import axios from "axios"
import { getAll } from "../../../endpoints/getAll"

const URL = getAll.logistica + `/reporte-planificacion`

export const getAllLogisticaLineasDespacho = async (params = {}, limit = 9999, paginationUrl = undefined) => {
  try {


    const response = await axios(`${paginationUrl ?? URL}${paginationUrl ? '&' : '?'}limit=${limit}`, {
      method: 'POST',
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
      data: params
    })

    if (!response.status) throw response

    return response
  } catch (err) {
    return Promise.reject(err)
  }
}