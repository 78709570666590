import React, { useEffect, useState } from 'react'


// Components
import Modal from 'components/Modal'
import CheckBoxInput from 'components/CheckboxInput'
import Button from 'components/Button'
import FormInput from 'components/FormInput'
import AccordionTable from 'layout/AccordionTable'
import SelectFilter from 'components/SelectFilter'
import Table from 'components/Table'
import Icon from 'components/Icon'
import CustomLoader from 'components/CustomLoader'
import { filtrosFormatter } from 'components/AccordionFilter/LogisticaLineasDespacho'


// Redux
import { useDispatch, useSelector } from 'react-redux'
import { fireToaster } from 'redux/actions/toaster'


// Services
import { getAllLogisticaReplanificacionMotivos } from 'services/logistica/replanificacionMotivos/getAll.service'
import { createReplanificacion } from 'services/logistica/replanificacion/create.service'
import { getAllLogisticaLineasDespacho } from 'services/logistica/lineasDespacho/getAll.service'


// Helpers
import { responseToasterContent } from 'helpers/toasterContent'
import { resultadosLogisticaLineasDespacho } from 'redux/actions/logisticaLineasDespacho'


const CONSTANT = {
  reduxSelector: 'logisticaLineasDespacho',
  reduxSetResults: resultadosLogisticaLineasDespacho,

  form: {
    motivo_id: '',
    nvs: [],
    es_automatica: false
  },

  selectFilter: {
    motivos: []
  }
}


const fetchData = async (data) => {
  return await Promise.all([
    getAllLogisticaLineasDespacho(data)
      .then(({ data }) => data)
      .catch(err => {
        console.error(err);
        return data
      }),
  ])
    .then(x => {
      let data = {}

      x.forEach(d => {
        data = { ...data, ...d }
      })

      return data
    })
}


const fetchSelectData = async () => {
  return Promise.all([
    await getAllLogisticaReplanificacionMotivos()
      .then(({ data }) => ({ motivos: data.data }))
      .catch(err => {
        console.error(err);
        return { motivos: [] }
      })
  ])
    .then(res => {
      let obj = {}
      res.forEach(d => Object.entries(d).forEach(([k, v]) => obj = { ...obj, [k]: v }))
      return obj
    })
}


export const ModalReplanificacion = ({ display, handleDisplay }) => {
  const { resultados: response, filtros } = useSelector(state => state[CONSTANT.reduxSelector])
  const [nvList, setNvList] = useState([])
  const [selectFilter, setSelectFilter] = useState(CONSTANT.selectFilter)
  const [form, setForm] = useState(CONSTANT.form)
  const [nvListFiltered, setNvListFiltered] = useState([])
  const [nvFilters, setNvFilters] = useState({ search: '' })
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const filtered = nvList?.filter(({ nv }) => String(nv?.ORDER_NUM).includes(nvFilters.search))

    setNvListFiltered(filtered)
  }, [nvFilters, nvList])


  useEffect(() => {
    fetchSelectData()
      .then(data => {
        setSelectFilter(data)
      })

    setNvList(response?.despachos_validos?.listado)
  }, [response?.despachos_validos?.listado])


  const handleCloseFull = () => {
    handleDisplay(null)
  }


  const handleForm = (e) => {
    const { name, value } = e.target

    if (name.includes('nvs')) {
      const id = Number(name.split('-')[1])
      const isListed = form.nvs.find(nv => nv === id)

      setForm(prev => ({
        ...prev,
        nvs: isListed
          ? prev.nvs.filter(nv => nv !== id)
          : [...form.nvs, id]
      }))
      return
    }

    setForm(prev => ({
      ...prev,
      [name]: value
    }))
  }

  const handleSelectAll = () => {
    const isAllSelected = form.nvs.length === nvList.length

    setForm(prev => ({
      ...prev,
      nvs: isAllSelected ? [] : nvList.map(({ nv }) => Number(nv?.ORDER_NUM))
    }))
  }


  const handleTableFilter = (e) => {
    const { name, value } = e.target

    setNvFilters(prev => ({ ...prev, [name]: value }))
  }


  const handleSubmit = async (e) => {
    e.preventDefault()
    setIsLoading(true)

    await createReplanificacion(form)
      .then(async res => {
        dispatch(fireToaster(responseToasterContent(res)))

        await fetchData(filtrosFormatter(filtros))
          .then(res => {
            dispatch(CONSTANT.reduxSetResults(res))
            handleCloseFull()
          })
          .finally(() => {
            setIsLoading(false)
          })
      })
      .catch(err => {
        dispatch(fireToaster(responseToasterContent(err)))
      })
      .finally(() => {
        setIsLoading(false)
      })
  }


  return (
    <Modal
      size={`col-8`}
      sizeDesk={`col-md-12`}
      display={display}
      handleValue={handleCloseFull}
    >
      {isLoading && <CustomLoader blocking='full' />}

      <form onSubmit={handleSubmit}>
        <div>
          <h3>Generar Replanificación</h3>
        </div>

        <hr className='my-50' />

        <div className='row'>
          <SelectFilter
            labelText='* Motivo'
            name='motivo_id'
            value={form.motivo_id.split('-')?.[1] ?? 'Seleccione'}
            optionObj={selectFilter?.motivos?.map(({ id, motivo }) => ({ id, name: motivo })) ?? []}
            size={'col-6'}
            handleValue={handleForm}
          />

          <CheckBoxInput
            labelText="Es automática"
            name='es_automatica'
            classes='w-fit mt-2 m-auto'
            size={'col-2'}
            value={form?.es_automatica}
            handleValue={handleForm}
          />
        </div>

        <AccordionTable
          title={`Notas de venta (${nvList?.length ?? 0})`}
          classes="col-12 mb-2"
        >
          <Table pb={false} style={{ maxHeight: '400px' }}>
            <thead className="table-light position-sticky top-0 w-100 z-100">
              <tr role="row">

                <th className="col-1">
                  <div>
                    <CheckBoxInput
                      labelText="Todas"
                      classes='w-fit mt-2 m-auto'
                      handleValue={handleSelectAll}
                      value={form.nvs?.length === nvList?.length}
                    />
                  </div>
                </th>

                <th className="col">
                  <div className="d-flex gap-1 position-relative">
                    <FormInput
                      labelText='Nota de venta'
                      size="col-12"
                      name='search'
                      value={nvFilters.search}
                      handleValue={handleTableFilter}
                      sizeDesk="col-md-12"
                      margin="mb-0"
                    />

                    <button
                      // onClick={() => filterDatatable('puntos_entrega')}
                      className="btn m-auto p-25"
                      style={
                        {
                          backgroundColor: '#7367f0',
                          position: 'absolute',
                          color: '#FFF',
                          top: '48%',
                          right: '10px',
                        }
                      }
                    >
                      <Icon icon='Search' />
                    </button>
                  </div>
                </th>
              </tr>
            </thead>

            <tbody>
              {nvListFiltered?.length
                ? nvListFiltered.map(({ nv }, i) => (
                  <tr className="odd" key={`${i}-nota-venta-${nv?.ORDER_NUM}`}>
                    <td>
                      <div className="d-flex h-100">
                        <CheckBoxInput
                          name={`nvs-${nv?.ORDER_NUM}`}
                          labelText=""
                          handleValue={handleForm}
                          classes='m-auto'
                          // value={form.nvs.find(id => Number(id) === Number(nv?.ORDER_NUM))}
                          value={form.nvs.includes(Number(nv?.ORDER_NUM))}
                        />
                      </div>
                    </td>

                    <td>
                      <div className='d-flex flex-column'>
                        {nv?.ORDER_NUM}
                      </div>
                    </td>
                  </tr>
                ))
                : <tr><td className='text-center' colSpan={2}><i>Sin resultados...</i></td></tr>
              }
            </tbody>
          </Table>
        </AccordionTable>

        <div className='d-flex justify-content-around'>
          <Button text='Cancelar' color='danger' icon='X' onClick={handleDisplay} />

          <Button text='Replanificar' color='success' icon='Check' type='submit' onClick={handleSubmit} />
        </div>
      </form>

    </Modal>
  )
}
