import { useEffect, useState } from "react";
import axios from "axios";

//	Components
import Button from "../../components/Button";
import CustomForm from "../../components/CustomForm";
import Switch from "../../components/Switch";
import FormInput from "../../components/FormInput";
import CustomLoader from "../../components/CustomLoader";
import TextareaInput from "../../components/TextareaInput";
import SelectFilter from "../../components/SelectFilter";


// Redux
import { useDispatch } from "react-redux";
import { fireToaster } from "../../redux/actions/toaster";


// Hooks
import { getOne } from "../../endpoints/getOne";


// React-router-dom
import { useNavigate, useParams } from "react-router-dom";
import { getAll } from "../../endpoints/getAll";
import { idExtractor } from "../../helpers/idExtractor";
import { BASE_URL } from "../../endpoints";
import FileUploaderB from "../../components/FileUploader_B";
import { getAllBodegas } from "services/bodegas/getAll.service";
import { Tooltip } from "chart.js";


const handleNullOrInteger = (value) => {
  if (!value || !value.length) return null
  else return Number(value)
}


const CONSTANT = {
  nullObject: { id: '*NULL*', nombre: '- Error -' },
  unselectedObject: { id: '*NULL*', nombre: 'Sin selección' },
  redirectUrl: '/puntos-entrega',
  componentTitle: 'Editar punto de entrega',
  title: 'Puntos de entrega',
  queryUrl: getOne.puntos_entrega,

  fecthComunas: getAll.comunas + '/buscar',
  fecthTiposDestino: getAll.tipos_destino,
  fetchMetodosEntrega: getAll.metodos_entrega,
  fecthProveedores: getAll.proveedores,
  fetchBodegasServicio: getAllBodegas,

  initialFormState: {
    asignar_por_defecto: false,
    instalacion_por_defecto: "",
    despacho_por_defecto: "",
    comuna_id: "",
    tipo_destino_id: "",
    metodo_entrega_id: "",
    rut_proveedor: "",
    nombre: "",
    descripcion: "",
    direccion: "",
    url_mapa: "",
    sucursal_propia: true,
    sucursal_maxi: '',
    aplicar_instalacion_hasta: '',
    bodega_instalacion_id: '',
    estatus: true,
    es_tarifa_plana: 0,
    meta: {}
  },

  selectFilterInitialState: {
    comunas: [],
    tiposDestino: [],
    metodosEntrega: [],
    proveedores: []
  }
}


const handleSelectFiltersData = async () => {
  const comunas = await axios(CONSTANT.fecthComunas,
    {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      }, method: 'POST'
    })
    .then(({ data }) => [CONSTANT.unselectedObject, ...data?.comunas] ?? [CONSTANT.unselectedObject])
    .catch(err => {
      console.error(err)
      return [CONSTANT.nullObject]
    })

  const tiposDestino = await axios(CONSTANT.fecthTiposDestino,
    {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
    })
    .then(({ data }) => [CONSTANT.unselectedObject, ...data] ?? [CONSTANT.unselectedObject])
    .catch(err => {
      console.error(err)
      return [CONSTANT.nullObject]
    })

  const metodosEntrega = await axios(CONSTANT.fetchMetodosEntrega,
    {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
    })
    .then(({ data }) => [CONSTANT.unselectedObject, ...data] ?? [CONSTANT.unselectedObject])
    .catch(err => {
      console.error(err)
      return [CONSTANT.nullObject]
    })

  const proveedores = await axios(CONSTANT.fecthProveedores,
    {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
    })
    .then(({ data }) => [CONSTANT.unselectedObject, ...data] ?? [CONSTANT.unselectedObject])
    .catch(err => {
      console.error(err)
      return [CONSTANT.nullObject]
    })

  const bodegasServicio = await CONSTANT.fetchBodegasServicio()
    .then(({ data }) => [CONSTANT.unselectedObject, ...data] ?? [CONSTANT.unselectedObject])
    .catch(err => {
      console.error(err)
      return [CONSTANT.nullObject]
    })

  return { comunas, tiposDestino, metodosEntrega, proveedores, bodegasServicio }
}


const Update = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [fetchData, setFetchData] = useState(null)
  const [selectFilter, setSelectFilter] = useState(CONSTANT.selectFilterInitialState)
  const [image, setImage] = useState([])
  const { id } = useParams()


  // FUNCION MANEJADORA DE LOS INPUTS
  const handleInputChange = (e) => {
    const { target } = e

    setFetchData({
      ...fetchData,
      [target.name]: target.value,
    })
  }

  const handleMetadatos = (e) => {
    const { name, value } = e.target

    if (['dias_despacho'].includes(name)) {
      if (isNaN(Number(value))) return

      setFetchData(form => ({
        ...form,
        meta: {
          [name]: value
        }
      }))

      return
    }

    setFetchData(form => ({
      ...form,
      meta: {
        [name]: value
      }
    }))
  }


  // EFFECTO QUE DISPARA EL MANEJADOR DE DATA PARA SELECTFILTERS
  useEffect(() => {
    handleSelectFiltersData().then(data => setSelectFilter(data))
  }, [])


  /*
    EFECTO QUE CONSULTA LA DATA DEL REGISTRO Y ACTUALIZA EL ESTADO 'FetchData' y 
    RELLENA SELECTFILTERS: comuna_id, tipo_destino_id, metodo_entrega_id 
  */
  useEffect(() => {
    if (!selectFilter.proveedores.length) return

    axios(CONSTANT.queryUrl(id),
      {
        headers: {
          'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
        },
      })
      .then(({ data }) => {
        const rutProveedor = selectFilter.proveedores.find(({ rut }) => rut === data?.rut_proveedor)

        setFetchData({
          ...data,
          comuna_id: `${data?.comuna?.id ?? '*NULL*'}-${data?.comuna?.nombre ?? 'N/A'}`,
          tipo_destino_id: `${data?.tipo_destino?.id ?? '*NULL*'}-${data?.tipo_destino?.nombre ?? 'N/A'}`,
          metodo_entrega_id: `${data?.metodo_entrega?.id ?? '*NULL*'}-${data?.metodo_entrega?.nombre ?? 'N/A'}`,
          rut_proveedor: `${rutProveedor?.rut ?? '*NULL*'}%-%${rutProveedor?.rut ?? 'N/A'} | ${rutProveedor?.nombre ?? 'Error'}`,
          bodega_instalacion_id: `${data?.bodega_instalacion?.id ?? '*NULL*'}-${data?.bodega_instalacion?.codigo ?? 'N/A'} | ${data?.bodega_instalacion?.nombre ?? 'N/A'}`,
        })
      })
      .catch(err => console.error(err))

    return () => {
      setFetchData(null)
    }
  }, [id, selectFilter.proveedores])


  const handleFetch = async (e) => {
    e.preventDefault()

    if (!fetchData?.comuna_id?.length || fetchData.comuna_id.includes('*NULL*')) return dispatch(fireToaster({ title: 'La comuna es obligatoria', icon: 'info' }))
    if (!fetchData?.tipo_destino_id?.length || fetchData.tipo_destino_id.includes('*NULL*')) return dispatch(fireToaster({ title: 'El tipo de destino es obligatorio', icon: 'info' }))
    if (!fetchData?.rut_proveedor?.length) return dispatch(fireToaster({ title: 'El RUT es obligatorio', icon: 'info' }))
    if (!fetchData?.nombre?.length) return dispatch(fireToaster({ title: 'El nombre es obligatorio', icon: 'info' }))
    if (!fetchData?.url_mapa?.length) return dispatch(fireToaster({ title: 'La URL es obligatoria', icon: 'info' }))
    if (!fetchData.sucursal_maxi) return dispatch(fireToaster({ title: 'La sucursal maxi es obligatoria', icon: 'info' }))

    let data = new FormData()
    data.append('_method', 'PUT')
    data.append('comuna_id', idExtractor(fetchData.comuna_id))
    data.append('tipo_destino_id', idExtractor(fetchData.tipo_destino_id))
    fetchData?.rut_proveedor?.length && data.append('rut_proveedor', fetchData.rut_proveedor.includes('*NULL*') ? 0 : idExtractor(fetchData.rut_proveedor, true))
    fetchData?.bodega_instalacion_id?.length && data.append('bodega_instalacion_id', fetchData.bodega_instalacion_id.includes('*NULL*') ? 0 : idExtractor(fetchData.bodega_instalacion_id))
    fetchData?.metodo_entrega_id?.includes('*NULL*')
      ? data.append('metodo_entrega_id', 0)
      : data.append('metodo_entrega_id', idExtractor(fetchData.metodo_entrega_id))
    data.append('nombre', fetchData.nombre)
    image.length && data.append('imagen', image[0].file)
    fetchData?.descripcion?.length && data.append('descripcion', fetchData.descripcion)
    fetchData?.direccion?.length && data.append('direccion', fetchData.direccion)
    data.append('url_mapa', fetchData.url_mapa)
    data.append('sucursal_propia', fetchData.sucursal_propia ? 1 : 0)
    data.append('sucursal_maxi', fetchData.sucursal_maxi)
    data.append('es_tarifa_plana', fetchData.es_tarifa_plana ? 1 : 0)
    data.append('aplicar_instalacion_hasta', fetchData?.aplicar_instalacion_hasta ?? '')
    data.append('estatus', fetchData.estatus ? 1 : 0)
    if (fetchData.meta?.dias_despacho) data.append('dias_despacho', fetchData.meta?.dias_despacho)
    // Atributos "por defecto"
    data.append('asignar_por_defecto', fetchData.asignar_por_defecto ? 1 : 0)
    data.append('tiene_despacho_por_defecto', fetchData.tiene_despacho_por_defecto ? 1 : 0)
    data.append('tiene_instalacion_por_defecto', fetchData.tiene_instalacion_por_defecto ? 1 : 0)
    fetchData.tiene_despacho_por_defecto && data.append('despacho_por_defecto', fetchData.despacho_por_defecto)
    fetchData.tiene_instalacion_por_defectoF && data.append('instalacion_por_defecto', fetchData.instalacion_por_defecto)

    axios(CONSTANT.queryUrl(id), {
      method: 'POST',
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null,
        '_method': 'PUT'
      },
      data
    })
      .then(({ data }) => {
        const toasterContent = {
          title: 'Operación realizada',
          text: `${CONSTANT.title}: ${data.nombre} actualizado con éxito`,
          icon: 'success'
        }
        dispatch(fireToaster(toasterContent))
        navigate(CONSTANT.redirectUrl)
      })
      .catch(err => {
        console.error(err);
        const { errores } = err.response.data
        let detalles = []
        Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))

        const toasterContent = {
          title: `
          Operación fallida
          (${err.response.status} - ${err.response.statusText})
        `,
          html: `
          <b>Detalle: </b>
          ${detalles.map(error => `<br /><i>-${error}</i>`)}
        `,
          icon: 'error'
        }
        dispatch(fireToaster(toasterContent))
      })
  }


  return (
    <div className="row">
      <div className="col col-md-6 mx-auto">
        <div className='col'>
          <Button text={CONSTANT.title} icon='ArrowLeft' style='flat' isLink url={CONSTANT.redirectUrl} />
        </div>

        {selectFilter.comunas.length && selectFilter.tiposDestino.length && selectFilter.metodosEntrega.length && fetchData?.id
          ? <CustomForm size="" title={CONSTANT.componentTitle}>
            <div className="row">
              <div className="col-6">
                <Switch
                  name="estatus"
                  value={fetchData.estatus}
                  labelText="Estatus"
                  handleValue={handleInputChange}
                  size="col-12"
                  classes='justify-content-end'
                />

                <Switch
                  name="es_tarifa_plana"
                  value={fetchData.es_tarifa_plana}
                  labelText="Tarifa plana"
                  handleValue={handleInputChange}
                  size="col-12"
                  classes='justify-content-end'
                />
              </div>

              <div className="col-6">
                <Switch
                  name="sucursal_propia"
                  value={fetchData.sucursal_propia}
                  labelText="Sucursal propia"
                  handleValue={handleInputChange}
                  size="col-12"
                  classes='justify-content-end'
                />

                <Switch
                  name="asignar_por_defecto"
                  value={fetchData.asignar_por_defecto}
                  labelText="Asignar por defecto"
                  handleValue={handleInputChange}
                  size="col-12"
                  classes='justify-content-end'
                />
              </div>
              <FormInput
                name="nombre"
                value={fetchData.nombre}
                labelText="* Nombre"
                placeholder='Nombre'
                handleValue={handleInputChange}
                size="col-12"
                sizeDesk='col-md-6'
              />

              <FormInput
                type='number'
                name="aplicar_instalacion_hasta"
                value={fetchData.aplicar_instalacion_hasta}
                labelText="Aplicar instalación hasta ($)"
                placeholder='Monto'
                handleValue={handleInputChange}
                size="col-12"
                sizeDesk='col-md-6'
              />

              <FormInput
                type='number'
                name="sucursal_maxi"
                value={fetchData.sucursal_maxi}
                labelText="* Sucursal maxi."
                placeholder='Maxi...'
                handleValue={handleInputChange}
                size="col-12"
                sizeDesk='col-md-6'
              />

              <SelectFilter
                name="comuna_id"
                value={fetchData.comuna_id?.split('-')[1]}
                labelText="* Comuna"
                placeholder='Comuna'
                handleValue={handleInputChange}
                optionObj={selectFilter.comunas?.map(({ nombre, id }) => ({ id, name: nombre }))}
                size="col-12"
                sizeDesk='col-md-6'
              />

              <SelectFilter
                name="tipo_destino_id"
                value={fetchData.tipo_destino_id?.split('-')[1]}
                labelText="* Tipo destino"
                placeholder='Tipo destino'
                handleValue={handleInputChange}
                optionObj={selectFilter.tiposDestino?.map(({ nombre, id }) => ({ id, name: nombre }))}
                size="col-12"
              />

              <SelectFilter
                name="metodo_entrega_id"
                value={fetchData.metodo_entrega_id?.split('-')[1]}
                labelText="* Método de entrega"
                placeholder='Método de entrega'
                handleValue={handleInputChange}
                optionObj={selectFilter.metodosEntrega?.map(({ nombre, id }) => ({ id, name: nombre }))}
                size="col-12"
              />

              <hr className='my-1' />

              <Switch
                name="tiene_instalacion_por_defecto"
                value={fetchData.tiene_instalacion_por_defecto}
                labelText="Aplicar instalación por defecto"
                handleValue={handleInputChange}
                size="col-12"
                sizeDesk='col-md-6'
                classes='justify-content-end my-auto'
              />

              <FormInput
                type='number'
                name="instalacion_por_defecto"
                value={fetchData.instalacion_por_defecto ?? ''}
                labelText="Instalación por defecto ($)"
                placeholder='Monto'
                handleValue={handleInputChange}
                isDisabled={!fetchData?.tiene_instalacion_por_defecto}
                size="col-12"
                sizeDesk='col-md-6'
              />

              <Switch
                name="tiene_despacho_por_defecto"
                value={fetchData.tiene_despacho_por_defecto}
                labelText="Aplicar despacho por defecto"
                handleValue={handleInputChange}
                size="col-12"
                sizeDesk='col-md-6'
                classes='justify-content-end my-auto'
              />

              <FormInput
                type='number'
                name="despacho_por_defecto"
                value={fetchData.despacho_por_defecto ?? ''}
                labelText="Despacho por defecto ($)"
                placeholder='Monto'
                handleValue={handleInputChange}
                isDisabled={!fetchData?.tiene_despacho_por_defecto}
                size="col-12"
                sizeDesk='col-md-6'
              />

              <hr className='my-1' />

              <SelectFilter
                altSeparator
                name="rut_proveedor"
                value={fetchData.rut_proveedor?.split('%-%')[1] ?? ''}
                labelText="* RUT del proveedor"
                placeholder='RUT'
                handleValue={handleInputChange}
                optionObj={selectFilter.proveedores?.map(({ nombre, rut, id }) => ({ id: rut ?? id ?? '*NULL*', name: `${rut ?? 'N/A'} | ${nombre ?? 'Error'}` }))}
                size="col-12"
                sizeDesk='col-md-12'
              />

              <SelectFilter
                name="bodega_instalacion_id"
                value={fetchData.bodega_instalacion_id?.split('-')[1] ?? ''}
                labelText="* Bodega de servicio"
                placeholder='Bodega de servicio'
                handleValue={handleInputChange}
                optionObj={selectFilter.bodegasServicio?.map(({ nombre, id, codigo }) => ({ id, name: `${codigo ?? 'N/A'} | ${nombre}` }))}
                size="col-12"
                sizeDesk='col-md-12'
              />

              <FormInput
                name="direccion"
                value={fetchData.direccion ?? ''}
                labelText="Dirección"
                placeholder='Dirección'
                handleValue={handleInputChange}
                size="col-12"
              />

              <TextareaInput
                name="descripcion"
                value={fetchData.descripcion ?? ''}
                labelText="Descrición"
                placeholder='Descrición'
                handleValue={handleInputChange}
                size="col-12"
                rows={2}
              />

              <FormInput
                name="url_mapa"
                value={fetchData.url_mapa ?? ''}
                labelText="* URL del mapa"
                placeholder='URL del mapa'
                handleValue={handleInputChange}
                size="col-12"
              />

              <FormInput
                name="dias_despacho"
                value={fetchData.meta?.dias_despacho ?? ''}
                labelText="* Días de despacho"
                placeholder='Días'
                handleValue={handleMetadatos}
                size="col-12"
                sizeDesk='col-md-6'
              />

              <div className="input-inset col-12">
                <label className="form-label">Imagen actual</label>
                <div className="form-outline border rounded-2 lista-detalle mb-2 d-flex justify-content-center p-2 w-fit">
                  <img src={BASE_URL + 'storage/' + fetchData.imagen} alt={`img-${fetchData.nombre}`} />
                </div>
              </div>

              <FileUploaderB handleValue={setImage} value={image} />
            </div>

            <Button type="submit" text="Actualizar" color="primary" icon="Check" onClick={handleFetch} />
          </CustomForm>
          : <CustomLoader />
        }
      </div>
    </div>
  );
};

export default Update;
