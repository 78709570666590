import {
   FILTER_BANCO_ID,
   FILTER_CLEAR,
   FILTER_ESTADO_ID,
   FILTER_FECHA_FINAL,
   FILTER_FECHA_INICIO,
   FILTER_MOTIVO_ID,
   FILTER_NOTA_VENTA,
   FILTER_PEDIDO_ID,
   RESULTADOS_DEVOLUCIONES,
} from "../redux-types";

export const pedidoFilter = (data) => ({ type: FILTER_PEDIDO_ID, payload: data })
export const notasVentaFilter = (data) => ({ type: FILTER_NOTA_VENTA, payload: data })
export const estatusFilter = (data) => ({ type: FILTER_ESTADO_ID, payload: data })
export const motivoFilter = (data) => ({ type: FILTER_MOTIVO_ID, payload: data })
export const bancoFilter = (data) => ({ type: FILTER_BANCO_ID, payload: data })
export const fechaInicialFilter = (data) => ({ type: FILTER_FECHA_INICIO, payload: data })
export const fechaFinalFilter = (data) => ({ type: FILTER_FECHA_FINAL, payload: data })

export const resultadosDevoluciones = (data) => ({ type: RESULTADOS_DEVOLUCIONES, payload: data })

export const clearFilterDevoluciones = () => ({ type: FILTER_CLEAR });
