import React, { useEffect, useState } from 'react'

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);


export const AutoservicioPieChart = ({ data }) => {
  const [pieDataChile, setPieDataChile] = useState(null)
  const [pieDataArg, setPieDataArg] = useState(null)


  useEffect(() => {
    if (!data?.chile) return

    let pieObj = {}
    let labels = []
    let qty = []
    let percentage = []
    let bgColors = []
    let borderColors = []

    data.chile.data.forEach(({ comuna, cantidad, porcentaje }) => {
      const random = {
        r: Math.round(Math.random() * 255),
        g: Math.round(Math.random() * 255),
        b: Math.round(Math.random() * 255)
      }

      labels = [...labels, comuna]
      qty = [...qty, cantidad]
      bgColors = [...bgColors, `rgba(${random.r}, ${random.g}, ${random.b}, 0.5)`]
      borderColors = [...borderColors, `rgba(${random.r}, ${random.g}, ${random.b}, 1)`]
      percentage = [
        ...percentage,
        {
          color: `rgba(${random.r}, ${random.g}, ${random.b}, 0.5)`,
          qty: cantidad,
          number: Number(porcentaje).toFixed(2),
          name: comuna
        }
      ]
    })

    pieObj = {
      labels,
      datasets: [
        {
          label: 'Cantidad',
          borderWidth: 1,
          data: qty,
          backgroundColor: bgColors,
          borderColor: borderColors
        },
      ],
      percentage
    }

    setPieDataChile(pieObj)

    return () => {
      setPieDataChile(null)
    }
  }, [data])


  useEffect(() => {
    if (!data?.argentina) return

    let pieObj = {}
    let labels = []
    let qty = []
    let percentage = []
    let bgColors = []
    let borderColors = []

    data.argentina.data.forEach(({ comuna, cantidad, porcentaje }) => {
      const random = {
        r: Math.round(Math.random() * 255),
        g: Math.round(Math.random() * 255),
        b: Math.round(Math.random() * 255)
      }

      labels = [...labels, comuna]
      qty = [...qty, cantidad]
      bgColors = [...bgColors, `rgba(${random.r}, ${random.g}, ${random.b}, 0.5)`]
      borderColors = [...borderColors, `rgba(${random.r}, ${random.g}, ${random.b}, 1)`]
      percentage = [
        ...percentage,
        {
          color: `rgba(${random.r}, ${random.g}, ${random.b}, 0.5)`,
          number: Number(porcentaje).toFixed(2),
          qty: cantidad,
          name: comuna
        }
      ]
    })

    pieObj = {
      labels,
      datasets: [
        {
          label: 'Cantidad',
          borderWidth: 1,
          data: qty,
          backgroundColor: bgColors,
          borderColor: borderColors
        },
      ],
      percentage
    }

    setPieDataArg(pieObj)

    return () => {
      setPieDataArg(null)
    }
  }, [data])


  return (
    <>
      <div className='col'>
        <div className='d-flex p-2 justify-content-around rounded flex-wrap' style={{ backgroundColor: '#fff' }}>
          <h3 className='col-12 text-center p-1' style={{ fontSize: '40px' }}>Chile</h3>

          {pieDataChile?.percentage?.length
            ?
            <>
              <div className='col-12 col-md-5 card gap-50 h-fit my-auto'>
                {pieDataChile?.percentage?.map(({ name, number, color, qty }) => (
                  <figure className='d-flex p-50 mb-0 border-bottom'>
                    <b className='col'>{name}</b>

                    <div className='d-flex col-4 gap-1 justify-content-between'>
                      <b>Qty: {qty} | {number}%</b>
                      <div style={{ backgroundColor: color, height: '20px', width: '50px' }}></div>
                    </div>
                  </figure>
                ))}
              </div>

              <div className='col-12 col-md-5 p-4'>
                <div className='d-flex justify-content-center'>
                  <Pie data={pieDataChile} />
                </div>
              </div>
            </>
            : <i className='fs-3 text-decoration-italic fw-bold'>Sin datos para mostrar...</i>
          }
        </div>
      </div>

      <hr className='my-5' />

      {/* TODO: Mostrar condicional para No Data */}
      <div className='col'>
        <div className='d-flex p-2 justify-content-around rounded flex-wrap' style={{ backgroundColor: '#fff' }}>
          <h3 className='col-12 text-center p-1' style={{ fontSize: '40px' }}>Argentina</h3>

          {pieDataArg?.percentage?.length
            ?
            <>
              <div className='col-12 col-md-5 card gap-50 h-fit my-auto'>
                {pieDataArg?.percentage?.map(({ name, number, color, qty }) => (
                  <figure className='d-flex p-50 mb-0 border-bottom'>
                    <b className='col'>{name}</b>

                    <div className='d-flex col-3 gap-1 justify-content-between'>
                      <b>Qty: {qty} | {number}%</b>
                      <div style={{ backgroundColor: color, height: '20px', width: '50px' }}></div>
                    </div>
                  </figure>
                ))}
              </div>

              <div className='col-12 col-md-5'>
                <div className='d-flex justify-content-center '>
                  <Pie data={pieDataArg} />
                </div>
              </div>
            </>
            : <i className='fs-3 text-decoration-italic fw-bold'>Sin datos para mostrar...</i>
          }
        </div>
      </div>
    </>
  )
}
