import axios from 'axios';


// Redux
import { useDispatch, useSelector } from 'react-redux'
import { fireToaster } from "../../redux/actions/toaster";
import { clearFilterPuntosEntrega, resultadosPuntosEntrega } from '../../redux/actions/puntosEntrega';


// Components
import CustomLoader from "../CustomLoader";
import DropdownMenuLink from "../Dropdown/Link";
import DropdownWrapper from "../Dropdown/Wrapper";
import Switch from "../Switch";
import Table from "../Table";


// Endpoints
import { getOne } from "../../endpoints/getOne";
import { BASE_URL } from '../../endpoints';


// Layouts
import AccordionTable from "../../layout/AccordionTable";
import { queryParamsFormatter } from '../../helpers/queryParamsFormatter';


const CONSTANT = {
  pathUrl: '/puntos-entrega',
  permissionsPath: 'puntos-entrega',
  queryUrlGetOne: getOne.puntos_entrega,
  title: 'Puntos de entrega',
  reduxClearFilters: (data) => clearFilterPuntosEntrega(data),
  reduxSetResults: (data) => resultadosPuntosEntrega(data),
}


const dataFormatter = (objFiltros) => {
  let data = {}

  Object.entries(objFiltros).forEach(([k, v]) => {
    if (v.length && ['tipo_destino_id', 'comuna_id', 'metodo_entrega_id'].includes(k)) {
      return data = {
        ...data,
        filtros: {
          ...data.filtros,
          [k]: v.map(el => el.split('-')[0])
        }
      }
    }
  })

  return data
}


const PuntosEntregaTable = () => {
  const { resultados: response, filtros } = useSelector(state => state.puntosEntrega)
  const { showing_results } = useSelector(state => state.listedResults)
  const dispatch = useDispatch()
  const { busqueda, publicado } = filtros
  const { user } = useSelector(state => state.auth)


  // FUNCION MANEJADORA DE PAGINACIÓN
  const handleUrl = (url) => {
    dispatch(CONSTANT.reduxSetResults(null))

    axios(url + `&limit=${showing_results}` + queryParamsFormatter({ busqueda }),
      {
        headers: {
          'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
        },
        method: 'POST',
        data: { ...dataFormatter(filtros) }
      })
      .then(({ data }) => dispatch(CONSTANT.reduxSetResults(data)))
      .then(err => console.error(err))
  }


  // FUNCIÓN MANEJADORA PARA ACTUALIZAR EL ESTATUS
  const handleFetch = async (objData, url, target) => {
    let config
    let fetchUrl
    let paramPublicado = publicado.includes('null') ? '' : publicado?.split('-')?.[0]

    if (target === 'estatus') {
      let newEstatus = objData[target] ? 0 : 1

      config = {
        method: 'POST',
        headers: {
          'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null,
          'Content-Type': 'multipart/form-data'
        },
        data: {
          [target]: newEstatus,
          _method: 'PUT',
        }
      }

      fetchUrl = CONSTANT.queryUrlGetOne(objData?.id)
    }

    if (target === 'delete') {
      config = {
        method: 'DELETE',
        headers: {
          'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
        },
        data: {
          _method: 'DELETE',
        }
      }

      fetchUrl = CONSTANT.queryUrlGetOne(objData?.id)
    }

    await axios(fetchUrl, config)
      .then(res => {
        const toasterContent = {
          title: 'Operación realizada',
          text: `${CONSTANT.title}: ${target} actualizado con éxito`,
          icon: 'success'
        }

        dispatch(fireToaster(toasterContent))
      })
      .catch(err => {
        console.error(err);
        const { errores } = err.response.data
        let detalles = []
        Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))

        const toasterContent = {
          title: `
            Operación fallida
            (${err.response.status} - ${err.response.statusText})
          `,
          html: `
            <b>Detalle: </b>
            ${detalles.map(error => `<br /><i>-${error}</i>`)}
          `,
          icon: 'error'
        }
        dispatch(fireToaster(toasterContent))
      })

    dispatch(CONSTANT.reduxSetResults(null))

    axios(url + queryParamsFormatter({ busqueda, estatus: paramPublicado }),
      {
        headers: {
          'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
        },
        method: 'POST',
        data: { ...dataFormatter(filtros) }
      })
      .then(({ data }) => dispatch(CONSTANT.reduxSetResults(data)))
      .catch(err => {
        console.error(err)
        dispatch(CONSTANT.reduxSetResults([]))
      })
  }


  return (
    <AccordionTable title={`${CONSTANT.title} (${response?.data?.length ?? 0} resultados)`}>
      {!response && <CustomLoader blocking={'partial'} />}

      <Table response={response} handlePagination={handleUrl}>
        <thead className="table-light position-sticky top-0 w-100 z-100 shadow">
          <tr role="row">
            <th>ID</th>
            <th>Datos</th>
            <th>Tipo destino</th>
            <th>Método de entrega</th>
            <th>Imagen</th>
            <th>Bodega instalación</th>
            <th>Estatus</th>
            <th className="w-max">Acciones</th>
          </tr>
        </thead>
        <tbody>
          {response?.data?.length
            ? response.data?.map((data) => {
              const imagen = `${BASE_URL}storage/${data.imagen}` ?? '/placeholder.png'

              return (
                <tr className="odd" key={`user-${data.id}-${data.titulo}`}>
                  <td className='col-1'>{data?.id}</td>

                  <td>
                    <div className='d-flex flex-column'>
                      <span>Nombre: <b>{data?.nombre ?? "N/A"}</b></span>
                      <span>Comuna: <b>{data?.comuna?.nombre ?? "N/A"}</b></span>
                      <span>Dirección: <b>{data?.direccion ?? "N/A"}</b></span>
                    </div>
                  </td>

                  <td>{data?.tipo_destino?.nombre ?? "N/A"}</td>

                  <td>{data?.metodo_entrega?.nombre ?? "N/A"}</td>

                  <td>{data.imagen ? <img className="height-listado" src={imagen} alt={data.nombre} /> : "N/A"}</td>

                  <td>{data?.bodega_instalacion?.codigo ?? 'N/A'} | {data?.bodega_instalacion?.nombre ?? 'N/A'}</td>

                  <td className='col-1'>
                    <Switch
                      value={data.estatus ? 1 : 0}
                      labelText={""}
                      handleValue={() => handleFetch(data, response.path + '?page=' + response.current_page + `&limit=${showing_results}` + queryParamsFormatter({ busqueda }), 'estatus')}
                    />
                  </td>
                  <td className='col-1'>
                    <DropdownWrapper icon={"MoreVertical"} classes="w-fit mx-auto" >
                      {user.permisos[`${CONSTANT.permissionsPath}.detalle`] &&
                        <DropdownMenuLink text='Detalle' href={`${CONSTANT.pathUrl}/detalle/${data.id}`} icon="Table" />
                      }

                      {user.permisos[`${CONSTANT.permissionsPath}.editar`] &&
                        <DropdownMenuLink text='Editar' href={`${CONSTANT.pathUrl}/update/${data.id}`} icon="Settings" />
                      }

                      {user.permisos[`${CONSTANT.permissionsPath}.eliminar`] &&
                        <DropdownMenuLink text='Borrar' icon="X" onClick={() => handleFetch(data, response.path + '?page=' + response.current_page + `&limit=${showing_results}` + queryParamsFormatter({ busqueda }), 'delete')} />
                      }
                    </DropdownWrapper>
                  </td>
                </tr>
              )
            })
            : <tr><td className="text-center fw-bolder fs-2 text-lgray" colSpan={9}><i>Sin datos para mostrar...</i></td></tr>
          }
        </tbody>
      </Table>
    </AccordionTable >
  )
}

export default PuntosEntregaTable