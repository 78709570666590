import { Navigate, Route, Routes } from "react-router-dom"
import { Listado as ListadoReportes } from "../../views/Logistica/Listado";
import { Listado as ListadoLineasDespacho } from "../../views/Logistica/LineasDespacho/Listado";
import { Listado as ListadoDespacho } from "../../views/Logistica/Despachos/Listado";
// import { Listado as ListadoLineasAgendamientos } from "../../views/Logistica/LineasAgendamientos/Listado";
import ReplanificacionMotivosRouter from "./ReplanificacionMotivosRouter";
import ReplanificacionRouter from "./ReplanificacionRouter";


export const LogisticaRouter = () => {
  return (
    <Routes>
      <Route path='/reportes/*' element={<ListadoReportes />} />

      <Route path='/lineas-despacho/*' element={<ListadoLineasDespacho />} />

      {/* <Route path='/lineas-agendamiento/*' element={<ListadoLineasAgendamientos />} /> */}

      <Route path='/replanificacion/*' element={<ReplanificacionRouter />} />

      <Route path='/replanificacion-motivos/*' element={<ReplanificacionMotivosRouter />} />

      <Route path='/despachos/*' element={<ListadoDespacho />} />

      <Route path='*' element={<Navigate to='/home' replace />} />
    </Routes>
  );
}
