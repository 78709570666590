import { Navigate, Route, Routes } from "react-router-dom"
import Listado from "../../views/Logistica/ReplanificacionMotivos/Listado";
import Form from "../../views/Logistica/ReplanificacionMotivos/Form";
import Detalle from "../../views/Logistica/ReplanificacionMotivos/Detalle";


const ReplanificacionMotivosRouter = () => {
   return (
      <Routes>
         <Route path='/' element={<Listado />} />

         <Route path='/create' element={<Form />} />

         <Route path='/update/:id' element={<Form />} />

         <Route path='/detalle/:id' element={<Detalle />} />

         <Route path='*' element={<Navigate to='/home' replace />} />
      </Routes>
   );
}

export default ReplanificacionMotivosRouter;