import React, { useState } from 'react'
import { Link } from 'react-router-dom';


// Redux
import { useSelector } from 'react-redux'
import { clearFilterLogisticaReplanificacion, resultadosLogisticaReplanificacion } from 'redux/actions/logisticaReplanificacion';


// Components
import Table from "components/Table";
import Button from 'components/Button';
import { ModalLineaDespachoDetalle } from 'components/Modal/Logisitica/ModalLineaDespachoDetalle';
import { ModalPlanificacion } from 'components/Modal/Logisitica/ModalPlanificacion';


// Layouts
import AccordionTable from "layout/AccordionTable";


// Services


// Helpers/Hooks
import { useCopyClipboard } from 'hooks/useCopyClipboard';


const CONSTANT = {
  permissionsPath: 'notas-venta',
  pathUrl: '/logistica/replanificacion',
  title: 'Replanificación',

  reduxSelector: 'logisticaReplanificacion',
  reduxClearFilters: clearFilterLogisticaReplanificacion,
  reduxSetResults: resultadosLogisticaReplanificacion,
}




// TODO: consultar si cuando la nv esta planificada deshabilitar el boton de planificacion en la tabla
export const ReplanificacionTable = () => {
  const { resultados: response } = useSelector(state => state[CONSTANT.reduxSelector])
  const [copy] = useCopyClipboard()
  const [modalLineaDetalle, setModalLineaDetalle] = useState({ display: false, data: null })
  const [modalPlanificar, setModalPlanificar] = useState({ display: false, data: null })


  const handleModalLineaDetalle = (data) => {
    setModalLineaDetalle(prev => ({ display: !prev.display, data }))
  }


  const handleModalPlanificar = async (data) => {
    setModalPlanificar(prev => ({ display: !prev.display, data }))
  }


  return (
    <>
      {response?.data_replanificaciones
        ?
        <>
          {/* MARK: LISTADO */}
          <AccordionTable title={`${CONSTANT.title} (${response?.data_replanificaciones?.length ?? 0} resultados)`}>
            <Table response={response} pb={false}>
              <thead className="table-light position-sticky top-0 w-100 z-100 shadow">
                <tr role="row">
                  <th>NV</th>
                  <th>Motivo</th>
                  <th>Fecha planificación</th>
                  <th>Fecha replanificación</th>
                  <th>Tipo despacho</th>
                  <th>Vendedor</th>
                  <th>Acc.</th>
                </tr>
              </thead>

              <tbody>
                {response?.data_replanificaciones?.length
                  ? response?.data_replanificaciones?.map(({
                    nota_venta, motivo, planificacion_original, planificacion_nueva, data_nv, es_planificado
                  }, i) => {
                    return (
                      <tr className="odd" key={`replanificacion-${nota_venta}-${i}`}>
                        <td className='col-1'>
                          <Link target='_blank' rel='noreferrer' to={`/erp/notas-ventas/detalle/${nota_venta}`}>{nota_venta}</Link>
                        </td>

                        <td>{motivo?.motivo ?? "N/A"}</td>

                        <td>{planificacion_original ?? 'N/A'}</td>

                        <td>{planificacion_nueva ?? 'N/A'}</td>

                        <td>{data_nv?.nv?.TIPO_DESPACHO ?? 'N/A'}</td>

                        <td>{data_nv?.nv?.NOMBRE_VENDEDOR ?? 'N/A'}</td>

                        <td>
                          <Button
                            className='p-50'
                            color='success'
                            text='Planificar'
                            icon='Calendar'
                            disabled={es_planificado}
                            onClick={() => handleModalPlanificar({ nota_venta })} />
                        </td>
                      </tr>
                    )
                  })
                  : <tr><td className="text-center fw-bolder fs-2 text-lgray" colSpan={9}><i>Sin datos para mostrar...</i></td></tr>
                }
              </tbody>
            </Table>
          </AccordionTable>

          {/* MARK: NVS AGRUPADAS */}
          <AccordionTable title={`Notas de venta agrupadas`} classes='mt-2'>
            <Table pb={false}>
              <thead className="table-light position-sticky top-0 w-100 z-100 shadow">
                <tr role="row">
                  <th>Notas de venta</th>
                </tr>
              </thead>

              <tbody>
                <tr key={`notas-ventas-${response?.nvs_replanificadas}`} className={`odd multi-data`}>
                  <td className='d-flex gap-1 fw-bold'>
                    <Button className='p-50' icon='Clipboard' disabled={!response?.nvs_replanificadas?.length} onClick={() => copy(response?.nvs_replanificadas)} />

                    <span className='fs-3'>{response?.nvs_replanificadas}</span>
                  </td>
                </tr>
              </tbody>
            </Table>
          </AccordionTable>

          {/* MARK: AGRUPADA POR VENDEDOR */}
          <AccordionTable title={`Notas de venta por vendedor`} classes='mt-2'>
            <Table pb={false}>
              <thead className="table-light position-sticky top-0 w-100 z-100 shadow">
                <tr role="row">
                  <th className='col-2'>Vendedor</th>

                  <th>Notas de venta</th>
                </tr>
              </thead>

              <tbody>
                {response?.nvs_replanificadas_por_vendedor?.length
                  ? response?.nvs_replanificadas_por_vendedor?.map(({ nvs, vendedor }, i) => {
                    return (
                      <tr key={`notas-ventas-${vendedor}-${i}`} className={`odd multi-data`}>
                        <td className='fw-bold'>{vendedor}</td>

                        <td style={{ paddingLeft: 0, paddingRight: 0 }}>
                          <div className='d-flex gap-1' onClick={() => copy(nvs)}>
                            <Button className='p-50' icon='Clipboard' disabled={!nvs?.length} onClick={() => copy(nvs)} />

                            <b className='fs-3'>{nvs}</b>
                          </div>
                        </td>
                      </tr>

                    )
                  })
                  : <tr><td className="text-center fw-bolder fs-2 text-lgray" colSpan={2}><i>Sin datos para mostrar...</i></td></tr>
                }
              </tbody>
            </Table>
          </AccordionTable>
        </>

        : <div className='col-12'><h2 className='card p-2 text-center mb-0 fw-bolder'><i>Por favor realice una búsqueda</i></h2></div>}

      <ModalPlanificacion
        data={modalPlanificar?.data}
        display={modalPlanificar?.display}
        handleDisplay={handleModalPlanificar}
      />

      <ModalLineaDespachoDetalle
        display={modalLineaDetalle?.display}
        data={modalLineaDetalle?.data}
        handleDisplay={handleModalLineaDetalle}
      />
    </>
  )
}
