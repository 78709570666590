import axios from "axios"
import { getAll } from "../../../endpoints/getAll"
import { queryParamsFormatter } from "../../../helpers/queryParamsFormatter"
import { successResponse } from "../../../helpers/successResponse"

const URL = getAll.logistica + `/replanificaciones`

export const getAllLogisticaReplanificacion = async (params = {}, limit = 9999, paginationUrl = undefined) => {
  try {
    const response = await axios(`${paginationUrl ?? URL}${paginationUrl ? '&' : '?'}limit=${limit}${queryParamsFormatter(params)}`, {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
    })

    if (!successResponse(response)) throw response

    return response
  } catch (err) {
    return Promise.reject(err)
  }
}