import {
   FILTER_CLEAR,
   FILTER_DESPACHOS_INPUT,
   RESULTADOS_LOGISTICA_DESPACHOS,
} from "../redux-types";

export const despachosInputFilter = (k, v) => ({ type: FILTER_DESPACHOS_INPUT, payload: { [k]: v } })

export const resultadosLogisticaDespachos = (data) => ({ type: RESULTADOS_LOGISTICA_DESPACHOS, payload: data })

export const clearFilterLogisticaDespachos = () => ({ type: FILTER_CLEAR });
