import { useEffect, useState } from "react";


// Redux
import { fireToaster } from "redux/actions/toaster";
import { useDispatch } from "react-redux";


//	Components
import FormInput from "components/FormInput";
import Button from "components/Button";
import CustomForm from "components/CustomForm";
import CustomLoader from "components/CustomLoader";
import SelectFilter from "components/SelectFilter";
import CheckBoxInput from "components/CheckboxInput";


// React-router
import { useNavigate, useParams } from "react-router-dom";


// Service
import { createMotivoReplanificacion } from "services/logistica/replanificacionMotivos/create.service";
import { getAllLogisticaReplanificacionMotivos } from "services/logistica/replanificacionMotivos/getAll.service";


// Helpers
import { responseToasterContent } from "helpers/toasterContent";
import { successResponse } from "helpers/successResponse";
import { createFormatter, updateFormatter } from "./helpers/formFormatter";


const CONSTANT = {
  redirectUrl: '/logistica/replanificacion',
  componentTitle: id => `${id ? 'Editar' : 'Crear'} replanificación`,
  title: 'Replanificación',

  serviceCreate: createMotivoReplanificacion,
  // serviceUpdate: updateMotivosReplanificacion,
  // serviceDetail: getDetailedMotivoReplanificacion,

  form: {
    motivo_id: '',
    nvs: '',
    es_automatica: false,
  },

  selectFilter: {
    motivos: []
  }
}


const fetchSelectData = async () => {
  return Promise.all([
    await getAllLogisticaReplanificacionMotivos()
      .then(({ data }) => ({ motivos: data.data }))
      .catch(err => {
        console.error(err);
        return { motivos: [] }
      })
  ])
    .then(res => {
      let obj = {}
      res.forEach(d => Object.entries(d).forEach(([k, v]) => obj = { ...obj, [k]: v }))
      return obj
    })
}

const Form = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [form, setForm] = useState(CONSTANT.form)
  const { id } = useParams()
  const [isConsulting, setIsConsulting] = useState(false)
  const [selectFilter, setSelectFilter] = useState(CONSTANT.selectFilter)


  useEffect(() => {
    fetchSelectData()
      .then(data => {
        console.log(data);
        
        setSelectFilter(data)
      })
  }, [])


  useEffect(() => {
    if (!id) return

    CONSTANT.serviceDetail(id)
      .then(({ data }) => {
        setForm({
          ...data,
        })
      })
      .catch(err => console.error(err))

    return () => {
      setForm(CONSTANT.form)
    }
  }, [id])


  // FUNCION MANEJADORA DE LOS INPUTS
  const handleInputChange = (e) => {
    const { name, value } = e.target

    setForm({
      ...form,
      [name]: value
    })
  }


  // FUNCIÓN MANEJADORA QUE CREA/MODIFICA EL REGISTRO EN LA BBDD
  const handleFetch = async (e) => {
    e.preventDefault()
    setIsConsulting(true)

    if (id) {
      CONSTANT.serviceUpdate(id, updateFormatter({ ...form }))
        .then(res => {
          dispatch(fireToaster(responseToasterContent(res)))
          if (successResponse(res)) navigate(CONSTANT.redirectUrl)
        })
        .finally(() =>
          setIsConsulting(false)
        )

      return
    }

    CONSTANT.serviceCreate(createFormatter({ ...form }))
      .then(res => {
        dispatch(fireToaster(responseToasterContent(res)))
        if (successResponse(res)) navigate(CONSTANT.redirectUrl)
      })
      .finally(() =>
        setIsConsulting(false)
      )

    return
  }


  return (
    <div className="row">
      <div className="col col-md-6 mx-auto">
        <div className='col'>
          <Button text={CONSTANT.title} icon='ArrowLeft' style='flat' isLink url={CONSTANT.redirectUrl} />
        </div>

        {id && !form?.id &&
          <CustomLoader blocking={'partial'} />
        }

        <CustomForm size="" title={CONSTANT.componentTitle(id)}>
          <div className="row">
            <CheckBoxInput
              name='es_automatica'
              value={form.es_automatica}
              labelText='Es automática'
              handleValue={handleInputChange}
              classes='col-6 my-auto'
            />

            <SelectFilter
              labelText='* Motivo'
              placeholder='Escriba un motivo'
              name='motivo_id'
              value={form.motivo_id?.split('-')?.[1] ?? 'Selecciona'}
              handleValue={handleInputChange}
              size='col-12'
              sizeDesk='col-md-12'
              optionObj={selectFilter?.motivos?.map(({ id, motivo }) => ({ id, name: motivo })) ?? []}
            />

            <FormInput
              labelText='* Notas de venta'
              placeholder='Notas de venta'
              name='nvs'
              value={form.nvs}
              handleValue={handleInputChange}
              size='col-12'
              sizeDesk='col-md-12'
            />
          </div>

          <Button
            type="submit"
            text={id ? 'Actualizar' : "Crear"}
            color="primary"
            disabled={isConsulting}
            icon={id ? 'Check' : "Plus"}
            onClick={(e) => handleFetch(e)}
          />
        </CustomForm>
      </div>
    </div>
  );
};

export default Form;
