import { Navigate, Route, Routes } from "react-router-dom"
import { Listado } from "../../views/Autoservicio/Listado"
import { Graficas } from "../../views/Autoservicio/Graficas"

export const AutoservicioRouter = () => {

   return (
      <Routes>
         <Route path='/' element={<Listado />} />

         <Route path='/graficas' element={<Graficas />} />

         <Route path='*' element={<Navigate to='/home' replace />} />
      </Routes>
   )
}
