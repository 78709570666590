import {
   FILTER_ADD_METODO_ENTREGA,
   FILTER_CLEAR,
   FILTER_CODIGO_VENDEDOR,
   FILTER_FECHA_FINAL,
   FILTER_FECHA_INICIO,
   FILTER_REMOVE_METODO_ENTREGA,
   RESULTADOS_LOGISTICA_REPORTES,
} from "../redux-types";


export const fechaInicialFilter = (data) => ({ type: FILTER_FECHA_INICIO, payload: data })

export const fechaFinalFilter = (data) => ({ type: FILTER_FECHA_FINAL, payload: data })

export const vendedorFilter = (data) => ({ type: FILTER_CODIGO_VENDEDOR, payload: data })

export const metodoEntregaFilter = (data, isRemove = false) =>
   isRemove
      ? { type: FILTER_REMOVE_METODO_ENTREGA, payload: data }
      : { type: FILTER_ADD_METODO_ENTREGA, payload: data }

export const resultadosLogisticaReportes = (data) => ({ type: RESULTADOS_LOGISTICA_REPORTES, payload: data })

export const clearFilterLogisticaReportes = () => ({ type: FILTER_CLEAR });
