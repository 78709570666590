
// Components
import { AutoservicioTable } from "../../components/Tables/Autoservicio/AutoservicioTable";


export const Listado = () => {
	return (
		<AutoservicioTable />
	);
}
