import {
   FILTER_CLEAR,
   FILTER_REPLANIFICACION_INPUT,
   RESULTADOS_LOGISTICA_REPLANIFICACION,
} from "../redux-types";

export const replanificacionInputFilter = (k, v) => ({ type: FILTER_REPLANIFICACION_INPUT, payload: { [k]: v } })

export const resultadosLogisticaReplanificacion = (data) => ({ type: RESULTADOS_LOGISTICA_REPLANIFICACION, payload: data })

export const clearFilterLogisticaReplanificacion = () => ({ type: FILTER_CLEAR });
