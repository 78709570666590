import axios from "axios"


// Endpoints
import { getAll } from "endpoints/getAll"


// Helpers
import { successResponse } from "helpers/successResponse"


const URL = getAll.notas_ventas + `/planificar`


export const createPlanificarNv = async (data) => {
  try {
    const response = await axios(URL, {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
      method: 'POST',
      data: {
        fecha_planificacion: data?.fecha_planificacion,
        nota_venta: data?.nota_venta
      }
    })

    if (!successResponse(response)) throw response

    return response
  } catch (err) {
    return Promise.reject(err)
  }
}