import {
   FILTER_CLEAR,
   FILTER_DESPACHOS_INPUT,
   RESULTADOS_LOGISTICA_DESPACHOS,
} from "../redux-types";


const initialState = {
   filtros: {
      fecha_desde: '',
      fecha_hasta: '',
      es_excepcion: '',
   },
   resultados: null
}


export const logisticaDespachosReducer = (state = initialState, action) => {
   switch (action.type) {
      case FILTER_DESPACHOS_INPUT:
         return {
            ...state, filtros: {
               ...state.filtros,
               ...action.payload
            }
         };

      case RESULTADOS_LOGISTICA_DESPACHOS:
         return {
            ...state, resultados: action.payload
         };

      case FILTER_CLEAR:
         return {
            ...state,
            filtros: initialState.filtros
         };

      default:
         return state;
   }
};