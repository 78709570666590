import React, { useEffect, useState } from 'react'


// Redux
import { useDispatch, useSelector } from 'react-redux'
import { FILTER_REPLANIFICACION_INPUT } from '../../redux/redux-types'
import { clearFilterLogisticaReplanificacion, replanificacionInputFilter, resultadosLogisticaReplanificacion } from '../../redux/actions/logisticaReplanificacion'
import { fireToaster } from '../../redux/actions/toaster'


// Components
import FormInput from '../FormInput'
import CustomLoader from '../CustomLoader'
import SelectFilter from '../SelectFilter'


// Layouts
import ListadoHeader from '../../layout/ListadoHeader'


// Helpers
import { filtrosAplicadosTotal } from '../../helpers/filtrosAplicadosTotal'


// Services
import { getAllLogisticaReplanificacion } from '../../services/logistica/replanificacion/getAll.service'
import { idExtractor } from '../../helpers/idExtractor'
import moment from 'moment'



const CONSTANT = {
  redirectUrl: '/logistica/replanificacion',
  title: 'Filtros: Logística - Replanificación',

  reduxStateSelector: 'logisticaReplanificacion',
  reduxClearFilters: clearFilterLogisticaReplanificacion,
  reduxSetResults: resultadosLogisticaReplanificacion,

  selectFilter: {
    esPlanificado: [
      { id: '*NULL*', nombre: 'Todas' },
      { id: '1', nombre: 'Sí' },
      { id: '0', nombre: 'No' }
    ],
  }
}


export const filtrosFormatter = (filtros) => {
  let validFormat = {}

  Object.entries(filtros).forEach(([k, v]) => {
    if (v.includes('*NULL*')) return

    if (['fecha_desde', 'fecha_hasta'].includes(k) && v.length) {
      validFormat = {
        ...validFormat,
        [k]: v
      }
    }

    if (['es_planificado'].includes(k) && v.length) {
      validFormat = {
        ...validFormat,
        //NOTICE: Se castea a string para que el formateador de params tome en cuenta el 0, 
        //        sino lo pasa como null y no lo toma en cuenta
        [k]: String(idExtractor(v))
      }
    }
  })

  return validFormat
}


const fetchData = async (params) => {
  return await Promise.all([
    getAllLogisticaReplanificacion(params)
      .then(({ data }) => data)
      .catch(err => {
        console.error(err);
        return []
      }),
  ])
    .then(x => {
      let data = {}

      x.forEach(d => {
        data = { ...data, ...d }
      })

      return data
    })
}


export const LogisticaReplanificacionFiltros = () => {
  const dispatch = useDispatch()
  const { filtros } = useSelector(state => state[CONSTANT.reduxStateSelector])
  const { fecha_desde, fecha_hasta, es_planificado } = filtros
  const [selectFilter, setSelectFilter] = useState(CONSTANT.selectFilter)
  const [isConsulting, setIsConsulting] = useState(false)


  // EFECTO QUE SETEA LA DATA DE LOS SELECTFILTERS
  useEffect(() => {
    dispatch(replanificacionInputFilter('fecha_desde', moment().format('YYYY-MM-DD')))
    dispatch(replanificacionInputFilter('fecha_hasta', moment().format('YYYY-MM-DD')))
  }, [dispatch])


  // FUNCION QUE CONSULTA EL BACKEND CON LOS FILTROS SELECCIONADOS
  const handleFetchFilter = async (reset = false) => {
    if (reset) {
      dispatch(CONSTANT.reduxClearFilters())
      return
    }

    if (!fecha_desde) return dispatch(fireToaster({ title: 'Debe seleccionar una fecha inicial', icon: 'warning' }))
    if (!fecha_hasta) return dispatch(fireToaster({ title: 'Debe seleccionar una fecha final', icon: 'warning' }))

    setIsConsulting(true)

    await fetchData(filtrosFormatter(filtros))
      .then(res => {
        dispatch(CONSTANT.reduxSetResults(res))
      })
      .finally(() => {
        setIsConsulting(false)
      })
  }


  // MANEJADORES DE FILTRO
  const handleFilter = (e, filter) => {
    const { name, value } = e.target

    switch (filter) {
      case FILTER_REPLANIFICACION_INPUT:
        dispatch(replanificacionInputFilter(name, value))
        break;

      default:
        break;
    }
  }


  return (
    <div className="card mb-50 shadow-none bg-transparent">
      {isConsulting && <CustomLoader blocking={'partial'} />}

      <ListadoHeader
        classes='border rounded-2'
        title={`${CONSTANT.title} (${filtrosAplicadosTotal(filtros)})`}
        handleDisable={isConsulting}
        handleClickSearch={() => handleFetchFilter()}
        handleClickClearFilter={() => handleFetchFilter(true)}
      >
        <>
          <FormInput
            labelText='Fecha inicial'
            name='fecha_desde'
            size='col-12'
            sizeDesk='col-md-2'
            handleValue={(e) => handleFilter(e, FILTER_REPLANIFICACION_INPUT)}
            value={fecha_desde}
            type={'date'}
          />

          <FormInput
            labelText='Fecha final'
            name='fecha_hasta'
            size='col-12'
            sizeDesk='col-md-2'
            handleValue={(e) => handleFilter(e, FILTER_REPLANIFICACION_INPUT)}
            value={fecha_hasta}
            type={'date'}
          />

          <SelectFilter
            labelText='Fue planificada'
            name='es_planificado'
            handleValue={(e) => handleFilter(e, FILTER_REPLANIFICACION_INPUT)}
            optionObj={selectFilter.esPlanificado.map(({ id, nombre }) => ({ id, name: nombre }))}
            sizeDesk='col-md-3'
            size='col-12'
            value={es_planificado?.split('-')?.[1] ?? 'Selecciona'}
          />
        </>
      </ListadoHeader>
    </div>
  )
}