
// Redux


// React-Router


// Components
import { LogisticaReplanificacionFiltros } from "../../../components/AccordionFilter/Replanificacion";
import { ReplanificacionTable } from "../../../components/Tables/Logistica/ReplanificacionTable";


// Helpers


// Services


const CONSTANT = {
  title: 'Logistica - Replanificación',
  path: 'logistica/replanificacion',
}


export const Listado = () => {

  return (
    <div className="row">
      <h3 className="mb-1 d-flex justify-content-between">
        <p><b>{CONSTANT.title}</b></p>

      </h3>

      <LogisticaReplanificacionFiltros />

      <ReplanificacionTable />
    </div>
  );
}
