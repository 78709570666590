import { BASE_URL, COMMON_URL, CONFIG_URL, PATH_URL, REPORTERIA_URL } from "..";

export const getAll = {
   // ERP
   erp_comunas: BASE_URL + PATH_URL + '/erp/comunas/obtener',
   erp_ciudades: BASE_URL + PATH_URL + '/erp/ciudades/obtener',
   erp_distritos: BASE_URL + PATH_URL + '/erp/distritos/obtener',
   erp_paises: BASE_URL + PATH_URL + '/erp/paises/obtener',
   erp_regiones: BASE_URL + PATH_URL + '/erp/regiones/obtener',
   notas_ventas: BASE_URL + PATH_URL + '/erp/notas-ventas',
   erp_vendedores: BASE_URL + PATH_URL + '/erp/vendedores',
   erp_proveedores: BASE_URL + PATH_URL + '/erp/proveedores',
   erp_usuarios: BASE_URL + PATH_URL + '/erp/usuarios/obtener',
   erp_bodegas: BASE_URL + PATH_URL + '/erp/bodegas',
   erp_bodegas_stock_productos: BASE_URL + PATH_URL + '/erp/stock/productos/bodegas',
   erp_gev: BASE_URL + PATH_URL + '/erp/gevs',

   // PRINCIPALES
   replanificaciones_motivos: BASE_URL + PATH_URL + '/replanificaciones-motivos',
   logistica: BASE_URL + PATH_URL + '/logistica',
   usuarios_sucursales: BASE_URL + PATH_URL + '/usuarios-sucursales',
   autogestion_clientes: BASE_URL + PATH_URL + '/autogestion-clientes',
   sucursales: BASE_URL + PATH_URL + '/sucursales',
   garantias: BASE_URL + PATH_URL + '/garantias',
   horarios_despacho: BASE_URL + PATH_URL + '/horarios-despacho',
   devoluciones: BASE_URL + PATH_URL + '/devoluciones',
   devoluciones_notas_credito: BASE_URL + PATH_URL + '/devoluciones-notas-credito',
   devoluciones_bitacora: BASE_URL + PATH_URL + '/devoluciones/bitacora',
   devoluciones_estatus_bitacora: BASE_URL + PATH_URL + '/devoluciones/estatus-bitacora',
   devoluciones_estatus: BASE_URL + PATH_URL + '/devoluciones-estatuses',
   devoluciones_motivos: BASE_URL + PATH_URL + '/devoluciones-motivos',
   devoluciones_bancos: BASE_URL + PATH_URL + '/devoluciones-bancos',
   devoluciones_reportes: BASE_URL + PATH_URL + '/devoluciones/reportes',
   productos_imagenes_masiva: BASE_URL + PATH_URL + '/imagenes-masivas',
   servicios: BASE_URL + PATH_URL + '/servicios',
   parametros_recargos: BASE_URL + PATH_URL + '/parametros-recargos',
   garantia_respuestas: BASE_URL + PATH_URL + '/garantia-respuestas',
   garantia_resultados: BASE_URL + PATH_URL + '/garantia-resultados',
   garantia_posiciones: BASE_URL + PATH_URL + '/garantia-posiciones',
   garantia_estados: BASE_URL + PATH_URL + '/garantia-estados',
   garantia_daños: BASE_URL + PATH_URL + '/garantia-daños',
   frontend_id: BASE_URL + COMMON_URL + '/frontends/listar',
   modelos: BASE_URL + PATH_URL + '/historial/modelos',
   reporte_ventas: BASE_URL + REPORTERIA_URL + '/ventas/resumen-mes',
   pedidos: BASE_URL + PATH_URL + '/pedidos',
   autogestion: BASE_URL + PATH_URL + '/autogestion-clientes',
   historial: BASE_URL + PATH_URL + '/historial',
   proveedores_categorias: BASE_URL + PATH_URL + '/proveedores-categorias',
   productos_proveedores: BASE_URL + PATH_URL + '/productos-proveedor',
   productos_propios_proveedor: BASE_URL + PATH_URL + '/productos-propios-proveedor',
   bodegas_puntos_entrega: BASE_URL + PATH_URL + '/bodegas-punto-entrega',
   eventos: BASE_URL + PATH_URL + '/eventos',
   busquedas_clientes: BASE_URL + PATH_URL + '/busquedas-clientes',
   claves: BASE_URL + CONFIG_URL + '/claves',
   dashboard: BASE_URL + PATH_URL + '/dashboard',
   dashboard_pedidos_estatus: BASE_URL + PATH_URL + '/dashboard/pedidos/por-estatus',
   dashboard_autogestiones: BASE_URL + PATH_URL + '/dashboard/autogestiones',
   permisos: BASE_URL + PATH_URL + '/permisos',
   distritos: BASE_URL + PATH_URL + '/distritos',
   paises: BASE_URL + PATH_URL + '/paises',
   agendamientos: BASE_URL + PATH_URL + '/agendamientos',
   adicionales: BASE_URL + PATH_URL + '/adicionales',
   productos: BASE_URL + PATH_URL + '/productos',
   ofertas: BASE_URL + PATH_URL + '/ofertas',
   ordenes_compras: BASE_URL + PATH_URL + '/erp/ordenes-compras',
   reporte_ordenes_compras: BASE_URL + REPORTERIA_URL + '/ordenes-compra/resumen-mes',
   reporte_comisiones: BASE_URL + REPORTERIA_URL + '/comisiones/resumen-mes',
   reporte_ventas_calzadas: BASE_URL + REPORTERIA_URL + '/ventas-calzadas/resumen-mes',
   reporte_agendamientos: BASE_URL + REPORTERIA_URL + '/agendamientos/resumen-mes',
   reporte_reposicion_stock: BASE_URL + REPORTERIA_URL + '/reposicion-stock/resumen-mes',
   etiquetas: BASE_URL + PATH_URL + '/etiquetas',
   cotizaciones: BASE_URL + PATH_URL + '/cotizaciones',
   comunas: BASE_URL + PATH_URL + '/comunas',
   ciudades: BASE_URL + PATH_URL + '/ciudades',
   cuentas_caja: BASE_URL + PATH_URL + '/cuentas-caja',
   bodegas: BASE_URL + PATH_URL + '/bodegas',
   empresas: BASE_URL + PATH_URL + '/empresas',
   empresas_tipos: BASE_URL + PATH_URL + '/empresas-tipos',
   tipos_elementos: BASE_URL + PATH_URL + '/tipos-elementos',
   categorias: BASE_URL + PATH_URL + '/categorias',
   cinta_informativa: BASE_URL + PATH_URL + '/cinta-informativa',
   faq: BASE_URL + PATH_URL + '/preguntas-frecuentes',
   categorias_faq: BASE_URL + PATH_URL + '/categorias-faq',
   componentes: BASE_URL + PATH_URL + '/componentes',
   subcategorias_faq: BASE_URL + PATH_URL + '/subcategorias-faq',
   banners: BASE_URL + PATH_URL + '/banners',
   formas_pago: BASE_URL + PATH_URL + '/formas-pago',
   factores_diferenciadores: BASE_URL + PATH_URL + '/factores-diferenciadores',
   codigos_descuento: BASE_URL + PATH_URL + '/codigos-descuento',
   estatus_pedido: BASE_URL + PATH_URL + '/estatus-pedido',
   menus: BASE_URL + PATH_URL + '/menus',
   usuarios: BASE_URL + PATH_URL + '/usuarios',
   tipos_facturacion: BASE_URL + PATH_URL + '/tipos-facturacion',
   tipos_atencion: BASE_URL + PATH_URL + '/tipos-atencion',
   metadatos: BASE_URL + PATH_URL + '/metadatos',
   divisas: BASE_URL + PATH_URL + '/divisas',
   puntos_entrega: BASE_URL + PATH_URL + '/puntos-entrega',
   roles: BASE_URL + PATH_URL + '/roles',
   metodos_pago: BASE_URL + PATH_URL + '/metodos-pago',
   metodos_entrega: BASE_URL + PATH_URL + '/metodos-entrega',
   tipos_destino: BASE_URL + PATH_URL + '/tipos-destino',
   dias: BASE_URL + PATH_URL + '/dias',
   criterios_comparacion: BASE_URL + PATH_URL + '/criterios-comparacion',
   unidades: BASE_URL + PATH_URL + '/unidades',
   claves_atributos: BASE_URL + PATH_URL + '/claves-atributos',
   valores_atributos: BASE_URL + PATH_URL + '/valores-atributos',
   proveedores: BASE_URL + PATH_URL + '/proveedores',
   terminos_condiciones: BASE_URL + PATH_URL + '/terminos-condiciones',
   pasos_compra: BASE_URL + PATH_URL + '/pasos-compra',
   regiones: BASE_URL + PATH_URL + '/regiones',

   // ASIGNACIONES MASIVAS
   ofertas_productos: BASE_URL + PATH_URL + '/ofertas-productos',
   adicionales_productos: BASE_URL + PATH_URL + '/adicionales-productos',
   puntos_entrega_productos: BASE_URL + PATH_URL + '/puntos-entregas-productos',
   metodos_pagos_productos: BASE_URL + PATH_URL + '/metodos-pagos-productos',
   etiquetas_productos: BASE_URL + PATH_URL + '/etiquetas-productos',

   // ASIGNACIONES MASIVAS INVERSAS
}