
// Components

import { useSelector } from "react-redux";
import { AutoservicioPieChart } from "../../components/Charts/AutoservicioPieChart";


export const Graficas = () => {
	const { resultados: response } = useSelector(state => state.autogestion)
	

	return (
		<div className="col">
			<AutoservicioPieChart data={response} />
		</div>
	);
}
