import { isValidCreateForm, isValidUpdateForm } from "./validations"

export const createFormatter = (form) => {
  const validations = isValidCreateForm(form)

  if (!validations.isValid) {
    console.error({ msg: "Error en validaciones", validations })
    return {}
  }

  const formData = {
    motivo: form.motivo
  }

  return formData
}

export const updateFormatter = (form) => {
  const validations = isValidUpdateForm(form)

  if (!validations.isValid) {
    console.error({ msg: "Error en validaciones", validations })
    return {}
  }

  const formData = {
    motivo: form.motivo
  }

  return formData
}