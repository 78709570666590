import { Link, useLocation } from "react-router-dom"

// Mock
const tabsList = [
   { name: 'Home', url: '/', },
   { name: 'Detalles', url: '/details', }
]


const Tabs = ({ tabs = tabsList }) => {
   const { pathname: path, search } = useLocation()

   const handleActive = (tabUrl) => {
      if (search.length) {
         return tabUrl === `${path}${search}` ? 'active' : ''
      }

      return (path === tabUrl) ? 'active' : ''
   }

   return (
      <div className="col">
         <ul className="nav nav-tabs bg-white rounded-1 border">
            <div className="d-flex tabs">
               {tabs.length > 1 &&
                  tabs.map(tab => (
                     <Link key={tab.url + '' + tab.name} to={tab.url}>
                        <li className={`tabs-item ${handleActive(tab.url)}`}>{tab.name}</li>
                     </Link>
                  ))
               }
            </div>
         </ul>
      </div>
   )
}

export default Tabs