
// Components
import { AutoservicioFiltros } from "../../components/AccordionFilter/Autoservicio/Autoservicio";
import Tabs from "../../components/Tabs";
import { AutoservicioRouter } from "../../routers/Autoservicio/AutoservicioRouter";

const CONSTANT = {
	title: 'Autoservicio',
	path: 'autoservicio',
}

const tabsList = [
	{ name: 'Gráficas', url: '/autoservicio/graficas' },
	{ name: 'Listado', url: '/autoservicio' },
]

export const Index = () => {
	return (
		<div className="row">
			<h3 className="mb-1 d-flex justify-content-between">
				<p><b>{CONSTANT.title}</b> - Datos</p>
				{/* <DropdownWrapper text="Acciones" classes="ms-auto">
					<DropdownMenuLink text={CONSTANT.create_btn} href={`/${CONSTANT.path}/create`} icon="Plus" />
				</DropdownWrapper> */}
			</h3>

			<Tabs
				tabs={tabsList}
			/>

			<AutoservicioFiltros />

			<AutoservicioRouter />
		</div>
	);
}
