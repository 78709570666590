import React, { useEffect, useState } from 'react'


// Redux
import { useDispatch, useSelector } from 'react-redux'
import {
  FILTER_FECHA_INICIO,
  FILTER_FECHA_FINAL,
  FILTER_CODIGO_VENDEDOR,
  FILTER_ADD_METODO_ENTREGA
} from '../../redux/redux-types'
import {
  fechaFinalFilter,
  fechaInicialFilter,
  metodoEntregaFilter,
  vendedorFilter,
} from '../../redux/actions/logisticaReportes'
import { fireToaster } from '../../redux/actions/toaster'
import { clearFilterLogisticaLineasDespacho, metodosEntregaFilter, resultadosLogisticaLineasDespacho } from '../../redux/actions/logisticaLineasDespacho'


// Components
import FormInput from '../FormInput'
import CustomLoader from '../CustomLoader'
import SelectFilter from '../SelectFilter'
import FiltroAplicado from './FiltroAplicado'


// Layouts
import ListadoHeader from '../../layout/ListadoHeader'


// Helpers
import { lastPosition } from '../../helpers/lastPosition'
import { filtrosAplicadosTotal } from '../../helpers/filtrosAplicadosTotal'


// Services

import { getAllMetodosEntrega } from '../../services/metodosEntrega/getAll.service'
import { getAllErpBodegas } from '../../services/bodegas/getAll.erp.service'
import { getAllErpVendedores } from '../../services/vendedores/getAll.erp.service'
import { getAllLogisticaLineasDespacho } from '../../services/logistica/lineasDespacho/getAll.service'
import moment from 'moment'
import { idExtractor } from 'helpers/idExtractor'


const CONSTANT = {
  redirectUrl: '/logistica/lineas-despacho',
  title: 'Filtros: Logística - Líneas Despacho',

  reduxStateSelector: 'logisticaLineasDespacho',
  reduxClearFilters: clearFilterLogisticaLineasDespacho,
  reduxSetResults: resultadosLogisticaLineasDespacho,

  selectFilter: {
    metodosEntrega: [], vendedores: [], bodegas: []
  }
}


export const filtrosFormatter = (filtros) => {
  let validFormat = {}

  Object.entries(filtros).forEach(([k, v]) => {
    if (['fecha_desde', 'fecha_hasta'].includes(k) && v.length) {
      validFormat = {
        ...validFormat,
        [k]: v
      }
    }

    if (['cod_vendedor', 'cods_metodos_entrega'].includes(k) && v.length) {
      validFormat = {
        ...validFormat,
        [k]: idExtractor(v, true)
      }
    }
  })

  return validFormat
}


const fetchData = async (data) => {
  return await Promise.all([
    getAllLogisticaLineasDespacho(data)
      .then(({ data }) => data)
      .catch(err => {
        console.error(err);
        return data
      }),
  ])
    .then(x => {
      let data = {}

      x.forEach(d => {
        data = { ...data, ...d }
      })

      return data
    })
}


const fetchSelectFiltersData = async () => {
  return await Promise.all([
    getAllMetodosEntrega()
      .then(({ data }) => ({ metodosEntrega: data?.data }))
      .catch(err => {
        console.error(err)
        return { metodosEntrega: [] }
      }),

    getAllErpBodegas()
      .then(({ data }) => ({ bodegas: data?.data }))
      .catch(err => {
        console.error(err)
        return { bodegas: [] }
      }),

    getAllErpVendedores()
      .then(({ data }) => ({ vendedores: data?.data }))
      .catch(err => {
        console.error(err)
        return { vendedores: [] }
      }),
  ])
    .then(x => {
      let data = {}

      x.forEach(d => {
        data = { ...data, ...d }
      })

      return data
    })
}


export const LogisticaLineasDespachoFiltros = () => {
  const dispatch = useDispatch()
  const { filtros } = useSelector(state => state[CONSTANT.reduxStateSelector])
  const { fecha_desde, fecha_hasta, cod_vendedor, cods_metodos_entrega } = filtros
  const [selectFilter, setSelectFilter] = useState(CONSTANT.selectFilter)
  const [isConsulting, setIsConsulting] = useState(false)


  // EFECTO QUE SETEA LA DATA DE LOS SELECTFILTERS
  useEffect(() => {
    fetchSelectFiltersData()
      .then(x => setSelectFilter(x))

    dispatch(fechaInicialFilter(moment().format('YYYY-MM-DD')))
    dispatch(fechaFinalFilter(moment().format('YYYY-MM-DD')))
  }, [dispatch])


  // FUNCION QUE CONSULTA EL BACKEND CON LOS FILTROS SELECCIONADOS
  const handleFetchFilter = async (reset = false) => {
    if (reset) {
      dispatch(CONSTANT.reduxClearFilters())
      return
    }

    if (!fecha_desde) return dispatch(fireToaster({ title: 'Debe seleccionar una fecha inicial', icon: 'warning' }))
    if (!fecha_hasta) return dispatch(fireToaster({ title: 'Debe seleccionar una fecha final', icon: 'warning' }))

    setIsConsulting(true)

    await fetchData(filtrosFormatter(filtros))
      .then(res => {
        dispatch(CONSTANT.reduxSetResults(res))
      })
      .finally(() => {
        setIsConsulting(false)
      })
  }


  // MANEJADORES DE FILTRO
  const handleFilter = (e, filter) => {
    const { value } = e.target

    switch (filter) {
      case FILTER_FECHA_INICIO:
        dispatch(fechaInicialFilter(value))
        break;

      case FILTER_FECHA_FINAL:
        dispatch(fechaFinalFilter(value))
        break;

      // case FILTER_ADD_BODEGA:
      //   dispatch(bodegaFilter(value))
      //   break;

      case FILTER_ADD_METODO_ENTREGA:
        dispatch(metodoEntregaFilter(value))
        break;

      case FILTER_CODIGO_VENDEDOR:
        dispatch(vendedorFilter(value))
        break;


      default:
        break;
    }
  }


  return (
    <div className="card mb-50 shadow-none bg-transparent">
      {isConsulting && <CustomLoader blocking={'partial'} />}

      <ListadoHeader
        classes='border rounded-2'
        title={`${CONSTANT.title} (${filtrosAplicadosTotal(filtros)})`}
        handleDisable={isConsulting}
        handleClickSearch={() => handleFetchFilter()}
        handleClickClearFilter={() => handleFetchFilter(true)}
      >
        <>
          <FormInput
            labelText='Fecha inicial'
            size='col-12'
            sizeDesk='col-md-2'
            handleValue={(e) => handleFilter(e, FILTER_FECHA_INICIO)}
            value={fecha_desde}
            type={'date'}
          />

          <FormInput
            labelText='Fecha final'
            size='col-12'
            sizeDesk='col-md-2'
            handleValue={(e) => handleFilter(e, FILTER_FECHA_FINAL)}
            value={fecha_hasta}
            type={'date'}
          />

          {/* <SelectFilter
            labelText='Bodega'
            name='cod_bodega'
            handleValue={(e) => handleFilter(e, FILTER_ADD_BODEGA)}
            optionObj={selectFilter.bodegas.map(({ WARE_CODE, DESC_TEXT }) => ({ id: WARE_CODE, name: DESC_TEXT }))}
            sizeDesk='col-md-3'
            size='col-12'
            value={cod_bodega?.split('-')?.[1] ?? 'Selecciona'}
          /> */}

          <SelectFilter
            labelText='Vendedor'
            name='cod_vendedor'
            handleValue={(e) => handleFilter(e, FILTER_CODIGO_VENDEDOR)}
            optionObj={selectFilter?.vendedores?.map(({ codigo_vendedor, vendedor }) => ({ id: codigo_vendedor, name: vendedor }))}
            sizeDesk='col-md-2'
            size='col-12'
            value={cod_vendedor?.split('-')?.[1] ?? 'Selecciona'}
          />

          <SelectFilter
            labelText='Métodos de entrega'
            name='cods_metodos_entrega'
            handleValue={(e) => handleFilter(e, FILTER_ADD_METODO_ENTREGA)}
            optionObj={selectFilter.metodosEntrega.map(({ id, nombre }) => ({ id, name: nombre }))}
            sizeDesk='col-md-2'
            size='col-12'
            value={lastPosition(cods_metodos_entrega)}
          />
        </>

        <p className='mb-25 text-black'>Filtros aplicados</p>

        <div className='row'>
          <FiltroAplicado array={cods_metodos_entrega} func={metodosEntregaFilter} title={'Métodos de entrega'} />
        </div>

        <hr className='my-1' />
      </ListadoHeader>
    </div>
  )
}