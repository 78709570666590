import {
   FILTER_CLEAR,
   FILTER_REPLANIFICACION_INPUT,
   RESULTADOS_LOGISTICA_REPLANIFICACION_MOTIVOS,
} from "../redux-types";

export const replanificacionInputFilter = (k, v) => ({ type: FILTER_REPLANIFICACION_INPUT, payload: { [k]: v } })

export const resultadosLogisticaReplanificacionMotivos = (data) => ({ type: RESULTADOS_LOGISTICA_REPLANIFICACION_MOTIVOS, payload: data })

export const clearFilterLogisticaReplanificacionMotivos = () => ({ type: FILTER_CLEAR });
