import { useEffect, useState } from "react";
import axios from "axios";


// Redux
import { useDispatch } from "react-redux";
import { fireToaster } from "redux/actions/toaster";


//	Components
import FormInput from "components/FormInput";
import Button from "components/Button";
import CustomForm from "components/CustomForm";
import Switch from "components/Switch";
import CustomLoader from "components/CustomLoader";
import FileUploaderB from "components/FileUploader_B";
import SelectFilter from "components/SelectFilter";
import TextareaInput from "components/TextareaInput";


// React-router
import { useNavigate, useParams } from "react-router-dom";


// Endpoints
import { getOne } from "endpoints/getOne";
import { BASE_URL } from "endpoints";
import { getAll } from "endpoints/getAll";


// Services
import { searchCategorias } from "services/categorias/search.service";
import { getAllUnidades } from "services/unidades/getAll.service";


const CONSTANT = {
  redirectUrl: '/productos/valores-atributos',
  componentTitle: 'Editar valor/atributo',
  title: 'Valores/Atributos',
  queryUrl: getOne.valores_atributos,

  logo: (data) => `${BASE_URL}storage/${data?.logo}` ?? '/placeholder.png',
  fetchClavesAtributos: (categoria) => getAll.claves_atributos + `/filtros?categoria_id=${categoria?.split('-')[0] ?? 1}`,

  nullObject: { id: '*NULL*', nombre: '- Error -' },
  unselectedObject: { id: '*NULL*', nombre: 'Sin selección' },

  initialFormState: {
    nombre: '',
    categoria_id: '',
    clave_atributo_id: '',
    unidad_id: '',
    simbolo: '',
    codigo: '',
    estatus: 1,

    descripcion: '',
    descripcion_titulo: '',
    descripcion_enlace: '',
  },

  selectFilterInitialState: {
    unidades: [],
    clavesAtributos: [],
    categorias: []
  }
}


const handleSelectFiltersData = async () => {
  return await Promise.all([
    getAllUnidades()
      .then(({ data }) => ({ unidades: [CONSTANT.unselectedObject, ...data?.data] }))
      .finally(err => {
        console.error(err)
        return [CONSTANT.nullObject]
      }),

    searchCategorias()
      .then(({ data }) => ({ categorias: [CONSTANT.unselectedObject, ...data?.categorias?.data] }))
      .finally(err => {
        console.error(err)
        return [CONSTANT.nullObject]
      })
  ])
    .then(arrRes => {
      let res = {}
      arrRes.forEach(d => Object.entries(d).forEach(([k, v]) => res = { ...res, [k]: v }))
      return res
    })
}


const Update = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [logo, setLogo] = useState([])
  const [imagenesProductos, setImagenesProductos] = useState([])
  const [selectFilter, setSelectFilter] = useState(CONSTANT.selectFilterInitialState)
  const [fetchData, setFetchData] = useState(CONSTANT.initialFormState)
  const { id } = useParams()


  // EFECTO QUE RELLENA LOS SELECTFILTERS
  useEffect(() => {
    handleSelectFiltersData().then(data => setSelectFilter(data))
  }, [])


  // FUNCIÓN MANEJADORA QUE ACTUALIZA EL ESTADO DE 'FetchData'
  const handleInputChange = (e) => {
    const { target } = e

    setFetchData({
      ...fetchData,
      [target.name]: target.value,
    })
  }


  // EFECTO QUE CONSULTA LA DATA DEL REGISTRO Y ACTUALIZA EL ESTADO 'FetchData'
  useEffect(() => {
    axios(CONSTANT.queryUrl(id),
      {
        headers: {
          'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
        },
      })
      .then(({ data }) => {
        setFetchData({
          ...data,
          clave_atributo_id: `${data?.neu_clave_atributo?.id}-${data?.neu_clave_atributo?.nombre}`,
          unidad_id: `${data?.unidad?.id ?? '*NULL*'}-${data?.unidad?.nombre ?? 'Sin selección'}`
        })
      })
      .catch(err => console.error(err))
    return () => {
      setFetchData(null)
      setSelectFilter(CONSTANT.selectFilterInitialState)
    }
  }, [id])


  const handleFetch = async (e) => {
    e.preventDefault()

    let data = new FormData()
    data.append('nombre', fetchData?.nombre)
    data.append('clave_atributo_id', Number(fetchData?.clave_atributo_id?.split('-')[0]))
    fetchData.unidad_id.length && !fetchData.unidad_id.includes('*NULL*') && data.append('unidad_id', Number(fetchData?.unidad_id?.split('-')[0]))
    data.append('simbolo', fetchData?.simbolo)
    data.append('codigo', fetchData?.codigo)
    fetchData?.descripcion?.length && data.append('descripcion', fetchData?.descripcion)
    logo.length && data.append('logo', logo[0].file)
    data.append('estatus', Number(fetchData?.estatus))
    data.append('_method', 'PUT')
    data.append('valor_default', Number(fetchData.valor_default))
    imagenesProductos.length && imagenesProductos.forEach(({ file }) => data.append('imagenes_productos[]', file))

    fetchData.descripcion.length && data.append('descripcion', fetchData?.descripcion)
    fetchData.descripcion_titulo.length && data.append('descripcion_titulo', fetchData?.descripcion_titulo)
    fetchData.descripcion_enlace.length && data.append('descripcion_enlace', fetchData?.descripcion_enlace)

    axios(CONSTANT.queryUrl(id), {
      method: 'POST',
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null,
        'Content-Type': 'multipart/form-data'
      },
      data
    })
      .then(({ data }) => {
        const toasterContent = {
          title: 'Operación realizada',
          text: `${CONSTANT.title}: ${data.nombre} creado con éxito`,
          icon: 'success'
        }
        dispatch(fireToaster(toasterContent))
        navigate(CONSTANT.redirectUrl)
      })
      .catch(err => {
        console.error(err);
        const { errores } = err.response.data
        let detalles = []
        Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))

        const toasterContent = {
          title: `
            Operación fallida
            (${err.response.status} - ${err.response.statusText})
          `,
          html: `
            <b>Detalle: </b>
            ${detalles.map(error => `<br /><i>-${error}</i>`)}
          `,
          icon: 'error'
        }
        dispatch(fireToaster(toasterContent))
      })
  }

  return (
    <div className="row">
      <div className="col col-md-6 mx-auto">
        <div className='col'>
          <Button text={CONSTANT.title} icon='ArrowLeft' style='flat' isLink url={CONSTANT.redirectUrl} />
        </div>

        {selectFilter.unidades && selectFilter.categorias && fetchData?.id
          ? <CustomForm size="" title={CONSTANT.componentTitle}>
            <div className="row">
              <Switch
                name="estatus"
                value={fetchData.estatus}
                labelText="Estatus"
                handleValue={handleInputChange}
                size="col-12"
                sizeDesk='justify-content-end'
              />

              <Switch
                name="valor_default"
                value={fetchData.valor_default ? 1 : 0}
                labelText="Valor por defecto"
                handleValue={handleInputChange}
                size="col-12"
                sizeDesk='justify-content-end'
              />

              <SelectFilter
                name="categoria_id"
                value={fetchData?.categoria_id?.split('-')[1] ?? ''}
                labelText="* Categoría"
                handleValue={handleInputChange}
                optionObj={selectFilter.categorias?.map(({ id, nombre }) => ({ id, name: nombre }))}
                sizeDesk='col-md-6'
                size="col-12"
              />

              {selectFilter?.clavesAtributos?.length && fetchData.categoria_id?.length

                ? <SelectFilter
                  name="clave_atributo_id"
                  value={fetchData?.clave_atributo_id?.split('-')[1] ?? ''}
                  labelText="* Clave/Atributo"
                  handleValue={handleInputChange}
                  optionObj={selectFilter.clavesAtributos?.map(({ id, nombre }) => ({ id, name: nombre }))}
                  sizeDesk='col-md-6'
                  size="col-12"
                />

                : <FormInput
                  isDisabled
                  value={fetchData?.clave_atributo_id?.split('-')[1] ?? ''}
                  labelText="* Clave/Atributo"
                  size="col-12"
                  sizeDesk='col-md-6'
                />
              }

              <FormInput
                name="nombre"
                value={fetchData?.nombre ?? ''}
                labelText="* Nombre"
                handleValue={handleInputChange}
                size="col-12"
                sizeDesk='col-md-4'
              />

              <SelectFilter
                name="unidad_id"
                value={fetchData?.unidad_id?.split('-')[1] ?? ''}
                labelText="Unidad"
                handleValue={handleInputChange}
                optionObj={selectFilter.unidades?.map(({ id, nombre }) => ({ id, name: nombre }))}
                size="col-12"
                sizeDesk='col-md-3'
              />

              <FormInput
                name="simbolo"
                value={fetchData?.simbolo ?? ''}
                labelText="Símbolo"
                handleValue={handleInputChange}
                size="col-12"
                sizeDesk='col-md-2'
              />

              <FormInput
                name="codigo"
                value={fetchData?.codigo ?? ''}
                labelText="Código"
                handleValue={handleInputChange}
                size="col-12"
                sizeDesk='col-md-3'
              />

              <TextareaInput
                name="descripcion"
                value={fetchData.descripcion ?? ''}
                labelText="Descripción"
                handleValue={handleInputChange}
                rows={3}
                size="col-12"
                sizeDesk='col-md-12'
              />

              <div className="input-inset col-12">
                <label className="form-label">Imagen actual</label>
                <div className="form-outline border rounded-2 lista-detalle mb-2 d-flex justify-content-center p-2 w-fit">
                  <img src={CONSTANT.logo(fetchData)} alt={`img-${fetchData?.nombre}`} />
                </div>
              </div>

              <div className="input-inset col mb-2">
                <label className="form-label">Imágenes (<b>Producto</b>)</label>
                <div className="d-flex border rounded-3 p-1 gap-1">
                  {fetchData?.imagenes_productos?.length

                    ? fetchData?.imagenes_productos?.split(',').map((imagen, i) => (
                      <img
                        key={`clave-atributo-imagen-${i}-${imagen?.nombre ?? 'unk'} `}
                        className='height-listado'
                        src={BASE_URL + 'storage/' + imagen}
                        alt="img"
                      />
                    ))

                    : <i className="my-auto">Sin imágenes para mostrar...</i>

                  }
                </div>
              </div>

              <FileUploaderB labelText='Logo' handleValue={setLogo} value={logo} />

              <FileUploaderB labelText='Productos' handleValue={setImagenesProductos} value={imagenesProductos} />

              <hr />

              <p className="fw-bold text-center fs-3">Datos descripción</p>

              <FormInput
                name="descripcion_titulo"
                value={fetchData.descripcion_titulo ?? ''}
                labelText="Título"
                handleValue={handleInputChange}
                size="col-12"
                sizeDesk='col-md-12'
              />

              <TextareaInput
                name="descripcion"
                value={fetchData.descripcion ?? ''}
                labelText="Descripción"
                handleValue={handleInputChange}
                rows={3}
                size="col-12"
                sizeDesk='col-md-12'
              />

              <FormInput
                name="descripcion_enlace"
                value={fetchData.descripcion_enlace ?? ''}
                labelText="Enlace"
                handleValue={handleInputChange}
                size="col-12"
                sizeDesk='col-md-12'
              />
            </div>

            <Button
              type="submit"
              text="Actualizar"
              color="primary"
              icon="Circle"
              onClick={handleFetch}
            />
          </CustomForm>

          : <CustomLoader />
        }

      </div>
    </div>

  );
};

export default Update;
