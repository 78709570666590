import { useEffect, useState } from "react"
import moment from "moment"


// Components
import { filtrosFormatter } from "components/AccordionFilter/Replanificacion"
import Modal from "components/Modal"
import Button from "components/Button"
import FormInput from "components/FormInput"
import CustomLoader from "components/CustomLoader"


// Redux
import { useDispatch, useSelector } from "react-redux"
import { fireToaster } from "redux/actions/toaster"
import { resultadosLogisticaReplanificacion } from "redux/actions/logisticaReplanificacion"


// Services
import { createPlanificarNv } from "services/notasVentas/planificar.service"
import { getAllLogisticaReplanificacion } from "services/logistica/replanificacion/getAll.service"


// Helpers
import { responseToasterContent } from "helpers/toasterContent"


const CONSTANT = {
  reduxSelector: 'logisticaReplanificacion',
  reduxSetResults: resultadosLogisticaReplanificacion,

  form: {
    fecha_planificacion: '',
    nota_venta: null
  },
}


const fetchData = async (params) => {
  return await Promise.all([
    getAllLogisticaReplanificacion(params)
      .then(({ data }) => data)
      .catch(err => {
        console.error(err);
        return []
      }),
  ])
    .then(x => {
      let data = {}

      x.forEach(d => {
        data = { ...data, ...d }
      })

      return data
    })
}


export const ModalPlanificacion = ({ display, handleDisplay, data }) => {
  const { filtros } = useSelector(state => state[CONSTANT.reduxSelector])
  const [isLoading, setIsLoading] = useState(false)
  const [form, setForm] = useState(CONSTANT.form)
  const dispatch = useDispatch()


  useEffect(() => {
    setForm({ nota_venta: data?.nota_venta, fecha_planificacion: moment().format('YYYY-MM-DD') })

    return () => {
      setForm(CONSTANT.form)
    }
  }, [data])


  const handleCloseFull = () => {
    handleDisplay(null)
  }


  const handleForm = (e) => {
    const { name, value } = e.target

    setForm(prev => ({
      ...prev,
      [name]: value
    }))
  }


  const handleSubmit = async (e) => {
    e.preventDefault()
    setIsLoading(true)

    await createPlanificarNv(form)
      .then(async res => {
        dispatch(fireToaster(responseToasterContent(res)))

        await fetchData(filtrosFormatter(filtros))
          .then(res => {
            dispatch(CONSTANT.reduxSetResults(res))

            handleCloseFull()
          })
          .finally(() => {
            setIsLoading(false)
          })
      })
      .catch(err => {
        dispatch(fireToaster(responseToasterContent(err)))
      })
      .finally(() => {
        setIsLoading(false)
      })
  }


  return (
    <Modal
      size={`col-8`}
      sizeDesk={`col-md-3`}
      display={display}
      handleValue={handleCloseFull}
    >
      {isLoading && <CustomLoader blocking='full' />}

      <p className='fw-bold fs-3'>Planificacion de nota de venta #{data?.nota_venta}</p>

      <hr className='mt-2 mb-4' />

      <form onSubmit={handleSubmit}>
        <FormInput
          type='date'
          handleValue={handleForm}
          name='fecha_planificacion'
          value={form.fecha_planificacion}
          size='col-12'
          sizeDesk='col-md-8'
          classes='mx-auto'
        />

        <div>
          <Button
            className='mx-auto'
            text='Planificar'
            icon='Check'
            onClick={handleSubmit}
            type="submit"
          />
        </div>
      </form>
    </Modal>
  )
}
