import axios from "axios";


//	Components
import Button from "../../components/Button";
import CustomForm from "../../components/CustomForm";
import Switch from "../../components/Switch";
import FormInput from "../../components/FormInput";


// Redux
import { useDispatch } from "react-redux";
import { fireToaster } from "../../redux/actions/toaster";


// Endpoint
import { create } from "../../endpoints/create";


// React-router
import { useNavigate } from "react-router-dom";
import SelectFilter from "../../components/SelectFilter";
import TextareaInput from "../../components/TextareaInput";
import { useEffect, useState } from "react";
import { getAll } from "../../endpoints/getAll";
import { idExtractor } from "../../helpers/idExtractor";
import CustomLoader from "../../components/CustomLoader";
import FileUploaderB from "../../components/FileUploader_B";
import { getAllBodegas } from "services/bodegas/getAll.service";
import Tooltip from "../../components/Buttons/Tooltip";


const handleNullOrInteger = (value) => {
  if (!value || !value.length) return null
  else return Number(value)
}


const CONSTANT = {
  redirectUrl: '/puntos-entrega',
  nullObject: { id: '*NULL*', nombre: '- Error -' },
  unselectedObject: { id: '*NULL*', nombre: 'Sin selección' },
  componentTitle: 'Crear punto de entrega',
  title: 'Puntos de entrega',

  queryUrl: create.puntos_entrega,

  fecthComunas: getAll.comunas + '/buscar',
  fecthTiposDestino: getAll.tipos_destino,
  fecthProveedores: getAll.proveedores,
  fetchMetodosEntrega: getAll.metodos_entrega,
  fetchBodegasServicio: getAllBodegas,

  initialFormState: {
    comuna_id: "",

    asignar_por_defecto: false,
    tiene_instalacion_por_defecto: false,
    tiene_despacho_por_defecto: false,
    instalacion_por_defecto: "",
    despacho_por_defecto: "",

    tipo_destino_id: "",
    metodo_entrega_id: "",
    rut_proveedor: "",
    nombre: "",
    descripcion: "",
    direccion: "",
    url_mapa: "",
    sucursal_propia: true,
    sucursal_maxi: '',
    estatus: true,
    aplicar_instalacion_hasta: '',
    es_tarifa_plana: 0,
    bodega_instalacion_id: '',
    meta: {}
  },

  selectFilterInitialState: {
    comunas: [],
    tiposDestino: [],
    metodosEntrega: [],
    proveedores: [],
    bodegasServicio: []
  }
}


const handleSelectFiltersData = async () => {
  const comunas = await axios(CONSTANT.fecthComunas,
    {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      }, method: 'POST'
    })
    .then(({ data }) => [CONSTANT.unselectedObject, ...data?.comunas] ?? [CONSTANT.unselectedObject])
    .catch(err => {
      console.error(err)
      return [CONSTANT.nullObject]
    })

  const tiposDestino = await axios(CONSTANT.fecthTiposDestino,
    {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
    })
    .then(({ data }) => [CONSTANT.unselectedObject, ...data] ?? [CONSTANT.unselectedObject])
    .catch(err => {
      console.error(err)
      return [CONSTANT.nullObject]
    })

  const metodosEntrega = await axios(CONSTANT.fetchMetodosEntrega,
    {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
    })
    .then(({ data }) => [CONSTANT.unselectedObject, ...data] ?? [CONSTANT.unselectedObject])
    .catch(err => {
      console.error(err)
      return [CONSTANT.nullObject]
    })

  const proveedores = await axios(CONSTANT.fecthProveedores,
    {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
    })
    .then(({ data }) => [CONSTANT.unselectedObject, ...data] ?? [CONSTANT.unselectedObject])
    .catch(err => {
      console.error(err)
      return [CONSTANT.nullObject]
    })

  const bodegasServicio = await CONSTANT.fetchBodegasServicio()
    .then(({ data }) => [CONSTANT.unselectedObject, ...data] ?? [CONSTANT.unselectedObject])
    .catch(err => {
      console.error(err)
      return [CONSTANT.nullObject]
    })

  return { comunas, tiposDestino, metodosEntrega, proveedores, bodegasServicio }
}


const Create = () => {
  const [image, setImage] = useState([])
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [form, setForm] = useState(CONSTANT.initialFormState);
  const [selectFilter, setSelectFilter] = useState(CONSTANT.selectFilterInitialState)


  // EFFECTO QUE DISPARA EL MANEJADOR DE DATA PARA SELECTFILTERS
  useEffect(() => {
    handleSelectFiltersData().then(data => setSelectFilter(data))
  }, [])


  // FUNCION MANEJADORA DE LOS INPUTS
  const handleInputChange = (e) => {
    const { name, value } = e.target

    setForm({
      ...form,
      [name]: value
    })
  }

  const handleMetadatos = (e) => {
    const { name, value } = e.target

    if (['dias_despacho'].includes(name)) {
      if ((isNaN(Number(value)))) return

      setForm(form => ({
        ...form,
        meta: {
          [name]: value
        }
      }))

      return
    }

    setForm(form => ({
      ...form,
      meta: {
        [name]: value
      }
    }))
  }

  // FUNCIÓN MANEJADORA QUE CREA EL REGISTRO EN LA BBDD
  const handleFetch = (e) => {
    e.preventDefault()

    if (!form.comuna_id.length || form.comuna_id.split('-')[0] === '*NULL*') return dispatch(fireToaster({ title: 'La comuna es obligatoria', icon: 'info' }))
    if (!form.tipo_destino_id.length || form.tipo_destino_id.split('-')[0] === '*NULL*') return dispatch(fireToaster({ title: 'El tipo de destino es obligatorio', icon: 'info' }))
    if (!form.sucursal_maxi) return dispatch(fireToaster({ title: 'La sucursal maxi es obligatoria', icon: 'info' }))
    if (!form.nombre.length) return dispatch(fireToaster({ title: 'El nombre es obligatorio', icon: 'info' }))
    if (!form.url_mapa.length) return dispatch(fireToaster({ title: 'La URL es obligatoria', icon: 'info' }))
    if (!image?.length) return dispatch(fireToaster({ title: 'La imagen es obligatoria', icon: 'info' }))

    let data = new FormData()
    data.append('comuna_id', idExtractor(form.comuna_id))
    data.append('tipo_destino_id', idExtractor(form.tipo_destino_id))
    data.append('metodo_entrega_id', idExtractor(form.metodo_entrega_id))
    form?.bodega_instalacion_id?.length && !form.bodega_instalacion_id.includes('*NULL*') && data.append('bodega_instalacion_id', idExtractor(form.bodega_instalacion_id))
    form?.rut_proveedor?.length && !form.rut_proveedor.includes('*NULL*') && data.append('rut_proveedor', idExtractor(form.rut_proveedor, true))
    data.append('nombre', form.nombre)
    data.append('imagen', image[0].file)
    form.descripcion.length && data.append('descripcion', form.descripcion)
    form.direccion.length && data.append('direccion', form.direccion)
    data.append('url_mapa', form.url_mapa)
    data.append('sucursal_propia', form.sucursal_propia ? 1 : 0)
    data.append('sucursal_maxi', form.sucursal_maxi)
    data.append('aplicar_instalacion_hasta', form.aplicar_instalacion_hasta)
    data.append('es_tarifa_plana', form.es_tarifa_plana)
    data.append('estatus', form.estatus ? 1 : 0)
    if (form.meta?.dias_despacho) { data.append('dias_despacho', form.meta?.dias_despacho) }
    // Atributos "por defecto"
    data.append('asignar_por_defecto', form.asignar_por_defecto ? 1 : 0)
    data.append('tiene_despacho_por_defecto', form.tiene_despacho_por_defecto ? 1 : 0)
    data.append('tiene_instalacion_por_defecto', form.tiene_instalacion_por_defecto ? 1 : 0)
    form.tiene_despacho_por_defecto && data.append('despacho_por_defecto', form.despacho_por_defecto)
    form.tiene_instalacion_por_defectoF && data.append('instalacion_por_defecto', form.instalacion_por_defecto)


    axios(CONSTANT.queryUrl, {
      method: 'POST',
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null,
        'Content-Type': 'multipart/form-data'
      },
      data
    })
      .then(({ data }) => {
        const toasterContent = {
          title: 'Operación realizada',
          text: `${CONSTANT.title}: ${data.nombre} creado con éxito`,
          icon: 'success'
        }
        dispatch(fireToaster(toasterContent))
        navigate(CONSTANT.redirectUrl)
      })
      .catch(err => {
        console.error(err)
        const { errores } = err.response.data
        let detalles = []
        Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))

        const toasterContent = {
          title: `
            Operación fallida
            (${err.response.status} - ${err.response.statusText})
          `,
          html: `
            <b>Detalle: </b>
            ${detalles.map(error => `<br /><i>-${error}</i>`)}
          `,
          icon: 'error'
        }
        dispatch(fireToaster(toasterContent))
      })
  }


  return (
    <div className="row">
      <div className="col col-md-6 mx-auto">
        <div className='col'>
          <Button text={CONSTANT.title} icon='ArrowLeft' style='flat' isLink url={CONSTANT.redirectUrl} />
        </div>

        {selectFilter.comunas.length && selectFilter.tiposDestino.length && selectFilter.metodosEntrega.length && selectFilter.proveedores.length
          ? <CustomForm size="" title={CONSTANT.componentTitle}>
            <div className="row">
              <div className="col-6">
                <Switch
                  name="estatus"
                  value={form.estatus}
                  labelText="Estatus"
                  handleValue={handleInputChange}
                  size="col-12"
                  classes='justify-content-end'
                />

                <Switch
                  name="es_tarifa_plana"
                  value={form.es_tarifa_plana}
                  labelText="Tarifa plana"
                  handleValue={handleInputChange}
                  size="col-12"
                  classes='justify-content-end'
                />
              </div>

              <div className="col-6">
                <Switch
                  name="sucursal_propia"
                  value={form.sucursal_propia}
                  labelText="Sucursal propia"
                  handleValue={handleInputChange}
                  size="col-12"
                  classes='justify-content-end'
                />

                <Switch
                  name="asignar_por_defecto"
                  value={form.asignar_por_defecto}
                  labelText="Asignar por defecto"
                  handleValue={handleInputChange}
                  size="col-12"
                  classes='justify-content-end'
                />
              </div>
              
              <FormInput
                name="nombre"
                value={form.nombre}
                labelText="* Nombre"
                placeholder='Nombre'
                handleValue={handleInputChange}
                size="col-12"
                sizeDesk='col-md-6'
              />

              <FormInput
                type='number'
                name="aplicar_instalacion_hasta"
                value={form.aplicar_instalacion_hasta}
                labelText="Aplicar instalación hasta ($)"
                placeholder='Monto'
                handleValue={handleInputChange}
                size="col-12"
                sizeDesk='col-md-6'
              />

              <FormInput
                type='number'
                name="sucursal_maxi"
                value={form.sucursal_maxi}
                labelText="* Sucursal maxi."
                placeholder='Maxi...'
                handleValue={handleInputChange}
                size="col-12"
                sizeDesk='col-md-6'
              />

              <SelectFilter
                name="comuna_id"
                value={form.comuna_id?.split('-')[1] ?? ''}
                labelText="* Comuna"
                placeholder='Comuna'
                handleValue={handleInputChange}
                optionObj={selectFilter.comunas?.map(({ nombre, id }) => ({ id, name: nombre }))}
                size="col-12"
                sizeDesk='col-md-6'
              />

              <SelectFilter
                name="tipo_destino_id"
                value={form.tipo_destino_id?.split('-')[1] ?? ''}
                labelText="* Tipo destino"
                placeholder='Tipo destino'
                handleValue={handleInputChange}
                optionObj={selectFilter.tiposDestino?.map(({ nombre, id }) => ({ id, name: nombre }))}
                size="col-12"
              />

              <SelectFilter
                name="metodo_entrega_id"
                value={form.metodo_entrega_id?.split('-')[1] ?? ''}
                labelText="* Método de entrega"
                placeholder='Método de entrega'
                handleValue={handleInputChange}
                optionObj={selectFilter.metodosEntrega?.map(({ nombre, id }) => ({ id, name: nombre }))}
                size="col-12"
              />

              <hr className='my-1' />

              <Switch
                name="tiene_instalacion_por_defecto"
                value={form.tiene_instalacion_por_defecto}
                labelText="Aplicar instalación por defecto"
                handleValue={handleInputChange}
                size="col-12"
                sizeDesk='col-md-6'
                classes='justify-content-end my-auto'
              />

              <FormInput
                type='number'
                name="instalacion_por_defecto"
                value={form.instalacion_por_defecto ?? ''}
                labelText="Instalación por defecto ($)"
                placeholder='Monto'
                handleValue={handleInputChange}
                isDisabled={!form?.tiene_instalacion_por_defecto}
                size="col-12"
                sizeDesk='col-md-6'
              />

              <Switch
                name="tiene_despacho_por_defecto"
                value={form.tiene_despacho_por_defecto}
                labelText="Aplicar despacho por defecto"
                handleValue={handleInputChange}
                size="col-12"
                sizeDesk='col-md-6'
                classes='justify-content-end my-auto'
              />

              <FormInput
                type='number'
                name="despacho_por_defecto"
                value={form.despacho_por_defecto ?? ''}
                labelText="Despacho por defecto ($)"
                placeholder='Monto'
                handleValue={handleInputChange}
                isDisabled={!form?.tiene_despacho_por_defecto}
                size="col-12"
                sizeDesk='col-md-6'
              />

              <hr className='my-1' />

              <SelectFilter
                altSeparator
                name="rut_proveedor"
                value={form.rut_proveedor?.split('%-%')[1] ?? ''}
                labelText="RUT del proveedor"
                placeholder='RUT'
                handleValue={handleInputChange}
                optionObj={selectFilter.proveedores?.map(({ nombre, rut, id }) => ({ id: rut ?? id ?? '*NULL*', name: `${rut ?? 'N/A'} | ${nombre ?? 'Error'}` }))}
                size="col-12"
                sizeDesk='col-md-12'
              />

              <SelectFilter
                name="bodega_instalacion_id"
                value={form.bodega_instalacion_id?.split('-')[1] ?? ''}
                labelText="* Bodega de servicio"
                placeholder='Bodega de servicio'
                handleValue={handleInputChange}
                optionObj={selectFilter.bodegasServicio?.map(({ nombre, id, codigo }) => ({ id, name: `${codigo ?? 'N/A'} | ${nombre}` }))}
                size="col-12"
                sizeDesk='col-md-12'
              />

              <FormInput
                name="direccion"
                value={form.direccion}
                labelText="Dirección"
                placeholder='Dirección'
                handleValue={handleInputChange}
                size="col-12"
              />

              <TextareaInput
                name="descripcion"
                value={form.descripcion}
                labelText="Descrición"
                placeholder='Descrición'
                handleValue={handleInputChange}
                size="col-12"
                rows={2}
              />

              <FormInput
                name="url_mapa"
                value={form.url_mapa}
                labelText="* URL del mapa"
                placeholder='URL del mapa'
                handleValue={handleInputChange}
                size="col-12"
              />

              <FormInput
                name="dias_despacho"
                value={form.meta?.dias_despacho}
                labelText="* Días de despacho"
                placeholder='Días'
                handleValue={handleMetadatos}
                size="col-12"
                sizeDesk='col-md-6'
              />
            </div>

            <FileUploaderB value={image} handleValue={setImage} labelText='* Imagen' />

            <Button type="submit" text="Crear" color="primary" icon="Check" onClick={handleFetch} />
          </CustomForm>

          : <CustomLoader />
        }
      </div>
    </div>
  );
};

export default Create;
