
// Components
import { LogisticaReplanificacionMotivosFiltros } from "../../../components/AccordionFilter/ReplanificacionMotivos";
import Button from "../../../components/Button";
import { ReplanificacionMotivosTable } from "../../../components/Tables/Logistica/ReplanificacionMotivosTable";


const CONSTANT = {
	title: 'Motivos de replanificación',
	path: 'logistica/replanificacion-motivos',
	create_btn: 'Crear motivo'
}


const Listado = () => {
	return (
		<div className="row">
			<h3 className="mb-1 d-flex justify-content-between flex-column">
				<p><b>{CONSTANT.title}</b> - Listado</p>

				<Button isLink text={CONSTANT.create_btn} url={`/${CONSTANT.path}/create`} icon="Plus" className={'w-fit'} />
			</h3>

			<LogisticaReplanificacionMotivosFiltros />

			<ReplanificacionMotivosTable />
		</div>
	);
}

export default Listado;