
// Redux


// React-Router


// Components
import { LogisticaDespachosFiltros } from "components/AccordionFilter/Despachos";
import { DespachosTable } from "components/Tables/Logistica/DespachosTable";


// Helpers


// Services


const CONSTANT = {
  title: 'Logistica - Despachos',
  path: 'logistica/despachos',
}


export const Listado = () => {

  return (
    <div className="row">
      <h3 className="mb-1 d-flex justify-content-between">
        <p><b>{CONSTANT.title}</b></p>
      </h3>

      <LogisticaDespachosFiltros />

      <DespachosTable />
    </div>
  );
}
