import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';


// Redux
import { useSelector } from 'react-redux'
import { clearFilterLogisticaDespachos, resultadosLogisticaDespachos } from 'redux/actions/logisticaDespachos';


// Components
import Table from "components/Table";
import { ModalLineaDespachoDetalle } from 'components/Modal/Logisitica/ModalLineaDespachoDetalle';
import { ModalPlanificacion } from 'components/Modal/Logisitica/ModalPlanificacion';


// Layouts
import AccordionTable from "layout/AccordionTable";
import Button from 'components/Button';
import { useCopyClipboard } from 'hooks/useCopyClipboard';


// Services


// Helpers/Hooks


const CONSTANT = {
  permissionsPath: 'notas-venta',
  pathUrl: '/logistica/despachos',
  title: 'Despachos',

  reduxSelector: 'logisticaDespachos',
  reduxClearFilters: clearFilterLogisticaDespachos,
  reduxSetResults: resultadosLogisticaDespachos,
}




// TODO: consultar si cuando la nv esta planificada deshabilitar el boton de planificacion en la tabla
export const DespachosTable = () => {
  const { resultados: response } = useSelector(state => state[CONSTANT.reduxSelector])
  const [modalLineaDetalle, setModalLineaDetalle] = useState({ display: false, data: null })
  const [modalPlanificar, setModalPlanificar] = useState({ display: false, data: null })
  const [allNvs, setAllNvs] = useState('Sin datos')
  const [copy] = useCopyClipboard()

  useEffect(() => {
    if (!response) return setAllNvs('Sin datos')
    const nvStrings = response?.data_despachos.map(({ nota_venta }) => nota_venta).join('|')

    setAllNvs(nvStrings)
  }, [response])


  const handleModalLineaDetalle = (data) => {
    setModalLineaDetalle(prev => ({ display: !prev.display, data }))
  }


  const handleModalPlanificar = async (data) => {
    setModalPlanificar(prev => ({ display: !prev.display, data }))
  }


  return (
    <>
      {response?.data_despachos
        ?
        <>
          {/* MARK: LISTADO */}
          <AccordionTable title={`${CONSTANT.title} (${response?.data_despachos?.length ?? 0} resultados)`}>
            <Table response={response} pb={false}>
              <thead className="table-light position-sticky top-0 w-100 z-100 shadow">
                <tr role="row">
                  <th>NV</th>
                  <th>Fecha planificación</th>
                  <th>Tipo despacho</th>
                  <th>Es excepción</th>
                  <th>Creación</th>
                </tr>
              </thead>

              <tbody>
                {response?.data_despachos?.length
                  ? response?.data_despachos?.map(({
                    nota_venta, es_excepcion, fecha_planificacion, data_nv, created_at
                  }, i) => {
                    return (
                      <tr className="odd" key={`replanificacion-${nota_venta}-${i}`}>
                        <td className='col-1'>
                          <Link target='_blank' rel='noreferrer' to={`/erp/notas-ventas/detalle/${nota_venta}`}>{nota_venta}</Link>
                        </td>

                        <td>{fecha_planificacion ?? 'N/A'}</td>

                        <td>{data_nv?.nv?.TIPO_DESPACHO ?? 'N/A'}</td>

                        <td>{es_excepcion ? 'Sí' : 'No'}</td>

                        <td>{moment(created_at).format('DD-MM-YYYY')}</td>
                      </tr>
                    )
                  })
                  : <tr><td className="text-center fw-bolder fs-2 text-lgray" colSpan={9}><i>Sin datos para mostrar...</i></td></tr>
                }
              </tbody>
            </Table>
          </AccordionTable>


          {/* MARK: AGRUPADAS */}
          <AccordionTable title={`Despachos agrupados`} classes='mt-2'>
            <Table pb={false}>
              <thead className="table-light position-sticky top-0 w-100 z-100 shadow">
                <tr role="row">
                  <th className='col-2'>Bodega</th>

                  <th>Notas de venta</th>

                  <th className='col-1'>Total</th>
                </tr>
              </thead>

              <tbody>
                <tr className={`odd multi-data`} style={{ backgroundColor: '#dfffc7' }}>
                  <td className='fw-bold'>Todas las bodegas</td>
                  <td className='fw-bold'>
                    <div className='d-flex gap-1'>
                      <Button className='p-25' icon='Clipboard' disabled={!allNvs?.length} onClick={() => copy(allNvs)} />
                      {allNvs}
                    </div>
                  </td>
                  <td className='fw-bold'>{response?.total_neumaticos ?? 0}</td>
                </tr>

                {response?.datos_por_bodega?.length
                  ? response?.datos_por_bodega?.map(({ nvs, bodega, total }, i) => {
                    return (
                      <tr key={`notas-ventas-${bodega}-${i}`} className={`odd multi-data`}>
                        <td className='fw-bold'>{bodega}</td>
                        <td className='fw-bold'>
                          <div className='d-flex gap-1'>
                            <Button className='p-25' icon='Clipboard' disabled={!nvs?.length} onClick={() => copy(nvs)} />
                            {nvs}
                          </div>
                        </td>
                        <td className='fw-bold'>{total}</td>
                      </tr>
                    )
                  })
                  : <tr><td className="text-center fw-bolder fs-2 text-lgray" colSpan={3}><i>Sin datos para mostrar...</i></td></tr>
                }
              </tbody>
            </Table>
          </AccordionTable>
        </>

        : <div className='col-12'><h2 className='card p-2 text-center mb-0 fw-bolder'><i>Por favor realice una búsqueda</i></h2></div>}

      <ModalPlanificacion
        data={modalPlanificar?.data}
        display={modalPlanificar?.display}
        handleDisplay={handleModalPlanificar}
      />

      <ModalLineaDespachoDetalle
        display={modalLineaDetalle?.display}
        data={modalLineaDetalle?.data}
        handleDisplay={handleModalLineaDetalle}
      />
    </>
  )
}
