import moment from 'moment'
import axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'


// Redux
import { fireToaster } from '../../../redux/actions/toaster'
import { useDispatch, useSelector } from 'react-redux'


// Components
import Accordion from '../../../components/Accordion'
import AccordionDetailData from '../../../components/AccordionDetailData'
import Button from '../../../components/Button'
import FormInput from '../../../components/FormInput'
import Modal from '../../../components/Modal'
import SelectFilter from '../../../components/SelectFilter'
import Table from '../../../components/Table'
import { NoResults } from '../../../components/Tables/Misc/NoResults'
import TextareaInput from '../../../components/TextareaInput'
import CustomLoader from '../../../components/CustomLoader'
import { ModalAgendamiento } from '../../../components/Modal/Pedido/ModalAgendamientoNew'


// Layouts
import AccordionTable from '../../../layout/AccordionTable'


// Endpoints
import { getOne } from '../../../endpoints/getOne'
import { create } from '../../../endpoints/create'


// Helpers
import { todayFormat } from '../../../helpers/todayInputFormat'
import { getAll } from '../../../endpoints/getAll'
import { idExtractor } from '../../../helpers/idExtractor'
import Switch from '../../../components/Switch'
import { BASE_URL, PATH_URL } from '../../../endpoints'
import CheckBoxInput from '../../../components/CheckboxInput'
import { historicoData } from '../../../helpers/historicoData'
import { ModalHistorico } from '../../../components/Historico/ModalHistorico'
import TablaDetalle from '../../../components/Historico/TablaDetalle'
import CotizacionesFiltros from '../../../components/AccordionFilter/Cotizaciones'
import CotizacionesTable from '../../../components/Tables/CotizacionesTable'
import { renderRecargoInstalacion } from '../../../helpers/renderRecargoInstalacion'
import { PrecioFormatter } from '../../../helpers/precioFormatter'



const Precio = (v) => new PrecioFormatter(v).producto()
const PrecioRaw = (v) => new PrecioFormatter(v).producto(true)


const CONSTANT = {
	queryUrlGetOne: getOne.pedidos,
	updatePedido: (id) => BASE_URL + PATH_URL + `/pedidos/estatus/${id}`,
	nullObject: { id: '*NULL*', nombre: '- Error -' },
	unselectedObject: { id: '*NULL*', nombre: 'Sin selección' },
	title: 'Pedidos',
	redirectListado: '/pedidos',
	modelo: 'App\\Models\\NeuPedido',

	redirectNotaVenta: (id) => `/erp/notas-ventas/detalle/${id}`,
	redirectUpdate: (id) => `/pedidos/update/${id}`,

	fecthPuntoEntrega: getOne.agendamientos,
	fecthErpVendedores: getAll.erp_vendedores + '/obtener?vendedores_habilitados=1',
	fecthErpBodegas: getAll.erp_bodegas + '/obtener-vigentes',
	fetchStockProducto: getAll.erp_bodegas_stock_productos + '?bodegas_con_saldo=1',
	fecthMetodosPago: getAll.metodos_pago,
	fecthMaximiseData: BASE_URL + PATH_URL + '/erp/notas-ventas/lineas/listar',
	fetchCuentasCajaErp: BASE_URL + PATH_URL + `/erp/cuentas-cajas/obtener`,
	fetchTiposPagoErp: BASE_URL + PATH_URL + `/erp/tipos-pagos/obtener`,
	fetchFrontendId: getAll.frontend_id,
	fetchEstatusPedido: getAll.estatus_pedido,
	queryUrlFormasPago: getAll.formas_pago,

	createPagoErp: BASE_URL + PATH_URL + `/erp/pagos/crear`,
	urlAsignarCotizacion: BASE_URL + PATH_URL + `/pedidos/asignar-cotizacion`,
	urlCreateAgendamiento: create.agendamientos,
	urlCreatePago: create.pago_pedido,
	urlEstatusPago: BASE_URL + PATH_URL + `/pagos-pedido/estatus`,
	urlCreateNotaVenta: create.nota_venta,
	urlEditarPedido: BASE_URL + PATH_URL + `/pedidos`,
	urlCambiarEstatusPedido: (id) => BASE_URL + PATH_URL + `/pedidos/estatus/${id}`,

	aprobado: 'approved',

	permiso: {
		estatus_pago: 'pagos-pedidos.estatus-pago',
		estatus_pedido: 'pedidos.cambiar-estatus'
	},

	agendamientoDataInitialState: {
		pedido_id: '',
		hora_agenda_id: '',
		fecha: '',
		fecha_hasta: ''
	},

	cotizacionForm: {
		cotizacion_id: '',
	},

	pagoDataInitialState: {
		pedido_id: '',
		pago_id: null,
		ref_pago_erp: '',
		metodo_pago_id: '',
		codigo_autorizacion: '0',
		fecha_pago: todayFormat(),
		tipo_pago: '',
		cuenta_caja: '',
		monto_pago: '',
		nota_venta: '',
		comentarios: '',
		enviar_erp: false
	},

	editForm: {
		observaciones: {
			target: 'observaciones',
			state: false,
			form: {
				observaciones: ''
			}
		},
		nota_venta: {
			target: 'nota_venta',
			state: false,
			form: {
				nota_venta: ''
			}
		},
		frontend: {
			target: 'frontend_id',
			state: false,
			form: {
				frontend_id: ''
			}
		},
		estatus_pedido: {
			target: 'estatus_pedido',
			state: false,
			form: {
				estatus_pedido_id: ''
			}
		},
		direccion_entrega: {
			target: 'direccion_entrega',
			state: false,
			form: {
				direccion_entrega: ''
			}
		}
	},

	notaVentaSelectInitiaState: {
		bodega_reserva: [],
		vendedor: []
	},

	notaVentaDataInitialState: {
		pedido_id: '',
		vendedor: '',
		bodega_reserva: '',
		forma_pago_cliente: '',
		fecha_orden: '',
		orden_cliente: '',
		comentario: '',
		usar_despacho_express: false
	},

	metodosPagoSelectInitialState: [],

	selectFilterInitialState: {
		cuentasCajaErp: [],
		tiposPagoErp: [],
		formasPago: [],
		frontendId: [],
		estatusPedido: []
	}
}


const fetchSelectFiltersData = async () => {
	const frontendId = await axios(CONSTANT.fetchFrontendId,
		{
			headers: {
				'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
			},
		})
		.then(({ data }) => [CONSTANT.unselectedObject, ...data] ?? [CONSTANT.unselectedObject])
		.catch(err => {
			console.error(err)
			return [CONSTANT.nullObject]
		})

	const estatusPedido = await axios(CONSTANT.fetchEstatusPedido,
		{
			headers: {
				'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
			},
		})
		.then(({ data }) => [...data] ?? [CONSTANT.unselectedObject])
		.catch(err => {
			console.error(err)
			return [CONSTANT.nullObject]
		})

	const vendedor = await axios(CONSTANT.fecthErpVendedores,
		{
			headers: {
				'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
			},
		})
		.then(({ data }) => data?.data ?? [])
		.catch(err => {
			console.error(err)
			return [{ id: '*NULL*', name: 'Sin resultados' }]
		})

	const bodega_reserva = await axios(CONSTANT.fecthErpBodegas,
		{
			headers: {
				'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
			},
		})
		.then(({ data }) => [{ WARE_CODE: '*NULL*', DESC_TEXT: 'Sin selección' }, ...data?.data] ?? [])
		.catch(err => {
			console.error(err)
			return [{ WARE_CODE: '*NULL*', DESC_TEXT: 'Sin resultados' }]
		})

	const metodos_pago = await axios(CONSTANT.fecthMetodosPago,
		{
			headers: {
				'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
			},
		})
		.then(({ data }) => [{ id: '*NULL*', nombre: 'Sin selección' }, ...data] ?? [])
		.catch(err => {
			console.error(err)
			return [{ id: '*NULL*', nombre: 'Sin resultados' }]
		})

	const cuentasCajaErp = await axios(CONSTANT.fetchCuentasCajaErp,
		{
			headers: {
				'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
			},
		})
		.then(({ data }) => {
			return [CONSTANT.unselectedObject, ...data?.data.map(({ nombre, cod_erp }) => ({ id: cod_erp, nombre }))] ?? [CONSTANT.unselectedObject]
		})
		.catch(err => {
			console.error(err)
			return [CONSTANT.nullObject]
		})

	const tiposPagoErp = await axios(CONSTANT.fetchTiposPagoErp,
		{
			headers: {
				'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
			},
		})
		.then(({ data }) => [CONSTANT.unselectedObject, ...Object.entries(data).map(([k, v]) => ({ id: k, nombre: v }))] ?? [CONSTANT.unselectedObject])

		.catch(err => {
			console.error(err)
			return [CONSTANT.nullObject]
		})

	const formasPago = await axios(CONSTANT.queryUrlFormasPago,
		{
			headers: {
				'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
			},
		})
		.then(({ data }) => [CONSTANT.unselectedObject, ...data])
		.catch(err => {
			console.error(err)
			return [CONSTANT.nullObject]
		})

	return { vendedor, bodega_reserva, metodos_pago, cuentasCajaErp, tiposPagoErp, formasPago, frontendId, estatusPedido }
}


const Detalle = () => {
	const nv = useRef()
	const redirect = useNavigate()
	const [data, setData] = useState(null)
	const [productos, setProductos] = useState([])

	const [puntoEntregaData, setPuntoEntregaData] = useState(null)
	const [notaVentaData, setNotaVentaData] = useState(CONSTANT.notaVentaDataInitialState)
	const [agendamientoData, setAgendamientoData] = useState(CONSTANT.agendamientoDataInitialState)
	const [pagoData, setPagoData] = useState(CONSTANT.pagoDataInitialState)
	const [cotizacionForm, setCotizacionForm] = useState(CONSTANT.cotizacionForm)
	const [editForm, setEditForm] = useState(CONSTANT.editForm)
	const [notaVentaSelect, setNotaVentaSelect] = useState(CONSTANT.notaVentaSelectInitiaState)
	const [metodosPagoSelect, setMetodosPagoSelect] = useState(CONSTANT.metodosPagoSelectInitialState)
	const [pagoErpSelect, setPagoErpSelect] = useState(CONSTANT.selectFilterInitialState)
	const [modalDisplay, setModalDisplay] = useState(false)
	const [modalContent, setModalContent] = useState('')
	const [displayAllAccordion, setDisplayAllAccodion] = useState(true)

	const { id } = useParams()
	const dispatch = useDispatch()
	const [historico, setHistorico] = useState(null)
	const [modal, setModal] = useState({ id: null, display: false, data: null })
	const { user } = useSelector(state => state.auth)
	const [params] = useSearchParams()

	const [isConsulting, setIsConsulting] = useState(false)


	useEffect(() => {
		if (!pagoData?.codigo_respuesta) return setPagoData(pagoData => ({ ...pagoData, enviar_erp: false }))
	}, [pagoData?.codigo_respuesta])


	// TODO: SETEAR EL STOCK DEL ERP COMO EN EL LISTADO DE PRODUCTOS
	useEffect(() => {
		if (!data) return

		const codigos_productos = data.lineas_pedido.map(({ producto }) => String(producto?.sku) ?? null).filter(el => el !== null)

		axios(CONSTANT.fetchStockProducto,
			{
				headers: {
					'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
				}, method: 'POST', data: { codigos_productos }
			})
			.then(({ data: erp_data }) => {
				let newData = data.lineas_pedido

				Object.entries(erp_data).forEach(([k, v]) => {
					const current = newData.findIndex(({ producto }) => producto.sku === k)
					const newProducto = {
						...newData[current],
						producto: {
							...newData[current].producto,
							stock_erp: v
						}
					}

					newData = newData.map((el, i) => current === i ? newProducto : el)
				})

				setProductos(newData)
			})
			.catch(err => console.error(err))

	}, [data])


	// EFECTO QUE CONSULTA LA INFORMACIÓN DEL REGISTRO Y DE LOS SELECTS PARA NOTA VENTA
	useEffect(() => {
		axios(CONSTANT.queryUrlGetOne(id),
			{
				headers: {
					'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null,
					'Accept': 'application/json'
				}
			})
			.then(({ data }) => {

				setData(data)

				historicoData(Number(id), CONSTANT.modelo).then(data => setHistorico(data))

				setAgendamientoData({
					fecha_hasta: '',
					pedido_id: data?.id,
					hora_agenda_id: data?.agendamiento?.hora_agenda_id,
					fecha: data?.agendamiento?.fecha.split('T')[0]
				})
			})
			.catch(err => {
				console.error(err)

				if (err.response.status === 404) {
					const toasterContent = {
						title: 'Error 404',
						text: `Recurso no encontrado`,
						icon: 'warning'
					}

					dispatch(fireToaster(toasterContent))
				}

				redirect(CONSTANT.redirectListado)
			})

		return () => {
			setData(null)
			setNotaVentaData(CONSTANT.notaVentaDataInitialState)
			setAgendamientoData(CONSTANT.agendamientoDataInitialState)
			setPagoData(CONSTANT.pagoDataInitialState)
		}
	}, [id, dispatch, redirect])


	// EFECTO QUE SETEA LA DATA DE LOS SELECTFILTERS
	useEffect(() => {
		fetchSelectFiltersData()
			.then(({ vendedor, bodega_reserva, metodos_pago, cuentasCajaErp, tiposPagoErp, formasPago, frontendId, estatusPedido }) => {
				const defaultFormaPago = formasPago.find(({ id }) => id === data?.cotizacion?.forma_pago_id) ?? formasPago.find(({ cod_erp }) => cod_erp === 'CTD')

				setNotaVentaSelect({
					vendedor,
					bodega_reserva,
					formasPago
				})

				setNotaVentaData(notaVendaData => ({
					...notaVendaData,
					forma_pago_cliente: `${defaultFormaPago?.cod_erp ?? '*NULL*'}%-%${defaultFormaPago?.nombre ?? 'Sin selección'}`
				}))

				setMetodosPagoSelect(metodos_pago)
				setPagoErpSelect({ cuentasCajaErp, tiposPagoErp, frontendId, estatusPedido })
			})
	}, [data])


	// EFECTO QUE SETEA LA DATA DE UN PAGO NUEVO PT.1
	useEffect(() => {
		if (modalContent === 'pago-create') {
			if (!metodosPagoSelect.length) return
			const selectedMetodoPago = metodosPagoSelect.find(({ id }) => Number(id) === Number(data?.metodo_pago_id))

			setPagoData(pagoData => ({
				...CONSTANT.pagoDataInitialState,
				metodo_pago_id: `${selectedMetodoPago?.id}-${selectedMetodoPago?.nombre}`,
			}))
		}
	}, [modalContent, data?.metodo_pago_id, metodosPagoSelect])


	// EFECTO QUE SETEA LA DATA DE UN PAGO NUEVO PT.2
	useEffect(() => {
		if (!metodosPagoSelect.length || !pagoErpSelect.cuentasCajaErp.length || !pagoErpSelect.tiposPagoErp.length) return

		const selectedMetodoPago = metodosPagoSelect.find(({ id }) => Number(id) === Number(pagoData?.metodo_pago_id.split('-')[0]))
		const selectedCuentaCaja = pagoErpSelect.cuentasCajaErp.find(({ id }) => id === selectedMetodoPago?.cuenta_caja_erp)
		const selectedTipoPago = pagoErpSelect.tiposPagoErp.find(({ id }) => id === selectedMetodoPago?.tipo_pago_erp)

		setPagoData(pagoData => ({
			...pagoData,
			cuenta_caja: `${selectedCuentaCaja?.id ?? '*NULL*'}%-%${selectedCuentaCaja?.id ?? '*NULL*'} | ${selectedCuentaCaja?.nombre ?? 'Sin seleccion'}`,
			tipo_pago: `${selectedTipoPago?.id ?? '*NULL*'}%-%${selectedTipoPago?.nombre ?? 'Sin seleccion'}`
		}))
	}, [pagoData?.metodo_pago_id, metodosPagoSelect, pagoErpSelect])


	// EFECTO QUE SETEA EL VENDEDOR POR DEFECTO
	useEffect(() => {
		if (!data || !notaVentaSelect.vendedor.length) return

		const selectedVendedor = notaVentaSelect.vendedor.find(({ codigo_vendedor, vendedor }) => {
			return (data?.cotizacion?.ejecutivo?.cod_maxi)
				? String(codigo_vendedor) === String(data?.cotizacion?.ejecutivo?.cod_maxi)
				: String(vendedor).toLowerCase().includes('pagina web')
		})

		setNotaVentaData(notaVentaData => ({
			...notaVentaData,
			vendedor: `${selectedVendedor?.codigo_vendedor ?? '*NULL*'}-${selectedVendedor?.vendedor ?? 'Error'}`
		}))
	}, [data, notaVentaSelect?.vendedor])


	// EFECTO QUE DETECTA LOS SEARCH PARAMS
	useEffect(() => {
		for (const [k, v] of params) {
			if (k === 'ap' && v === `1`) handleModalContent('pago-create')
		}
	}, [params])


	const handleInputChange = (e) => {
		const { name, value } = e.target
		if (name.includes('editForm')) {
			const [_, targetForm, key] = name.split('%-%')

			setEditForm({
				...editForm,
				[targetForm]: {
					...editForm[targetForm],
					form: {
						...editForm[targetForm].form,
						[key]: value
					}
				}
			})
		}
	}


	// EFECTO QUE CONSULTA LA INFORMACIÓN DEL PUNTO DE ENTREGA (FECHAS - HORAS)
	// useEffect(() => {
	// 	if (!data?.puntoentrega_id) return

	// 	axios(CONSTANT.fecthPuntoEntrega(data?.puntoentrega_id) + '?' + queryParamsFormatter({ desde: currentMonthDate().first, hasta: currentMonthDate().last }),
	// 		{
	// 			headers: {
	// 				'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
	// 			},
	// 		})
	// 		.then(({ data }) => setPuntoEntregaData(data))
	// 		.catch(err => console.error(err))

	// 	return () => setPuntoEntregaData(null)
	// }, [data, dispatch, agendamientoData.fecha_hasta])


	// FUNCION MANEJADORA DE LA DATA PARA EL AGENDAMIENTO
	const handleAgendamiento = (e) => {
		const { name, value } = e.target
		const [fecha, hora_agenda_id] = value.split('+')

		if (name === 'fecha_hasta') {
			return setAgendamientoData({
				...agendamientoData,
				fecha_hasta: value
			})
		}

		setAgendamientoData({
			...agendamientoData,
			fecha,
			hora_agenda_id: Number(hora_agenda_id),
			pedido_id: Number(data?.id)
		})
	}


	// FUNCION MANEJADORA DEL FORMULARIO PARA CREAR UNA NOTA DE VENTA
	const handleNotaVentaForm = (e) => {
		const { name, value } = e.target

		setNotaVentaData({
			...notaVentaData,
			[name]: value === '*NULL*' ? null : value
		})
	}


	// FUNCION MANEJADORA DE LA DATA PARA CREAR UN PAGO
	const handlePago = (e) => {
		const { name, value } = e.target

		setPagoData({
			...pagoData,
			[name]: name === 'monto_pago' ? Number(value) : value,
			pedido_id: Number(data?.id),
		})
	}


	// FUNCION MANEJADORA PARA MANEJAR UN PAGO YA REGISTRADO
	const handleSetPago = (data) => {
		const selectedMetodoPago = metodosPagoSelect.find(({ id }) => id === data?.metodo_pago?.id)

		setPagoData({
			...pagoData,
			pago_id: data?.id,
			monto_pago: Number(data?.monto),
			fecha_pago: data?.fecha_pago?.split(' ')?.[0] ?? todayFormat(),
			codigo_respuesta: data?.codigo_respuesta === 'approved' ? true : false,
			codigo_autorizacion: data?.codigo_autorizacion,
			metodo_pago_id: `${selectedMetodoPago?.id}-${selectedMetodoPago?.nombre}`,
			enviar_erp: data?.ref_pago_erp ? false : true,
			ref_pago_erp: data?.ref_pago_erp
		})

		setModalContent('pago-update')
		setModalDisplay(true)
	}


	// FUNCION MANEJADORA DEL CONTENIDO DEL MODAL
	const handleModalContent = (target) => {
		setModalContent(target)
		setModalDisplay(true)
	}


	// FUNCIÓN MANEJADORA PARA ACTUALIZACIÓN DEL ESTATUS
	const handleFetch = async (objData, target) => {
		let config
		let url

		if (target === 'agendamiento') {
			if (!objData.fecha) return dispatch(fireToaster({ title: 'Por favor, seleccione una fecha', icon: 'info' }))
			if (!objData.hora_agenda_id) return dispatch(fireToaster({ title: 'Por favor, seleccione un intérvalo de la tabla', icon: 'info' }))

			url = data?.agendamiento?.id
				? CONSTANT.urlCreateAgendamiento + `/${data?.agendamiento?.id}`
				: CONSTANT.urlCreateAgendamiento
			config = {
				method: data?.agendamiento?.id ? 'PUT' : 'POST',
				data: {
					...objData,
					_method: data?.agendamiento?.id ? 'PUT' : 'POST'
				}
			}
		}

		if (target === CONSTANT.editForm.nota_venta.target) {
			if (data?.nota_venta) {
				url = CONSTANT.urlEditarPedido
				config = {
					method: 'PUT',
					data: { ...editForm[target].form, pedido_id: data?.id }
				}
			}

			else {
				url = CONSTANT.urlCreateNotaVenta
				config = {
					method: 'POST',
					data: (objData.bodega_reserva === null || objData.bodega_reserva.split('-')[0] === '*NULL*')

						? {
							pedido_id: data?.id,
							comentario: objData?.comentario,
							usar_despacho_express: objData?.usar_despacho_express ? true : false,
							vendedor: idExtractor(objData?.vendedor),
							fecha_orden: objData?.fecha_orden.length
								? moment(objData?.fecha_orden).format('YYYY-MM-DD')
								: '',
							orden_cliente: objData?.orden_cliente.length
								? objData?.orden_cliente
								: '',
							forma_pago_cliente: notaVentaData.forma_pago_cliente.includes('*NULL*')
								? null
								: idExtractor(objData?.forma_pago_cliente, true),
						}

						: {
							pedido_id: data?.id,
							usar_despacho_express: objData?.usar_despacho_express ? true : false,
							comentario: `Tipo de facturación: ${data.tipo_facturacion?.nombre}. ${objData?.comentario}`,
							vendedor: idExtractor(objData?.vendedor),
							fecha_orden: objData?.fecha_orden.length
								? moment(objData?.fecha_orden).format('YYYY-MM-DD')
								: '',
							orden_cliente: objData?.orden_cliente.length
								? objData?.orden_cliente
								: '',
							forma_pago_cliente: notaVentaData.forma_pago_cliente.includes('*NULL*')
								? null
								: idExtractor(objData?.forma_pago_cliente, true),
							bodega_reserva: idExtractor(objData.bodega_reserva)
						}
				}

				dispatch(fireToaster({ title: 'Generando nota de venta', text: 'Por favor, espere...', icon: 'info', timer: 50000 }))
			}
		}

		if (target === 'estatus') {
			let newEstatus = objData.estado_pago ? 0 : 1
			url = BASE_URL + PATH_URL + '/pedidos'
			config = {
				method: 'PUT',
				data: { estado_pago: newEstatus, _method: 'PUT', pedido_id: objData.id }
			}
		}

		if (target === 'pago') {
			setPagoData({ ...pagoData, monto_pago: Number(pagoData.monto_pago) })

			if (!pagoData.codigo_autorizacion.length) return dispatch(fireToaster({ title: 'Por favor, ingrese un código de autorización', icon: 'info' }))
			if (pagoData.metodo_pago_id.includes('*NULL*')) return dispatch(fireToaster({ title: 'Por favor, ingrese un método de pago', icon: 'info' }))
			if (typeof pagoData.monto_pago !== 'number') return dispatch(fireToaster({ title: 'Por favor, ingrese un monto válido', icon: 'info' }))

			if (pagoData.enviar_erp) {
				if (pagoData.tipo_pago.includes('*NULL*')) return dispatch(fireToaster({ title: 'Por favor, ingrese un tipo de pago', icon: 'info' }))
				if (pagoData.cuenta_caja.includes('*NULL*')) return dispatch(fireToaster({ title: 'Por favor, ingrese una cuenta de caja', icon: 'info' }))
			}

			url = CONSTANT.urlCreatePago

			config = {
				method: pagoData?.pago_id ? 'PUT' : 'POST',
				data: {
					...objData,
					monto: Number(pagoData.monto_pago),
					pago_pedido_id: Number(pagoData?.pago_id),
					metodo_pago_id: idExtractor(objData.metodo_pago_id),
					fecha_pago: `${pagoData?.fecha_pago?.split(' ')?.[0]} 00:00:00`,
					codigo_autorizacion: objData?.codigo_autorizacion,
					nota_venta: Number(data?.nota_venta),
					ref_pago_erp: String(pagoData?.ref_pago_erp ?? ''),
				}
			}
		}

		if (target === 'estatus-pago') {
			url = CONSTANT.urlEstatusPago + `/${objData.id}`

			config = {
				...config,
				method: 'PUT',
				data: {
					estatus: objData.codigo_respuesta === 'approved' ? false : true
				}
			}
		}

		if (target === 'pago-delete') {
			url = CONSTANT.urlCreatePago + `/${objData?.id}`

			config = {
				method: 'DELETE',
			}
		}

		if (target === 'cotizacion') {
			url = CONSTANT.urlAsignarCotizacion
			config = {
				method: 'PUT',
				data: {
					pedido_id: Number(id),
					cotizacion_id: cotizacionForm.cotizacion_id
				}
			}
		}

		if ([CONSTANT.editForm.observaciones.target, CONSTANT.editForm.direccion_entrega.target].includes(target)) {
			url = CONSTANT.urlEditarPedido
			config = {
				method: 'PUT',
				data: { ...editForm[target].form, pedido_id: data?.id }
			}
		}

		if ([CONSTANT.editForm.frontend.target].includes(target)) {
			url = CONSTANT.urlEditarPedido
			config = {
				method: 'PUT',
				data: { frontend_id: Number(idExtractor(editForm.frontend.form.frontend_id, true)), pedido_id: data?.id }
			}
		}

		if ([CONSTANT.editForm.estatus_pedido.target].includes(target)) {
			if (editForm.estatus_pedido.form.estatus_pedido_id?.includes('Anulado') && !window.confirm('¿Está seguro que desea anular el pedido?')) return

			url = CONSTANT.urlCambiarEstatusPedido(id)
			config = {
				method: 'PUT',
				data: { estatus_pedido_id: Number(idExtractor(editForm.estatus_pedido.form.estatus_pedido_id, true)) }
			}
		}

		config = {
			...config,
			headers: {
				...config?.headers,
				'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
			}
		}

		setIsConsulting(true)

		await axios(url, config)
			.then(async ({ data }) => {
				const toasterContent = {
					title: 'Operación realizada',
					text: `${CONSTANT.title}: registro actualizado con éxito`,
					icon: 'success'
				}
				dispatch(fireToaster(toasterContent))

				setData(null)
				setCotizacionForm(CONSTANT.cotizacionForm)
				setModalDisplay(false)

				if (target === 'pago' && user.permisos[CONSTANT.permiso.estatus_pago]) {
					await axios(CONSTANT.urlEstatusPago + `/${data.id}`,
						{
							headers: {
								'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
							},
							method: 'PUT',
							data: {
								estatus: true
							}
						})
						.then(res => {
							const toasterContent = {
								title: 'Operación realizada',
								text: `${CONSTANT.title}: pago actualizado con éxito`,
								icon: 'success'
							}
							dispatch(fireToaster(toasterContent))
						})
						.catch(err => {
							console.error(err);
							const { msj, error } = err.response.data
							let toasterContent = {
								title: `
								Operación fallida
								(${err.response.status} - ${err.response.statusText})
							`,
								html: `
								<b>Detalle: </b>
								<br />
								${msj}: ${error}
							`,
								icon: 'error'
							}

							dispatch(fireToaster(toasterContent))
						})
				}

				if (target === 'pago' && pagoData.enviar_erp && !pagoData?.ref_pago_erp) {
					if (pagoData.cuenta_caja.includes('*NULL*')) return dispatch(fireToaster({ title: 'Por favor, ingrese una cuenta caja', icon: 'info' }))
					if (pagoData.tipo_pago.includes('*NULL*')) return dispatch(fireToaster({ title: 'Por favor, ingrese una tipo de pago', icon: 'info' }))

					await axios(CONSTANT.createPagoErp,
						{
							headers: {
								'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
							},
							method: 'POST',
							data: {
								...config.data,
								cuenta_caja: idExtractor(objData.cuenta_caja, true),
								tipo_pago: idExtractor(objData.tipo_pago, true),
								fecha_pago: moment(pagoData?.fecha_pago).format('YYYY-MM-DD'),
								comentarios: pagoData?.comentarios,
								pago_id: data?.id,
							}
						})
						.then(res => {
							const toasterContent = {
								title: 'Operación realizada',
								text: `${CONSTANT.title}: ${target} pago en el ERP creado con éxito`,
								icon: 'success'
							}
							dispatch(fireToaster(toasterContent))

							setData(null)
							setModalDisplay(false)
						})
						.catch(err => {
							console.error(err);
							const { errores, msj, error } = err.response.data
							let toasterContent = {
								title: `
								Operación fallida
								(${err.response.status} - ${err.response.statusText})
							`,
								html: `
								<b>Detalle: </b>
								<br />
								${msj}: ${error}
							`,
								icon: 'error'
							}

							if (target !== 'nota_venta') {
								let detalles = []
								Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))
								toasterContent = {
									title: `
									Operación fallida
									(${err.response.status} - ${err.response.statusText})
								`,
									html: `
									<b>Detalle: </b>
									${detalles.map(error => `<br /><i>-${error}</i>`)}
								`,
									icon: 'error'
								}
							}

							dispatch(fireToaster(toasterContent))
						})
				}
			})
			.catch(err => {
				console.error(err);
				let detalles = []
				let toasterContent
				const { errores, error, codigo, msj, message } = err.response.data

				if (errores && typeof errores === 'object') {
					// Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error))) <- Formateo legacy de error
					Object.values(errores).forEach(error => detalles.push(error))

					toasterContent = {
						title: `
							Operación fallida
							(${err.response.status} - ${err.response.statusText})
						`,
						html: `
							<b>Detalle: </b>
							<br />
							${detalles.map(error => `<br /><i>-${error}</i>`)}
						`,
						icon: 'error'
					}
				}

				if (message) {
					toasterContent = {
						title: `
							Operación fallida
							(${err.response.status} - ${err.response.statusText})
						`,
						html: `
							<b>Detalle: </b>
							<br />
							${message}
						`,
						icon: 'error'
					}
				}

				if (typeof error === 'string') {
					toasterContent = {
						title: `
							Operación fallida
							(${codigo ?? 'NO_CODE'} - ${msj ?? message ?? 'NO_MSG'})
						`,
						html: `
							<b>Detalle: </b>
							<br />
							<i>-${error}</i>
						`,
						icon: 'error'
					}
				}
				console.log(toasterContent);
				dispatch(fireToaster(toasterContent))
			})
			.finally(async () => {
				setIsConsulting(false)

				await axios(CONSTANT.queryUrlGetOne(id),
					{
						headers: {
							'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
						},
					})
					.then(({ data }) => {
						if (target === 'nota_venta' && data?.nota_venta && config.method === 'POST') {
							redirect(CONSTANT.redirectNotaVenta(data?.nota_venta))
						}

						setData(data)
						setEditForm(CONSTANT.editForm)
						historicoData(Number(id), CONSTANT.modelo).then(data => setHistorico(data))

						setAgendamientoData({
							fecha_hasta: '',
							pedido_id: data?.id,
							hora_agenda_id: data?.agendamiento?.hora_agenda_id,
							fecha: data?.agendamiento?.fecha.split('T')[0]
						})
					})
					.catch(err => {
						console.error(err)

						if (err.response.status === 404) {
							const toasterContent = {
								title: 'Error 404',
								text: `Recurso no encontrado`,
								icon: 'warning'
							}

							dispatch(fireToaster(toasterContent))
						}

						redirect(CONSTANT.redirectListado)
					})
			})
	}


	// FUNCION MANEJADORA DE LA DATA DEL MODAL
	const handleModalHistorico = (data) => {
		const { id, anterior, nuevo } = data

		setModal({
			display: true,
			id,
			data: {
				anterior,
				nuevo
			}
		})
	}


	const handleEditForm = (target) => {
		if (target === CONSTANT.editForm.observaciones.target) {
			setEditForm({
				...editForm,
				observaciones: {
					...editForm.observaciones,
					state: !editForm.observaciones.state,
					form: {
						observaciones: data?.observaciones ?? ''
					}
				}
			})
		}

		if (target === CONSTANT.editForm.nota_venta.target) {
			setEditForm({
				...editForm,
				nota_venta: {
					...editForm.nota_venta,
					state: !editForm.nota_venta.state,
					form: {
						nota_venta: data?.nota_venta ?? ''
					}
				}
			})
		}

		if (target === CONSTANT.editForm.direccion_entrega.target) {
			setEditForm({
				...editForm,
				direccion_entrega: {
					...editForm.direccion_entrega,
					state: !editForm.direccion_entrega.state,
				}
			})
		}

		if (target === CONSTANT.editForm.frontend.target) {
			const selectedFrontend = pagoErpSelect?.frontendId.find(({ nombre }) => nombre === data?.frontend?.nombre)
			setEditForm({
				...editForm,
				frontend: {
					...editForm.frontend,
					state: !editForm.frontend.state,
					form: {
						frontend_id: `${selectedFrontend?.id ?? '1'}%-%${selectedFrontend?.nombre ?? 'chileneumaticos'}` ?? ''
					}
				}
			})
		}

		if (target === CONSTANT.editForm.estatus_pedido.target) {
			const selectedEstatus = pagoErpSelect?.estatusPedido.find(({ nombre }) => nombre === data?.estatus_pedido?.nombre)

			setEditForm({
				...editForm,
				estatus_pedido: {
					...editForm.estatus_pedido,
					state: !editForm.estatus_pedido.state,
					form: {
						estatus_pedido_id: `${selectedEstatus?.id ?? '1'}%-%${selectedEstatus?.nombre ?? 'chileneumaticos'}` ?? ''
					}
				}
			})
		}
	}


	const handleCotizacionForm = (e) => {
		const { name } = e.target
		const id = name.split('-')[1]

		setCotizacionForm({ cotizacion_id: Number(id) })
	}


	const handleFiltrosPersistentes = (location) => {
		return `?filtros_persistentes=${location?.search?.split('filtros_persistentes=')?.[1] ?? '0'}`
	}


	// FUNCION MANEJADORA QUE SCROLLEA HASTA EL ELEMENTO (element) SELECCIONADO
	const handleScrollTo = (element) => element.current.scrollIntoView()


	return (
		<>
			<ModalHistorico modal={modal} fnHandleModal={setModal} />

			{/* MODAL AGENDAMIENTO/PAGO */}
			<Modal display={modalDisplay} handleValue={() => setModalDisplay(false)} sizeDesk='col-md-12 h-100 px-1'>
				<>
					{/* AGENDAMIENTO */}
					{modalContent === 'agenda' &&
						<ModalAgendamiento
							data={data}
							id={id}
							agendamientoData={agendamientoData}
							handleAgendamiento={handleAgendamiento}
							puntoEntregaData={puntoEntregaData}
							handleFetch={handleFetch}
							isConsulting={isConsulting}
						/>
					}

					{/* PAGO */}
					{modalContent.includes('pago') && metodosPagoSelect.length && pagoData?.metodo_pago_id

						? <div className='m-2'>
							<h3 className='p-50 rounded-2 m-0'>{pagoData?.pago_id ? 'Editar' : 'Adjuntar'} pago</h3>

							<hr className='mt-0 mb-2' />

							<div className='col'>
								<div className='row'>
									<FormInput
										labelText='Código de autorización'
										placeholder='Código...'
										name='codigo_autorizacion'
										handleValue={handlePago}
										value={pagoData.codigo_autorizacion}
										size='col-12'
										sizeDesk='col-md-4'
									/>

									<FormInput
										labelText='Ref. ERP'
										placeholder='Referencia...'
										name='ref_pago_erp'
										handleValue={handlePago}
										value={pagoData?.ref_pago_erp}
										size='col-12'
										sizeDesk='col-md-4'
									/>

									<FormInput
										type='number'
										labelText='Monto'
										placeholder='Cantidad...'
										name='monto_pago'
										handleValue={handlePago}
										value={pagoData.monto_pago}
										size='col-12'
										sizeDesk='col-md-4'
									/>

									<SelectFilter
										labelText='Método de pago'
										name='metodo_pago_id'
										handleValue={handlePago}
										value={pagoData?.metodo_pago_id?.split('-')[1] ?? 'Seleccione'}
										optionObj={metodosPagoSelect?.map(({ id, nombre }) => ({ id, name: nombre }))}
										size='col-12'
										sizeDesk='col-md-4'
									/>

									<FormInput
										type='date'
										labelText='Fecha'
										name='fecha_pago'
										handleValue={handlePago}
										value={pagoData.fecha_pago}
										size='col-12'
										sizeDesk='col-md-4'
									/>

									{user.permisos[CONSTANT.permiso.estatus_pago] &&
										<>
											<div className='col-12'><hr className='mb-1 mt-0' /></div>

											<div className='col'>
												<CheckBoxInput
													isDisabled={!data?.nota_venta || pagoData?.ref_pago_erp}
													labelText="Pago ERP"
													name="enviar_erp"
													handleValue={handlePago}
													value={pagoData.enviar_erp}
												/>
											</div>

											<div className='d-flex flex-column'>
												{!data?.nota_venta && <span>No se puede realizar una pago ERP sin una nota de venta</span>}
												{pagoData?.ref_pago_erp && <span>Este pago ya ha sido registrado al ERP</span>}
											</div>

											<div className='col-12'><hr className='my-1' /></div>

											{pagoData.enviar_erp
												? <>
													<SelectFilter
														altSeparator
														labelText='* Tipo pago'
														name='tipo_pago'
														value={pagoData.tipo_pago?.split('%-%')[1] ?? 'Seleccione'}
														handleValue={handlePago}
														optionObj={pagoErpSelect.tiposPagoErp.map(({ id, nombre }) => ({ id: id ?? '*NULL*', name: nombre ?? 'Error' }))}
														size='col-12'
														sizeDesk='col-md-6'
													/>

													<SelectFilter
														altSeparator
														labelText='* Cuenta caja'
														name='cuenta_caja'
														value={pagoData.cuenta_caja?.split('%-%')[1] ?? 'Seleccione'}
														handleValue={handlePago}
														optionObj={pagoErpSelect.cuentasCajaErp.map(({ id, nombre }) => ({ id: id ?? '*NULL*', name: `${id} | ${nombre}` ?? 'Error' }))}
														size='col-12'
														sizeDesk='col-md-6'
													/>
												</>

												: <>
													<FormInput
														labelText='* Tipo pago'
														value={pagoData.tipo_pago?.split('%-%')[1] ?? 'Seleccione'}
														isDisabled
														name='fecha'
														size='col-12'
														sizeDesk='col-md-6'
													/>

													<FormInput
														labelText='* Cuenta caja'
														value={pagoData.cuenta_caja?.split('%-%')[1] ?? 'Seleccione'}
														isDisabled
														name='fecha'
														size='col-12'
														sizeDesk='col-md-6'
													/>
												</>
											}
										</>
									}

									<TextareaInput
										labelText='Comentarios'
										name='comentarios'
										value={pagoData.comentarios ?? ''}
										handleValue={handlePago}
										rows={3}
										size='col-12'
										disabled={!pagoData.enviar_erp}
									/>

									<div className='col-12 mt-2'>
										<Button
											isDisabled={isConsulting}
											text={pagoData?.pago_id ? 'Actualizar' : 'Adjuntar pago'}
											className='mx-auto'
											onClick={() => handleFetch(pagoData, 'pago')}
										/>
									</div>
								</div>
							</div>
						</div>

						: <CustomLoader />
					}

					{/* ASIGNAR COTIZACION */}
					{modalContent === 'cotizacion' &&
						<div>
							<h3 className='p-50 rounded-2 m-0 position-sticky'>Asignar cotizacion a pedido #{id}</h3>
							<h3 className='p-50 rounded-2 m-0 position-sticky'>Cotizacion: #{cotizacionForm.cotizacion_id ?? 'Sin selección'}</h3>
							<div className='d-flex col-12 col-md-2 mt-50'>
								<Button
									isDisabled={isConsulting}
									text='Asignar'
									className='m-auto mb-25'
									onClick={() => handleFetch({}, 'cotizacion')}
								/>
							</div>
							<hr className='mt-0 mb-2' />

							<div className='custom-scrollbar h-100' style={{ maxHeight: '500px', overflow: 'auto' }}>
								<CotizacionesFiltros />

								<CotizacionesTable handleValue={handleCotizacionForm} selected={cotizacionForm.cotizacion_id} />
							</div>

						</div>
					}
				</>
			</Modal>

			<div className="card-body p-25 bg-white mb-50 rounded-2 border">
				<div className='d-flex gap-1'>
					<div className='col'>
						<Button text={CONSTANT.title} icon='ArrowLeft' isLink url={CONSTANT.redirectListado + handleFiltrosPersistentes(window.location)} />
					</div>
				</div>

				<div className="d-flex justify-content-between flex-column flex-md-row mt-0 p-25 align-items-center">
					<b className="mb-0 px-1 fs-3">Pedido
						<span className="mx-50 mb-0">#{id}</span>
					</b>

					<div className="d-flex flex-column flex-md-row gap-1">
						{data?.cotizacion_id &&
							<Button newTab icon='ArrowRight' text='Ir a la cotización' color={'warning'} isLink url={`/cotizaciones/detalle/${data?.cotizacion_id}`} />
						}

						<Button icon='Plus' text={data?.cotizacion_id ? 'Reasignar cotización' : 'Asignar cotización'} onClick={() => handleModalContent('cotizacion')} />

						<Button icon='Settings' text='Editar pedido' color={'warning'} isLink url={`/pedidos/update/${id}` + handleFiltrosPersistentes(window.location)} />

						{user.permisos[`devoluciones.crear`] &&
							<Button icon='FileMinus' text='Generar devolución' color={'danger'} isLink url={`/devoluciones/create?pedido_id=${id}`} />
						}

						{data?.nota_venta &&
							<Button newTab icon='ArrowRight' text='Ir a la nota de venta' color={'success'} isLink url={`/erp/notas-ventas/detalle/${data?.nota_venta}`} />
						}

						<div className="d-flex justify-content-between flex-md-row mt-0 p-25 align-items-center">
							<div className="d-flex gap-1">
								<Button icon={`${displayAllAccordion ? 'Minimize2' : 'Maximize2'}`} className='p-50' onClick={() => setDisplayAllAccodion(!displayAllAccordion)} />
							</div>
						</div>
					</div>

				</div>
			</div>

			{data && metodosPagoSelect.length
				? <div className='row'>

					{/* COLUMNA IZQUIERDA */}
					<div className='col-12 col-md-6'>

						{/* DATOS GENERALES */}
						<div className='col mb-2'>
							<Accordion
								title={<h3 className='m-0 w-100 text-center text-white'>Datos generales</h3>}
								isOpen={displayAllAccordion}
							>

								<hr className='my-50 mx-50' />

								<div className='col px-2 align-items-center'>
									<div className='row'>
										<AccordionDetailData dataKey={'Cotización'} value={
											data?.cotizacion_id
												? <a href={`/cotizaciones/detalle/${data?.cotizacion_id}`} target='_blank' rel='noreferrer'>{data?.cotizacion_id}</a>
												: 'N/A'
										}
										/>

										<AccordionDetailData dataKey={'Estatus pago'} value={
											user.permisos['pedidos.editar']
												? <Switch
													value={data?.estado_pago}
													labelText={data?.estado_pago ? 'Activo' : 'Inactivo'}
													margin='my-0'
													handleValue={() => handleFetch(data, 'estatus')}
												/>
												: <span className={`tag fw-bold px-50 ${data?.estado_pago ? 'success' : 'red'}`}>{data?.estado_pago ? 'Activo' : 'Inactivo'}</span>
										}
										/>

										<AccordionDetailData dataKey={'Método pago'} value={data?.metodo_pago?.nombre ?? 'N/A'} />

										<AccordionDetailData dataKey={'Frontend'} value={
											editForm.frontend.state

												?
												<div className='d-flex justify-content-between gap-50'>
													<SelectFilter
														altSeparator
														labelText=''
														name='editForm%-%frontend%-%frontend_id'
														value={editForm.frontend.form.frontend_id?.split('%-%')[1] ?? 'Seleccione'}
														handleValue={handleInputChange}
														optionObj={pagoErpSelect.frontendId.map(({ id, nombre }) => ({ id: id ?? '*NULL*', name: nombre ?? 'Error' }))}
														size='col-12'
														sizeDesk='col-md-12'
													/>

													<div className='d-flex flex-column gap-25'>
														<Button
															icon={'Check'}
															color={'success'}
															text={''}
															className='p-25'
															onClick={() => handleFetch({}, CONSTANT.editForm.frontend.target)}
														/>

														<Button
															icon={'X'}
															color={'danger'}
															text={''}
															className='p-25'
															onClick={() => handleEditForm(CONSTANT.editForm.frontend.target)}
														/>
													</div>
												</div>

												:
												<div className='d-flex justify-content-between gap-1'>
													<span className='my-auto'>{data?.frontend?.nombre ?? 'chileneumaticos'}</span>

													<Button
														icon={'Settings'}
														color={'warning'}
														text={''}
														className='p-25'
														onClick={() => handleEditForm(CONSTANT.editForm.frontend.target)}
													/>
												</div>
										} />

										<AccordionDetailData dataKey={'Número seguimiento'} value={data?.numero_seguimiento ?? 'N/A'} />
										<AccordionDetailData dataKey={'Tipo de facturación'} value={data?.tipo_facturacion?.nombre ?? 'N/A'} />
										<AccordionDetailData dataKey={'Estatus pedido'} value={
											editForm.estatus_pedido.state

												?
												<div className='d-flex justify-content-between gap-50'>
													<SelectFilter
														altSeparator
														labelText=''
														name='editForm%-%estatus_pedido%-%estatus_pedido_id'
														value={editForm.estatus_pedido.form.estatus_pedido_id?.split('%-%')[1] ?? 'Seleccione'}
														handleValue={handleInputChange}
														optionObj={pagoErpSelect.estatusPedido.map(({ id, nombre }) => ({ id: id ?? '*NULL*', name: nombre ?? 'Error' }))}
														size='col-12'
														sizeDesk='col-md-12'
													/>

													<div className='d-flex flex-column gap-25'>
														<Button
															icon={'Check'}
															color={'success'}
															text={''}
															className='p-25'
															onClick={() => handleFetch({}, CONSTANT.editForm.estatus_pedido.target)}
														/>

														<Button
															icon={'X'}
															color={'danger'}
															text={''}
															className='p-25'
															onClick={() => handleEditForm(CONSTANT.editForm.estatus_pedido.target)}
														/>
													</div>
												</div>

												:
												<div className='d-flex justify-content-between gap-1'>
													<span className='my-auto'>{data?.estatus_pedido?.nombre ?? 'chileneumaticos'}</span>

													{!user.permiso?.[CONSTANT.permiso.estatus_pedido] &&
														<Button
															icon={'Settings'}
															color={'warning'}
															text={''}
															className='p-25'
															onClick={() => handleEditForm(CONSTANT.editForm.estatus_pedido.target)}
														/>
													}
												</div>
										} />
										<AccordionDetailData dataKey={'Estado pago'} value={data?.estado_pago ? 'Pagado' : 'Pendiente'} />
										<AccordionDetailData dataKey={'Orden a proveedor'} value={data?.orden_a_proveedor ? 'Sí' : 'No'} />
										<AccordionDetailData dataKey={'Total'} value={Precio(data?.monto_total_pedido)} />
										<AccordionDetailData dataKey={'Subtotal'} value={Precio(data?.monto_subtotal)} />

										<AccordionDetailData dataKey={'Descuento código'}
											value={
												data?.pedido_codigo_descuento
													? <div className='d-flex justify-content-center gap-1'>
														<p className='m-0'>{Precio(data?.monto_descuento)}</p> <p className='sub-text-2 m-0'>
															<a href={`/codigos-descuento/detalle/${data?.pedido_codigo_descuento?.codigo_descuento_id}`} rel='noreferrer' target='_blank'>
																Ver
															</a>
														</p>
													</div>

													: <>N/A</>
											}
										/>

										<AccordionDetailData dataKey={'Costo envío'} value={PrecioRaw(data?.costo_envio)} />
										<AccordionDetailData dataKey={'Costo instalación'} value={PrecioRaw(data?.costo_instalacion)} />

										<AccordionDetailData dataKey={'Fecha / Hora intérvalo'}
											value={
												data?.agendamiento

													? <div onClick={() => handleModalContent('agenda')} className='d-flex flex-column tag dark-blue px-1 py-50 cursor-pointer'>
														<b className='m-auto'>{data?.agendamiento?.fecha.split('T')[0] ?? '?'}</b>
														<b className='m-auto'>{data?.agendamiento?.hora_agenda?.hora_inicio_intervalo} - {data?.agendamiento?.hora_agenda?.hora_final_intervalo}</b>
													</div>

													: <span className='tag px-50 cursor-pointer' onClick={() => handleModalContent('agenda')}>Agendar pedido</span>
											}
										/>

										<AccordionDetailData
											dataKey={'Nota de venta'}
											value={
												data?.nota_venta

													? editForm.nota_venta.state

														?
														<div className='d-flex justify-content-between gap-50'>
															<FormInput
																labelText=''
																name='editForm%-%nota_venta%-%nota_venta'
																value={editForm.nota_venta.form.nota_venta}
																handleValue={handleInputChange}
															/>

															<div className='d-flex flex-column gap-25'>
																<Button
																	icon={'Check'}
																	color={'success'}
																	text={''}
																	className='p-25'
																	onClick={() => handleFetch({}, CONSTANT.editForm.nota_venta.target)}
																/>

																<Button
																	icon={'X'}
																	color={'danger'}
																	text={''}
																	className='p-25'
																	onClick={() => handleEditForm(CONSTANT.editForm.nota_venta.target)}
																/>
															</div>
														</div>

														:
														<div className='d-flex justify-content-between gap-1'>
															<span className='my-auto'>{data?.nota_venta}</span>

															<Button
																icon={'Settings'}
																color={'warning'}
																text={''}
																className='p-25'
																onClick={() => handleEditForm(CONSTANT.editForm.nota_venta.target)}
															/>
														</div>

													:
													<Button
														isDisabled={isConsulting}
														onClick={() => handleScrollTo(nv)}
														text='Generar nota de venta'
														className='p-25 mx-auto w-fit'
													/>
											} last />
									</div>
								</div>
							</Accordion>
						</div>

						{/* OBSERVACIONES */}
						<div className='col mb-2'>
							<Accordion
								title={<h3 className='m-0 w-100 text-center text-white'>Observaciones</h3>}
								isOpen={displayAllAccordion}
							>
								<div className='d-flex justify-content-end gap-1'>
									{editForm.observaciones.state &&
										<Button
											icon={'Check'}
											color={'success'}
											text={'Guardar'}
											className='p-25'
											onClick={() => handleFetch({}, CONSTANT.editForm.observaciones.target)}
										/>
									}

									<Button
										icon={editForm.observaciones.state ? 'X' : 'Wrench'}
										color={editForm.observaciones.state ? 'danger' : 'warning'}
										text={editForm.observaciones.state ? 'Cancelar' : 'Editar'}
										className='p-25'
										onClick={() => handleEditForm(CONSTANT.editForm.observaciones.target)}
									/>
								</div>

								<hr className='my-50 mx-50' />

								{editForm.observaciones.state
									?
									<TextareaInput
										labelText=''
										name='editForm%-%observaciones%-%observaciones'
										placeholder='Escriba sus observaciones'
										value={editForm?.observaciones?.form?.observaciones}
										handleValue={handleInputChange}
									/>

									:
									<p className='m-0 px-1'>{data?.observaciones ?? 'Sin observaciones'}</p>
								}

							</Accordion>
						</div>

					</div>

					{/* COLUMNA DERECHA */}
					<div className='col-12 col-md-6'>

						{/* PUNTO ENTREGA */}
						<div className='col mb-2'>
							<Accordion
								isOpen={displayAllAccordion}
								title={<h3 className='m-0 w-100 text-center text-white'>Punto de entrega</h3>}
							>
								<div className='col px-2 align-items-center'>
									<div className='row'>
										<AccordionDetailData dataKey={'Dirección de entrega'} value={
											data?.direccion_entrega

												? <a href={`/puntos-entrega/detalle/${data?.puntoentrega_id}`} rel='noreferrer' target='_blank'>{data?.direccion_entrega}</a>

												: editForm.direccion_entrega.state

													? <div className='d-flex justify-content-between gap-50'>
														<FormInput
															labelText=''
															name='editForm%-%direccion_entrega%-%direccion_entrega'
															value={editForm.direccion_entrega.form.direccion_entrega}
															handleValue={handleInputChange}
														/>

														<div className='d-flex flex-column gap-25'>
															<Button
																icon={'Check'}
																color={'success'}
																text={''}
																className='p-25'
																onClick={() => handleFetch({}, CONSTANT.editForm.direccion_entrega.target)}
															/>

															<Button
																icon={'X'}
																color={'danger'}
																text={''}
																className='p-25'
																onClick={() => handleEditForm(CONSTANT.editForm.direccion_entrega.target)}
															/>
														</div>
													</div>

													: <div className='d-flex justify-content-between gap-1'>
														<span className='my-auto'>Sin dirección</span>

														<Button
															icon={'Settings'}
															color={'warning'}
															text={''}
															className='p-25'
															onClick={() => handleEditForm(CONSTANT.editForm.direccion_entrega.target)}
														/>
													</div>
										} />
										<AccordionDetailData dataKey={'Descripción'} value={data?.punto_entrega?.descripcion ?? 'N/A'} />
										<AccordionDetailData dataKey={'Método entrega'} value={data?.punto_entrega?.metodo_entrega?.nombre ?? 'N/A'} />
										<AccordionDetailData dataKey={'Tipo destino'} value={data?.punto_entrega?.tipo_destino?.nombre ?? 'N/A'} />
										<AccordionDetailData dataKey={'RUT'} value={data?.punto_entrega?.rut_proveedor ?? 'N/A'} last />
									</div>
								</div>
							</Accordion>
						</div>

						{/* CLIENTE */}
						<div className='col mb-2'>
							<Accordion
								isOpen={displayAllAccordion}
								title={<h3 className='m-0 w-100 text-center text-white'>Cliente</h3>}
							>
								<div className='col px-2 align-items-center'>
									<div className='row'>
										<AccordionDetailData dataKey={'Nombre y apellido'} value={data?.usuario?.nombre ?? '?'} />
										<AccordionDetailData dataKey={'Teléfono'} value={data?.usuario?.fono ?? 'N/A'} />
										<AccordionDetailData dataKey={'Email'} value={
											<span style={{ wordBreak: 'break-word' }}>{data?.usuario?.email ?? 'N/A'}</span>
										} />
										<AccordionDetailData dataKey={'RUT'} value={data?.usuario?.rut ?? 'N/A'} last />
									</div>
								</div>
							</Accordion>
						</div>

						{/* DATOS FACTURA */}
						<div className='col mb-2'>
							<Accordion
								isOpen={displayAllAccordion}
								title={<h3 className='m-0 w-100 text-center text-white'>Datos factura</h3>}
							>
								<div className='col px-2 align-items-center'>
									<div className='row'>
										<AccordionDetailData dataKey={'Comuna/Región'} value={`${data?.datos_facturacion?.comuna?.nombre ?? 'N/A'} / ${data?.datos_facturacion?.comuna?.region?.nombre ?? 'N/A'}`} />
										<AccordionDetailData dataKey={'RUT empresa'} value={data?.datos_facturacion?.empresa_rut ?? 'N/A'} />
										<AccordionDetailData dataKey={'Razón social'} value={data?.datos_facturacion?.empresa_razon_social ?? 'N/A'} />
										<AccordionDetailData dataKey={'Giro'} value={data?.datos_facturacion?.empresa_giro ?? 'N/A'} />
										<AccordionDetailData dataKey={'Dirección'} value={data?.datos_facturacion?.empresa_direccion ?? 'N/A'} last />
									</div>
								</div>
							</Accordion>
						</div>
					</div>

					{/* PAGOS REALIZADOS */}
					<AccordionTable
						classes='mb-2'
						isOpen={displayAllAccordion}
						title='Pagos realizados'
					>
						<Table pb={false} style={{ maxHeight: '900px' }}>
							<thead className="table-light">
								<tr role="row">
									<th>ID</th>
									<th>Método pago</th>
									<th>Datos</th>
									<th className='text-center'>Respuesta</th>
									<th>Monto</th>
									<th>Fecha</th>
									<th>
										<Button isDisabled={isConsulting} icon='Plus' color='success' text='Adjuntar' onClick={() => handleModalContent('pago-create')} className='p-25' />
									</th>
								</tr>
							</thead>
							<tbody>
								{data?.pagos_pedido?.length

									? data?.pagos_pedido?.map(({ metodo_pago, codigo_autorizacion, monto, numero_final_tarjeta, codigo_respuesta, fecha_pago, ref_pago_erp, id }, i, arr) => (
										<tr key={`pagos-realizados-${id}-${metodo_pago?.nombre}`}>
											<td>{id}</td>

											<td>{metodo_pago?.nombre ?? 'N/A'}</td>

											<td>
												<div>
													<p className='p-0 m-0'>Cód. Autorización: <b>{codigo_autorizacion ?? 'N/A'}</b></p>
													<p className='p-0 m-0'>Ref. ERP: <b>{ref_pago_erp ?? 'N/A'}</b></p>
													<p className='p-0 m-0'>Núm. tarjeta: <b>{numero_final_tarjeta ?? 'N/A'}</b></p>
												</div>
											</td>

											<td>
												{user.permisos[CONSTANT.permiso.estatus_pago]
													? <Switch
														labelText={codigo_respuesta === CONSTANT.aprobado ? "Aprobado" : 'Rechazado'}
														name="codigo_respuesta"
														handleValue={() => handleFetch({ id, codigo_respuesta }, 'estatus-pago')}
														value={codigo_respuesta === CONSTANT.aprobado}
														size="col-12"
														classes='flex-column align-items-center fw-bolder'
													/>

													: <p className={`tag mx-auto px-50 ${codigo_respuesta === CONSTANT.aprobado ? 'success' : 'red'}`}>{codigo_respuesta === CONSTANT.aprobado ? 'Aprobado' : 'Rechazado'}</p>
												}
											</td>

											<td className='text-nowrap fw-bolder'>{`${Precio(monto) ?? 'N/A'}`}</td>

											<td className='text-nowrap'>{fecha_pago?.split(' ')?.[0] ?? 'N/A'}</td>

											<td>
												<div className='d-flex flex-column gap-50'>
													<Button className='p-25 w-fit' icon='Settings' text={!ref_pago_erp ? 'Editar | ERP' : 'Editar'} onClick={() => handleSetPago(arr[i])} />

													<Button className='p-25 w-fit' color='danger' icon='X' text='Eliminar' onClick={() => handleFetch(arr[i], 'pago-delete')} />
												</div>
												{/* <DropdownWrapper classes="mx-auto w-fit" icon='MoreHorizontal'>
												</DropdownWrapper> */}
											</td>

										</tr>
									))

									: <tr><td colSpan={9}><NoResults /></td></tr>}
							</tbody>
						</Table>
					</AccordionTable>

					{/* PRODUCTOS */}
					<AccordionTable
						classes='mb-2'
						isOpen={displayAllAccordion}
						title='Productos'
					>
						<Table pb={false} style={{ maxHeight: '900px' }}>
							<thead className="table-light">
								<tr role="row">
									<th>Descripción</th>
									<th>SKU</th>
									<th>Qty</th>
									<th>Stock página</th>
									<th>Precio unit.</th>
									<th>Subtotal</th>
								</tr>
							</thead>
							<tbody>
								{productos?.length

									? productos?.map(({ id, cantidad_comprada, producto, precio_unidad, subtotal_neto, precio_instalacion, recargo, valor_impuesto, subtotal }) => (
										<React.Fragment key={`tr-${id}`}>
											<tr key={`pagos-realizados-${id}-${producto?.sku}`}>
												<td>
													<a href={`/productos/detalle/${producto?.id}`} target='_blank' rel='noreferrer'>
														<b>{producto?.nombre?.toUpperCase() ?? '?'}</b>
													</a>
												</td>
												<td>{producto?.sku ?? '?'}</td>
												<td>{cantidad_comprada?.split('.')[0] ?? '?'}</td>
												<td>{producto?.stock_propio?.stock ?? '?'}</td>

												<td>
													<div className="d-flex flex-column w-max">
														<span className="fw-bolder fs-4 text-black">{Precio(precio_unidad)}</span>
														<span className="text-black">Neto: {Precio(precio_unidad / ((100 + valor_impuesto) / 100))}</span>
														<span className='w-max'>Instalación: {renderRecargoInstalacion(precio_instalacion)}</span>
														<span className='w-max'>Recargo: {renderRecargoInstalacion(recargo)}</span>
													</div>
												</td>

												<td>
													<div className="d-flex flex-column w-max">
														<span className="fw-bolder fs-4 text-black">{Precio(subtotal)}</span>
														<span className="text-black">Neto: {Precio(subtotal_neto)}</span>
														<span className='w-max'>Instalación: {precio_instalacion === null
															? renderRecargoInstalacion(precio_instalacion)
															: renderRecargoInstalacion(precio_instalacion * cantidad_comprada?.split('.')[0])
														}
														</span>
														<span className='w-max'>Recargo: {recargo === null
															? renderRecargoInstalacion(recargo)
															: renderRecargoInstalacion(recargo * cantidad_comprada?.split('.')[0])
														}</span>
													</div>
												</td>

											</tr>

											<tr style={{ borderBottom: '1px solid #041c62' }} key={`tr2-producto-${data?.id}-${data?.nombre}`}>
												<td colSpan={8} className="pt-0 px-50">
													<AccordionTable
														title={`Stock disponible`}
														isOpen={data.filtro_stock ? true : false}
													>
														<Table pb={false} style={{ maxHeight: '900px' }}>
															<thead className='table-light position-sticky top-0 w-100 z-100 shadow'>
																<tr key={`tr3-producto-${data?.id}-${data?.nombre}`}>
																	<th>Bodega</th>
																	<th>Disponible</th>
																	<th>Física</th>
																	<th>Reservado</th>
																	<th>Facturado sin despachar</th>
																	<th>por llegar</th>
																</tr>
															</thead>
															<tbody>
																{producto?.stock_erp?.length
																	? producto.stock_erp.map(({ bodega, stock_disponible, stock_fisico, stock_facturado_sin_despachar, stock_por_llegar, stock_reservado }) => (
																		<tr key={`erp-${data?.id}-${bodega}`}>
																			<td>
																				<b>{bodega}</b>
																			</td>
																			<td>
																				<b>{stock_disponible}</b>
																			</td>
																			<td>
																				<b>{stock_fisico}</b>
																			</td>
																			<td>
																				<b>{stock_reservado}</b>
																			</td>
																			<td>
																				<b>{stock_facturado_sin_despachar}</b>
																			</td>
																			<td>
																				<b>{stock_por_llegar}</b>
																			</td>
																		</tr>
																	))
																	: <tr><td colSpan={6}>No hay disponibilidad</td></tr>
																}
															</tbody>
														</Table>
													</AccordionTable>
												</td>
											</tr>
										</React.Fragment>
									))

									: <tr><td colSpan={6}><NoResults /></td></tr>
								}
							</tbody>
						</Table>
					</AccordionTable>

					{/* GENERAR NOTA VENTA */}
					{!data?.nota_venta &&
						<Accordion
							classes='mb-2'
							isOpen={displayAllAccordion}
							title='Generar nota de venta'
						>
							{notaVentaSelect.bodega_reserva?.length && notaVentaSelect.vendedor?.length && notaVentaSelect.formasPago?.length

								? <div className='card-body' ref={nv}>
									<div className='row'>
										<SelectFilter
											name='bodega_reserva'
											value={notaVentaData?.bodega_reserva?.split('-')[1] ?? 'Seleccione'}
											optionObj={notaVentaSelect.bodega_reserva.map(({ WARE_CODE, DESC_TEXT }) => ({ id: WARE_CODE, name: `${WARE_CODE ?? 'N/A'} | ${DESC_TEXT ?? '?'}` }))}
											handleValue={handleNotaVentaForm}
											labelText='Bodega reserva'
											size='col-12'
											sizeDesk='col-md-3'
										/>

										<SelectFilter
											name='vendedor'
											value={notaVentaData?.vendedor?.split('-')[1] ?? 'Seleccione'}
											optionObj={notaVentaSelect.vendedor.map(({ vendedor, codigo_vendedor }) => ({ id: codigo_vendedor ?? '*NULL*', name: vendedor ?? 'N/A' }))}
											handleValue={handleNotaVentaForm}
											labelText='Vendedor'
											size='col-12'
											sizeDesk='col-md-3'
										/>

										<SelectFilter
											name='forma_pago_cliente'
											altSeparator
											value={notaVentaData?.forma_pago_cliente?.split('%-%')[1] ?? 'Seleccione'}
											optionObj={notaVentaSelect.formasPago.map(({ nombre, cod_erp, id }) => ({ id: cod_erp ?? id ?? '*NULL*', name: nombre ?? 'N/A' }))}
											handleValue={handleNotaVentaForm}
											labelText='Forma de pago'
											size='col-12'
											sizeDesk='col-md-3'
										/>

										<FormInput
											labelText='Orden cliente'
											name='orden_cliente'
											value={notaVentaData?.orden_cliente}
											handleValue={handleNotaVentaForm}
											size='col-12'
											sizeDesk='col-md-3'
										/>

										<FormInput
											type='date'
											labelText='Fecha orden'
											name='fecha_orden'
											value={notaVentaData?.fecha_orden}
											handleValue={handleNotaVentaForm}
											size='col-12'
											sizeDesk='col-md-3'
										/>

										<TextareaInput
											name='comentario'
											labelText='Comentario'
											value={notaVentaData.comentario}
											handleValue={handleNotaVentaForm}
											rows={2}
											size='col-12'
											sizeDesk='col-md-6'
										/>

										<CheckBoxInput
											labelText='Usar Despacho Express'
											name='usar_despacho_express'
											value={notaVentaData?.usar_despacho_express}
											handleValue={handleNotaVentaForm}
											classes={'col-6 col-md-3 mt-1'}
										/>

										{/* Espaciado */}
										<div className='col-md-5'></div>

										<div className='col'>
											<Button
												isDisabled={isConsulting}
												text='Generar nota de venta'
												onClick={() => handleFetch(notaVentaData, 'nota_venta')}
											/>
										</div>
									</div>
								</div>

								: <CustomLoader />
							}
						</Accordion>
					}

					<TablaDetalle historicoData={historico} fnHandleModal={handleModalHistorico} />

				</div >

				: <CustomLoader />
			}
		</>
	)
}

export default Detalle