import { Navigate, Route, Routes } from "react-router-dom"
import { Listado } from "views/Logistica/Replanificacion/Listado";
import Form from "views/Logistica/Replanificacion/Form";


const ReplanificacionRouter = () => {
   return (
      <Routes>
         <Route path='/' element={<Listado />} />

         <Route path='/create' element={<Form />} />

         <Route path='/update/:id' element={<Form />} />

         <Route path='*' element={<Navigate to='/home' replace />} />
      </Routes>
   );
}

export default ReplanificacionRouter;