import {
   FILTER_CLEAR,
   FILTER_REPLANIFICACION_INPUT,
   RESULTADOS_LOGISTICA_REPLANIFICACION,
} from "../redux-types";

const initialState = {
   filtros: {
      fecha_desde: '',
      fecha_hasta: '',
      es_planificado: '',
   },
   resultados: null
}


export const logisticaReplanificacionReducer = (state = initialState, action) => {
   switch (action.type) {
      case FILTER_REPLANIFICACION_INPUT:
         return {
            ...state, filtros: {
               ...state.filtros,
               ...action.payload
            }
         };

      case RESULTADOS_LOGISTICA_REPLANIFICACION:
         return {
            ...state, resultados: action.payload
         };

      case FILTER_CLEAR:
         return {
            ...state,
            filtros: initialState.filtros
         };

      default:
         return state;
   }
};