import { useState } from "react";


// Redux
import { fireToaster } from "redux/actions/toaster";
import { useDispatch, useSelector } from "react-redux";


// React-Router
import { LogisticaLineasDespachoRouter } from "routers/Logistica/LineasDespachoRouter";


// Components
import Tabs from "components/Tabs";
import { LogisticaLineasDespachoFiltros } from "components/AccordionFilter/LogisticaLineasDespacho";
import Button from "components/Button";
import { ModalReplanificacion } from "components/Modal/Logisitica/ModalReplanificacion";
import { ModalExcepciones } from "components/Modal/Logisitica/ModalExcepciones";
import CustomLoader from "components/CustomLoader";
import { filtrosFormatter } from 'components/AccordionFilter/LogisticaLineasDespacho'


// Helpers
import { responseToasterContent } from "helpers/toasterContent";


// Services
import { createPlanificacion } from "services/logistica/planificacion/create.service";
import { getAllLogisticaLineasDespacho } from "services/logistica/lineasDespacho/getAll.service";


const CONSTANT = {
  title: 'Logistica - Líneas Despacho',
  path: 'logistica/lineas-despacho',
  reduxSelector: 'logisticaLineasDespacho',

  modal_replanificacion: 'Generar replanificación',
  modal_planificacion: 'Guardar planificación',
  modal_excepciones: 'Generar excepciones'
}


const tabsList = [
  { name: 'Válidas', url: '/logistica/lineas-despacho/validas' },
  { name: 'Inválidas', url: '/logistica/lineas-despacho/invalidas' },
]


const fetchData = async (data) => {
  return await Promise.all([
    getAllLogisticaLineasDespacho(data)
      .then(({ data }) => data)
      .catch(err => {
        console.error(err);
        return data
      }),
  ])
    .then(x => {
      let data = {}

      x.forEach(d => {
        data = { ...data, ...d }
      })

      return data
    })
}


export const Listado = () => {
  const { resultados: response, filtros } = useSelector(state => state[CONSTANT.reduxSelector])
  const dispatch = useDispatch()
  const [modalReplanificacion, setModalReplanificacion] = useState({ display: false, data: null })
  const [modalExcepciones, setModalExcepciones] = useState({ display: false, data: null })
  const [isLoading, setIsLoading] = useState(false)


  const handleModalReplanificacion = () => setModalReplanificacion(prev => ({ ...prev, display: !prev.display }))


  const handleModalExcepciones = () => setModalExcepciones(prev => ({ ...prev, display: !prev.display }))


  const handleSavePlanificaciones = async () => {
    const nvList = response?.despachos_validos?.listado.map(({ nv }) => Number(nv?.ORDER_NUM))

    await createPlanificacion({ nvs: nvList })
      .then(async res => {
        dispatch(fireToaster({ title: 'Operación realizada', icon: 'success' }))

        await fetchData(filtrosFormatter(filtros))
          .then(res => {
            dispatch(CONSTANT.reduxSetResults(res))
          })
          .finally(() => {
            setIsLoading(false)
          })
      })
      .catch(err => {
        dispatch(fireToaster(responseToasterContent(err)))
      })
      .finally(() => {
        setIsLoading(false)
      })
  }


  return (
    <div className="row">
      {isLoading && <CustomLoader blocking='full' />}

      <h3 className="mb-1 d-flex justify-content-between flex-column">
        <p><b>{CONSTANT.title}</b></p>

        <div className="d-flex gap-1">
          <Button text={CONSTANT.modal_planificacion} color="success" onClick={handleSavePlanificaciones} icon="Check" className={'w-fit'} />

          <Button text={CONSTANT.modal_replanificacion} onClick={handleModalReplanificacion} icon="Plus" className={'w-fit'} />

          <Button text={CONSTANT.modal_excepciones} color='warning' onClick={handleModalExcepciones} icon="AlertCircle" className={'w-fit'} />
        </div>
      </h3>

      <LogisticaLineasDespachoFiltros />

      <Tabs
        tabs={tabsList}
      />

      <LogisticaLineasDespachoRouter />

      <ModalReplanificacion display={modalReplanificacion.display} handleDisplay={handleModalReplanificacion} />

      <ModalExcepciones display={modalExcepciones.display} handleDisplay={handleModalExcepciones} />
    </div>
  );
}
