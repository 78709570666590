import axios from 'axios'
import { useEffect, useState } from 'react'


// Redux
import { fireToaster } from '../../redux/actions/toaster'
import { useDispatch } from 'react-redux'


// Components
import Accordion from '../../components/Accordion'
import SelectFilter from '../../components/SelectFilter'
import AccordionDetailData from '../../components/AccordionDetailData'
import Button from '../../components/Button'
import FormInput from '../../components/FormInput'
import Switch from '../../components/Switch'
import CustomLoader from '../../components/CustomLoader'
import Table from '../../components/Table'
import Modal from '../../components/Modal'
import { NoResults } from '../../components/Tables/Misc/NoResults'


// Router Dom
import { useNavigate, useParams } from 'react-router-dom'


// Layouts
import AccordionTable from '../../layout/AccordionTable'


// Endpoints
import { getOne } from '../../endpoints/getOne'
import { getAll } from '../../endpoints/getAll'
import { create } from '../../endpoints/create'
import { BASE_URL } from '../../endpoints'
import { idExtractor } from '../../helpers/idExtractor'
import { historicoData } from '../../helpers/historicoData'
import { ModalHistorico } from '../../components/Historico/ModalHistorico'
import TablaDetalle from '../../components/Historico/TablaDetalle'
import { PrecioFormatter } from '../../helpers/precioFormatter'


const CONSTANT = {
  queryUrlGetOne: getOne.puntos_entrega,
  redirectListado: '/puntos-entrega',
  redirectUpdate: (id) => `/puntos-entrega/update/${id}`,
  title: 'Puntos de entrega',
  modelo: 'App\\Models\\NeuPuntoEntrega',

  fetchAgendas: getOne.agendas,
  fetchDias: getAll.dias,
  fetchBodegasErp: getAll.erp_bodegas + '/obtener-vigentes',

  createAgenda: create.agendas,
  createDiaAgenda: create.dias_agenda,
  createHoraAgenda: create.horas_agenda,
  createContacto: create.contactos_punto_entrega,
  createBodegaPuntoEntrega: create.bodegas_punto_entrega,

  updateContacto: getOne.contactos_punto_entrega,
  updateBodegaPuntoEntrega: getOne.bodegas_punto_entrega,

  selectFilter: {
    bodegas: null
  },

}


const handleSelectFilterData = async () => {
  const bodegas = await axios(CONSTANT.fetchBodegasErp,
    {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
    })
    .then(({ data }) => data?.data ?? [])
    .catch(err => {
      console.error(err)
      return []
    })

  return { bodegas }
}


const Precio = (v) => new PrecioFormatter(v).producto()

const Detalle = () => {
  const [data, setData] = useState(null)
  const [dias, setDias] = useState(null)
  const [modalTarget, setModalTarget] = useState(null)
  const [diaAgendaData, setDiaAgendaData] = useState(null)
  const [horaAgendaData, setHoraAgendaData] = useState(null)
  const [contactoForm, setContactoForm] = useState({ nombre: '', email: '', fono: '', estatus: true })
  const [bodegaForm, setBodegaForm] = useState({ bodega_id: '', dias_estimado_llegada: '', estatus: true })
  const [selectFilter, setSelectFilter] = useState(CONSTANT.selectFilter)
  const [copyMapaUrl, setCopyMapaUrl] = useState(null)
  const { id } = useParams()
  const dispatch = useDispatch()
  const [isOpenModal, setIsOpenModal] = useState(false)
  const redirect = useNavigate()
  const [historico, setHistorico] = useState(null)
  const [modal, setModal] = useState({ id: null, display: false, data: null })


  // EFECTO QUE RELLENA LA DATA DE LOS SELECTFILTERS  
  useEffect(() => {
    handleSelectFilterData().then(data => setSelectFilter(data))
  }, [])


  // EFECTO QUE CONSULTA LA INFORMACIÓN DEL REGISTRO
  useEffect(() => {
    axios(CONSTANT.queryUrlGetOne(id),
      {
        headers: {
          'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
        },
      })
      .then(({ data }) => {
        setData(data)
        historicoData(Number(id), CONSTANT.modelo).then(data => setHistorico(data))
      })
      .catch(err => {
        console.error(err)
        const toasterContent = {
          title: 'Registro no encontrado',
          icon: 'warning'
        }

        dispatch(fireToaster(toasterContent))
        redirect(CONSTANT.redirectListado)
      })

    axios(CONSTANT.fetchDias,
      {
        headers: {
          'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
        },
      })
      .then(({ data }) => setDias(data))
      .catch(err => console.error(err))

    return () => {
      setData(null)
    }
  }, [id, dispatch, redirect])


  // FUNCION QUE MANEJA LA INFORMACION PARA CREAR UN DIA EN LA AGENDA
  const handleInputChange = (e, target) => {
    const { value, name } = e.target

    if (target === 'dia-agenda') {
      return setDiaAgendaData({
        estatus: true,
        agenda_id: modalTarget.targetId,
        [name]: value
      })
    }

    if (target === 'hora-agenda') {
      return setHoraAgendaData({
        ...horaAgendaData,
        estatus: true,
        dia_agenda_id: modalTarget.targetId,
        [name]: value
      })
    }

    if (target === 'contacto') {
      return setContactoForm({
        ...contactoForm,
        [name]: value
      })
    }

    if (target === 'bodega') {
      return setBodegaForm({
        ...bodegaForm,
        [name]: value
      })
    }
  }


  // FUNCION QUE RELLENA LOS DATOS A EDITAR DE UNA hora-agenda
  const handleHoraAgendaUpdate = (data) => {
    setHoraAgendaData({
      hora_inicio_intervalo: data?.hora_inicio_intervalo,
      hora_final_intervalo: data?.hora_final_intervalo,
      max_cliente: data?.max_cliente,
      estatus: data?.estatus
    })
    handleModalInfo('hora-agenda-update', data.id)
  }


  // FUNCION QUE MANEJA LA INFORMACION QUE VA A TENER EL MODAL
  const handleModalInfo = (target, targetId) => {
    setModalTarget({ target, targetId })
    setIsOpenModal(true)
  }


  // FUNCION QUE MANEJA LA INFORMACION QUE VA A TENER EL MODAL v2
  const handleModalData = (target, obj = null) => {
    if (target.includes('contacto')) {
      setContactoForm({
        id: obj?.id ?? null,
        nombre: obj?.nombre ?? '',
        email: obj?.email ?? '',
        fono: obj?.fono ?? '',
        estatus: obj?.estatus ?? true,
      })
    }

    if (target.includes('bodegas')) {
      setBodegaForm({
        id: obj?.id ?? null,
        bodega_id: obj?.bodega_id ?? '',
        dias_estimado_llegada: obj?.dias_estimado_llegada ?? '',
        estatus: obj?.estatus ?? true,
      })
    }

    setModalTarget({ target })
    setIsOpenModal(true)
  }


  // FUNCION MANEJADORA DEL CLICK DE COPIADO DE URL DEL MAPA
  // TODO:  cambiar al hook useCopyClipboard
  const handleCopyUrlButton = (data) => {
    if (!data) return
    setCopyMapaUrl(data)
    navigator.clipboard.writeText(data)
    dispatch(fireToaster({ title: 'Texto copiado', icon: 'success' }))
  }


  useEffect(() => {
    if (!isOpenModal) {
      setDiaAgendaData(null)
      setHoraAgendaData(null)
    }
  }, [isOpenModal])


  // FUNCIÓN MANEJADORA PARA ACTUALIZACIÓN DEL ESTATUS
  const handleFetch = async (objData, target) => {
    let config, fetchUrl

    if (target === 'estatus-agenda') {
      let newEstatus = objData?.agenda?.estatus ? 0 : 1
      let targetId = objData?.agenda?.id
      config = {
        method: 'POST',
        data: { estatus: newEstatus, _method: 'PUT' }
      }
      fetchUrl = CONSTANT.fetchAgendas(targetId)
    }

    if (target === 'habilitar-agenda') {
      config = {
        method: 'POST',
        data: {
          punto_entrega_id: Number(id),
          estatus: 1
        }
      }
      fetchUrl = CONSTANT.createAgenda
    }

    if (target === 'dia-agenda') {
      config = {
        method: 'POST',
        data: {
          ...diaAgendaData,
          dia_id: Number(diaAgendaData.dia_id.split('-')[0])
        }
      }
      fetchUrl = CONSTANT.createDiaAgenda
    }

    if (target === 'hora-agenda') {
      config = {
        method: 'POST',
        data: {
          ...horaAgendaData,
          dia_agenda_id: Number(horaAgendaData.dia_agenda_id),
          max_cliente: Number(horaAgendaData.max_cliente),
          sobrecupo: Number(horaAgendaData.sobrecupo)
        }
      }
      fetchUrl = CONSTANT.createHoraAgenda
    }

    if (target === 'hora-agenda-update') {
      config = {
        method: 'PUT',
        data: {
          ...horaAgendaData,
          dia_agenda_id: Number(horaAgendaData.dia_agenda_id),
          max_cliente: Number(horaAgendaData.max_cliente),
          sobrecupo: Number(horaAgendaData.sobrecupo),
          _method: 'PUT'
        }
      }
      fetchUrl = CONSTANT.createHoraAgenda + `/${objData}`
    }

    if (target === 'delete-dia-agenda') {
      config = {
        method: 'DELETE'
      }
      fetchUrl = CONSTANT.createDiaAgenda + `/${objData}`
    }

    if (target === 'delete-hora-agenda') {
      config = {
        method: 'DELETE'
      }
      fetchUrl = CONSTANT.createHoraAgenda + `/${objData}`
    }

    if (target === 'contacto') {
      if (!contactoForm?.nombre?.length) return dispatch(fireToaster({ title: 'El nombre es necesario', icon: 'info' }))
      if (!contactoForm?.fono?.length) return dispatch(fireToaster({ title: 'El teléfono es necesario', icon: 'info' }))

      config = {
        method: objData?.id ? 'PUT' : 'POST',
        data: {
          ...contactoForm,
          punto_entrega_id: Number(data?.id)
        }
      }
      fetchUrl = objData?.id ? CONSTANT.updateContacto(objData?.id) : CONSTANT.createContacto
    }

    if (target === 'contactos-delete') {
      if (!window.confirm('¿Está seguro que desea eliminar el registro?')) return
      config = {
        method: 'DELETE'
      }
      fetchUrl = CONSTANT.updateContacto(objData?.id)
    }

    if (target === 'bodega') {
      if (!bodegaForm?.bodega_id) return dispatch(fireToaster({ title: 'La bodega es necesaria', icon: 'info' }))
      if (!bodegaForm?.dias_estimado_llegada) return dispatch(fireToaster({ title: 'Ingrese días estimados de llegada', icon: 'info' }))
      console.log(objData);
      config = {
        method: objData?.id ? 'PUT' : 'POST',
        data: {
          bodega_id: idExtractor(objData.bodega_id),
          dias_estimado_llegada: Number(objData.dias_estimado_llegada),
          estatus: objData.estatus ? 1 : 0,
          punto_entrega_id: Number(data?.id)
        }
      }
      fetchUrl = objData?.id ? CONSTANT.updateBodegaPuntoEntrega(objData?.id) : CONSTANT.createBodegaPuntoEntrega
    }

    if (target === 'bodegas-delete') {
      if (!window.confirm('¿Está seguro que desea eliminar el registro?')) return
      config = {
        method: 'DELETE'
      }
      fetchUrl = CONSTANT.updateBodegaPuntoEntrega(objData?.id)
    }

    if (target === 'delete') {
      if (!window.confirm('¿Está seguro que desea eliminar el registro?')) return
      config = {
        method: 'DELETE'
      }
      fetchUrl = CONSTANT.queryUrlGetOne(id)
    }

    config = {
      ...config,
      headers: {
        ...config.headers,
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      }
    }

    await axios(fetchUrl, config)
      .then(res => {
        const toasterContent = {
          title: 'Operación realizada',
          text: `${CONSTANT.title}: registro actualizado con éxito`,
          icon: 'success'
        }

        dispatch(fireToaster(toasterContent))
        setIsOpenModal(false)
        setData(null)
        if (target === 'delete') return redirect(CONSTANT.redirectListado)
      })
      .catch(err => {
        console.error(err);
        const { errores } = err.response.data
        let detalles = []
        Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))

        const toasterContent = {
          title: `
            Operación fallida
            (${err.response.status} - ${err.response.statusText})
          `,
          html: `
            <b>Detalle: </b>
            ${detalles.map(error => `<br /><i>-${error}</i>`)}
          `,
          icon: 'error'
        }

        dispatch(fireToaster(toasterContent))
      })
      .finally(async () => {
        await axios(CONSTANT.queryUrlGetOne(id),
          {
            headers: {
              'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
            },
          })
          .then(({ data }) => {
            setData(data)
            historicoData(Number(id), CONSTANT.modelo).then(data => setHistorico(data))
          })
          .catch(err => {
            console.error(err)
            const toasterContent = {
              title: 'Registro no encontrado',
              icon: 'warning'
            }

            dispatch(fireToaster(toasterContent))
            redirect(CONSTANT.redirectListado)
          })
      })
  }


  // FUNCION MANEJADORA DE LA DATA DEL MODAL
  const handleModal = (data) => {
    const { id, anterior, nuevo } = data

    setModal({
      display: true,
      id,
      data: {
        anterior,
        nuevo
      }
    })
  }


  return (
    <div className='row'>

      <ModalHistorico modal={modal} fnHandleModal={setModal} />

      <div className='col-12'>
        <div className='col mb-50'>
          <div className='d-flex gap-1'>

            <div className='col'>
              <Button text={CONSTANT.title} icon='ArrowLeft' isLink url={CONSTANT.redirectListado} />
            </div>

            <Button text='Editar' icon='Settings' color='success' isLink url={CONSTANT.redirectUpdate(id)} />
            <Button text='Borrar' icon='Trash' color='danger' onClick={() => handleFetch(data, 'delete')} />
          </div>
        </div>

        {data && selectFilter?.bodegas
          ? <div className='row'>

            <Modal display={isOpenModal} handleValue={() => setIsOpenModal(false)} size={'h-100'}>
              {/* MARK: MODAL-DIA-AGENDA */}
              {modalTarget?.target === 'dia-agenda' &&
                <>
                  <h3 className='text-center mb-2'>Día agenda</h3>
                  <div>
                    <SelectFilter
                      name='dia_id'
                      value={diaAgendaData?.dia_id?.split('-')[1] ?? 'Seleccione'}
                      labelText="* Día"
                      optionObj={dias?.map(({ valor, id }) => ({ id, name: valor }))}
                      size="col-12"
                      sizeDesk='col-md-12'
                      handleValue={(e) => handleInputChange(e, 'dia-agenda')}
                    />
                    <Button text='Guardar día' icon='Check' className='mx-auto my-1' onClick={() => handleFetch(diaAgendaData, 'dia-agenda')} />
                  </div>
                </>
              }


              {/* MARK: MODAL-HORA AGENDA */}
              {(modalTarget?.target === 'hora-agenda' || modalTarget?.target === 'hora-agenda-update') &&
                <>
                  <h3 className='text-center mb-2'>Hora agenda</h3>

                  <div className='row'>
                    <FormInput
                      type='time'
                      name='hora_inicio_intervalo'
                      value={horaAgendaData?.hora_inicio_intervalo}
                      labelText='* Hora de inicio'
                      handleValue={(e) => handleInputChange(e, 'hora-agenda')}
                      size="col-12"
                      sizeDesk='col-md-6'
                    />

                    <FormInput
                      type='time'
                      name='hora_final_intervalo'
                      value={horaAgendaData?.hora_final_intervalo}
                      labelText='* Hora final'
                      handleValue={(e) => handleInputChange(e, 'hora-agenda')}
                      size="col-12"
                      sizeDesk='col-md-6'
                    />

                    <FormInput
                      type='number'
                      name='max_cliente'
                      value={horaAgendaData?.max_cliente}
                      labelText="* Max cliente"
                      handleValue={(e) => handleInputChange(e, 'hora-agenda')}
                      size="col-12"
                      sizeDesk='col-md-6'
                    />

                    {/* TODO: MODIFICAR LA INTEGRACION CON EL SOBRECUPO */}
                    <FormInput
                      type='number'
                      name='sobrecupo'
                      value={horaAgendaData?.sobrecupo}
                      labelText="Sobrecupo"
                      handleValue={(e) => handleInputChange(e, 'hora-agenda')}
                      size="col-12"
                      sizeDesk='col-md-6'
                    />
                  </div>
                  <div>
                    <Button
                      text='Guardar hora'
                      icon='Check'
                      className='mx-auto my-1'
                      onClick={() => handleFetch(modalTarget.targetId, modalTarget.target)}
                    />
                  </div>
                </>
              }

              {/* MARK: MODAL-CONTACTOS */}
              {modalTarget?.target.includes('contactos') &&
                <>
                  <h3 className='text-center mb-2'>{contactoForm?.id ? 'Editar' : 'Agregar'} contacto | Punto de entrega</h3>

                  <div className='row'>
                    <Switch
                      name='estatus'
                      labelText='Estatus'
                      value={contactoForm?.estatus}
                      handleValue={(e) => handleInputChange(e, 'contacto')}
                    />

                    <FormInput
                      name='nombre'
                      value={contactoForm?.nombre}
                      labelText="* Nombre"
                      handleValue={(e) => handleInputChange(e, 'contacto')}
                      size="col-12"
                      sizeDesk='col-md-6'
                    />

                    <FormInput
                      name='email'
                      value={contactoForm?.email}
                      labelText="Email"
                      handleValue={(e) => handleInputChange(e, 'contacto')}
                      size="col-12"
                      sizeDesk='col-md-6'
                    />

                    <FormInput
                      name='fono'
                      value={contactoForm?.fono}
                      labelText="* Teléfono"
                      handleValue={(e) => handleInputChange(e, 'contacto')}
                      size="col-12"
                      sizeDesk='col-md-6'
                    />
                  </div>

                  <div>
                    <Button
                      text={contactoForm?.id ? 'Editar' : 'Crear'}
                      icon='Check'
                      className='mx-auto my-1'
                      onClick={() => handleFetch(contactoForm, 'contacto')}
                    />
                  </div>
                </>
              }

              {/* MARK: MODAL-BODEGAS */}
              {modalTarget?.target.includes('bodegas') &&
                <>
                  <h3 className='text-center mb-2'>{bodegaForm?.id ? 'Editar' : 'Agregar'} bodega | Punto de entrega</h3>

                  <div className='row'>
                    <Switch
                      name='estatus'
                      labelText='Estatus'
                      value={bodegaForm?.estatus}
                      handleValue={(e) => handleInputChange(e, 'bodega')}
                    />

                    {bodegaForm?.id
                      ? <FormInput
                        value={bodegaForm?.bodega_id}
                        labelText="Bodega"
                        isDisabled
                        size="col-12"
                        sizeDesk='col-md-6'
                      />

                      : <SelectFilter
                        name='bodega_id'
                        value={bodegaForm?.bodega_id ?? 'Selecccione'}
                        labelText="* Bodega"
                        optionObj={selectFilter?.bodegas?.map(({ WARE_CODE, DESC_TEXT }) => ({ id: WARE_CODE, name: `${WARE_CODE} | ${DESC_TEXT}` }))}
                        handleValue={(e) => handleInputChange(e, 'bodega')}
                        size="col-12"
                        sizeDesk='col-md-12'
                      />
                    }


                    <FormInput
                      name='dias_estimado_llegada'
                      type='number'
                      value={bodegaForm?.dias_estimado_llegada}
                      labelText="* Días estimados"
                      handleValue={(e) => handleInputChange(e, 'bodega')}
                      size="col-12"
                      sizeDesk='col-md-4'
                    />
                  </div>

                  <div>
                    <Button
                      text={bodegaForm?.id ? 'Editar' : 'Crear'}
                      icon='Check'
                      className='mx-auto my-1'
                      onClick={() => handleFetch(bodegaForm, 'bodega')}
                    />
                  </div>
                </>
              }

            </Modal>

            {/* MARK: DATOS GENERALES */}
            <Accordion
              title={<h3 className='m-0 w-100 text-center text-white'>Datos generales</h3>}
              isOpen
              classes='col-md-6 col-12 mx-auto mb-1'
            >
              <AccordionDetailData dataKey={'ID'} value={data?.id} />
              <AccordionDetailData dataKey={'Sucursal propia'} value={data?.sucursal_propia ? 'Sí' : 'No'} />
              <AccordionDetailData dataKey={'Tarifa plana'} value={data?.es_tarifa_plana ? 'Sí' : 'No'} />
              <AccordionDetailData dataKey={'Sucursal maxi.'} value={data?.sucursal_maxi ?? 'N/A'} />
              <AccordionDetailData dataKey={'Instalación hasta'} value={Precio(data?.aplicar_instalacion_hasta)} />
              <AccordionDetailData dataKey={'Nombre'} value={data?.nombre ?? 'N/A'} />
              <AccordionDetailData dataKey={'Comuna'} value={data?.comuna?.nombre ?? 'N/A'} />
              <AccordionDetailData dataKey={'RUT proveedor'} value={data?.rut_proveedor ?? 'N/A'} />
              <AccordionDetailData dataKey={'Método de entrega'} value={data?.metodo_entrega?.nombre ?? 'N/A'} />
              <AccordionDetailData dataKey={'Tipo de destino'} value={data?.tipo_destino?.nombre ?? 'N/A'} />
              <AccordionDetailData dataKey={'Dirección'} value={data?.direccion ?? 'N/A'} />
              <AccordionDetailData dataKey={'Descripción'} value={data?.descripcion ?? 'N/A'} />
              <AccordionDetailData dataKey={'Días de despacho'} value={data?.meta?.dias_despacho ?? 'N/A'} />
              <AccordionDetailData dataKey={'Imagen'} value={<img src={BASE_URL + 'storage/' + data?.imagen} alt={`img-${data?.nombre}`} />} />
              <AccordionDetailData dataKey={'URL del mapa'} value={
                <div className='d-flex gap-1'>
                  <textarea className='custom-scrollbar w-100 rounded border' readOnly value={data?.url_mapa ?? 'N/A'}></textarea>

                  {data?.url_mapa &&
                    <Button className='my-auto' icon={copyMapaUrl ? 'Check' : 'Link'} onClick={() => handleCopyUrlButton(data?.url_mapa)} />
                  }
                </div>
              } />
              <AccordionDetailData dataKey={'Estatus'} value={
                <Switch
                  value={data?.estatus}
                  labelText={""}
                  margin='my-0'
                  handleValue={() => handleFetch(data, 'estatus')}
                />
              } />
              <AccordionDetailData dataKey={'Creación'} value={data?.created_at?.split('T')[0] ?? 'N/A'} />
              <AccordionDetailData dataKey={'Modicación'} value={data?.updated_at?.split('T')[0] ?? 'N/A'} />
              <AccordionDetailData dataKey={'Agenda'} value={data?.agenda?.id

                ? <Switch
                  value={data?.agenda?.estatus}
                  labelText={""}
                  margin='my-0'
                  handleValue={() => handleFetch(data, 'estatus-agenda')}
                />

                : <Button text='Habilitar agenda' onClick={() => handleFetch(data, 'habilitar-agenda')} />
              } last />
            </Accordion>

            {/* MARK: AGENDA */}
            {data?.agenda?.estatus &&
              <div className='col-12 col-md-6 mx-auto mb-1'>
                <div className='rounded overflow-hidden border'>
                  <div className='d-flex justify-content-between border-bottom bg-color-brand p-50'>
                    <h3 className='m-0 h-fit text-center text-white d-flex justify-content-between ms-1'>Agenda</h3>
                    <Button text='Agregar día' icon='Plus' color='success' className='me-1 p-25' onClick={() => handleModalInfo('dia-agenda', data?.agenda?.id)} />
                  </div>
                  <div className='bg-white'>
                    {data?.agenda?.dias_agenda.length
                      ? data?.agenda?.dias_agenda.map(dia => (
                        <div key={`${dia.id}-${dia.agenda_id}-${dia.dia_id}`} className='border-bottom' >
                          <div className='d-flex justify-content-between p-25 px-50' style={{ backgroundColor: '#eee' }}>
                            <b className='text-center h-fit my-auto'>{dia?.dia?.valor.toUpperCase() ?? '?'}</b>
                            <div className='d-flex gap-50'>
                              <Button className='p-25' text='Añadir hora' icon='Plus' color='success' onClick={() => handleModalInfo('hora-agenda', dia.id)} />
                              <Button className='p-25' text='Eliminar día' icon='X' color='danger' onClick={() => handleFetch(dia.id, 'delete-dia-agenda')} />
                            </div>
                          </div>

                          <div>
                            {dia?.horas_agenda?.length
                              ? dia?.horas_agenda?.map(hora => (
                                <div key={`${hora?.id}-${hora?.dia_agenda_id}`} className='d-flex border-top p-50 justify-content-between' style={{ backgroundColor: hora?.sobrecupo > 0 && 'rgb(255 216 0 / 20%)' }}>
                                  <span><b>Inicio:</b> {hora?.hora_inicio_intervalo} | <b>Final:</b> {hora?.hora_final_intervalo} | <b>Max clientes:</b> {hora?.max_cliente} {hora?.sobrecupo > 0 && <>| <b>Sobrecupo:</b> {hora?.sobrecupo ?? 10}</>}</span>
                                  <div className='d-flex gap-50'>
                                    <Button className='p-25' icon='Settings' onClick={() => handleHoraAgendaUpdate(hora)} />
                                    <Button className='p-25' icon='X' color='danger' onClick={() => handleFetch(hora.id, 'delete-hora-agenda')} />
                                  </div>
                                </div>
                              ))

                              : <div className='p-50'>Sin Horas asignadas...</div>
                            }
                          </div>
                        </div>
                      ))

                      : <div className='p-50'>Sin días asignados...</div>
                    }
                  </div>
                </div>
              </div>
            }

            {/* MARK: BODEGA-INSTALACION */}
            <Accordion
              title={<h3 className='m-0 w-100 text-center text-white'>Bodega instalación</h3>}
              isOpen
              classes='col-md-6 col-12 mx-auto mb-1'
            >
              <AccordionDetailData dataKey={'ID'} value={data?.bodega_instalacion?.id ?? 'N/A'} />
              <AccordionDetailData dataKey={'Código'} value={data?.bodega_instalacion?.codigo ?? 'N/A'} />
              <AccordionDetailData dataKey={'Nombre'} value={data?.bodega_instalacion?.nombre ?? 'N/A'} last />
            </Accordion>

            {/* MARK: CONTACTOS */}
            <AccordionTable
              classes='mb-1 col-12 col-md-7'
              title='Contactos'
            >
              <Table pb={false}>
                <thead className="table-light">
                  <tr role="row">
                    <th className='col-6'>ID</th>
                    <th className='col-4'>Nombre</th>
                    <th className='col-4'>Email / Teléfono</th>
                    <th className='col-1 text-center'>Estatus</th>
                    <th className='col-1'>
                      <Button icon='Plus' color='success' text='Adjuntar' onClick={() => handleModalData('contactos')} className='p-25' />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data?.contactos_punto_entrega?.length

                    ? data?.contactos_punto_entrega?.map(data => (
                      <tr key={`contactos-${data?.id}-${data?.nombre ?? '?'}`}>
                        <td>{data?.id ?? 'N/A'}</td>

                        <td>{data?.nombre ?? 'N/A'}</td>

                        <td>
                          <div className='d-flex flex-column'>
                            <b>{data?.email ?? 'N/A'}</b>
                            <span>{data?.fono ?? 'N/A'}</span>
                          </div>
                        </td>

                        <td>
                          <Switch
                            value={data?.estatus ? true : false}
                            labelText={null}
                            classes='mx-auto mt-0 mb-0'
                            handleValue={() => alert('No implementado')}
                          />
                        </td>

                        <td>
                          <Button icon='Settings' color='warning' text='Editar' onClick={() => handleModalData('contactos', data)} className='p-25 mb-25' />
                          <Button icon='X' color='danger' text='Borrar' onClick={() => handleFetch(data, 'contactos-delete')} className='p-25' />
                        </td>
                      </tr>
                    ))

                    : <tr><td colSpan={6}><NoResults /></td></tr>
                  }
                </tbody>
              </Table>
            </AccordionTable>

            {/* MARK: BODEGAS */}
            <AccordionTable
              classes='mb-1 col-12 col-md-5'
              title='Bodegas'
            >
              <Table pb={false}>
                <thead className="table-light">
                  <tr role="row">
                    <th className='col-6'>ID</th>
                    <th className='col-4'>Bodega</th>
                    <th className='col-4'>Días</th>
                    <th className='col-1 text-center'>Estatus</th>
                    <th className='col-1'>
                      <Button icon='Plus' color='success' text='Adjuntar' onClick={() => handleModalData('bodegas')} className='p-25' />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data?.bodegas?.length

                    ? data?.bodegas?.map(data => (
                      <tr key={`contactos-${data?.id}-${data?.nombre ?? '?'}`}>
                        <td>{data?.id ?? 'N/A'}</td>

                        <td>{data?.bodega_id ?? 'N/A'}</td>

                        <td>{data?.dias_estimado_llegada ?? 'N/A'}</td>

                        <td>
                          <Switch
                            value={data?.estatus ? true : false}
                            labelText={null}
                            classes='mx-auto mt-0 mb-0'
                            handleValue={() => alert('No implementado')}
                          />
                        </td>

                        <td>
                          <Button icon='Settings' color='warning' text='Editar' onClick={() => handleModalData('bodegas', data)} className='p-25 mb-25' />
                          <Button icon='X' color='danger' text='Borrar' onClick={() => handleFetch(data, 'bodegas-delete')} className='p-25' />
                        </td>
                      </tr>
                    ))

                    : <tr><td colSpan={6}><NoResults /></td></tr>
                  }
                </tbody>
              </Table>
            </AccordionTable>

            <TablaDetalle historicoData={historico} fnHandleModal={handleModal} />

          </div>

          : <CustomLoader />
        }
      </div>
    </div>

  )
}

export default Detalle