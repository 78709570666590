import axios from "axios"
import { getAll } from "../../endpoints/getAll"
import { queryParamsFormatter } from "../../helpers/queryParamsFormatter"
import { idExtractor } from "../../helpers/idExtractor"
import { successResponse } from "../../helpers/successResponse"

export const filtrosFormatter = (filters) => {
  let obj = {}

  Object.entries(filters).forEach(([k, v]) => {
    if (v.includes('NULL')) return

    if (['comuna_id', 'sucursal_id'].includes(k) && v.length) {
      obj = {
        ...obj,
        [k]: idExtractor(v, true)
      }
      return
    }

    if (v.length) {
      obj = {
        ...obj,
        [k]: v
      }
    }
  })

  return obj
}


export const getAllAutogestion = async (limit, params = {}, paginationUrl = undefined) => {
  try {
    const response = await axios(`${paginationUrl ?? getAll.autogestion}${paginationUrl ? '&' : '?'}limit=${limit}${queryParamsFormatter(filtrosFormatter(params))}`, {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
    })

    if (!successResponse(response)) throw response

    return response
  } catch (err) {
    return Promise.reject(err)
  }
}