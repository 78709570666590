import axios from 'axios';


// Redux
import { useDispatch, useSelector } from 'react-redux'
import { fireToaster } from "../../redux/actions/toaster";
import { clearFilterUsuarios, resultadosUsuarios } from '../../redux/actions/usuarios';


// Components
import CustomLoader from "../CustomLoader";
import DropdownMenuLink from "../Dropdown/Link";
import DropdownWrapper from "../Dropdown/Wrapper";
import Switch from "../Switch";
import Table from "../Table";


// Endpoints
import { getOne } from "../../endpoints/getOne";


// Layouts
import AccordionTable from "../../layout/AccordionTable";
import { idExtractor } from '../../helpers/idExtractor';
import { queryParamsFormatter } from '../../helpers/queryParamsFormatter';
import NameAvatar from '../NameAvatar';


const CONSTANT = {
  pathUrl: '/usuarios',
  permissionsPath: 'usuarios',
  queryUrlGetOne: getOne.usuarios,
  title: 'Usuarios',
  reduxClearFilters: clearFilterUsuarios,
  reduxSetResults: resultadosUsuarios,
}


const dataFormatter = (filtrosObj) => {
  let data = {}

  Object.entries(filtrosObj).forEach(([key, value]) => {
    if (key === 'busqueda') return

    if (value.length > 0) {
      data = {
        ...data,
        filtros: {
          ...data.filtros,
          [key]: value.map(el => idExtractor(el))
        }
      }
    }
  })

  return data
}


const UsuariosTable = () => {
  const { resultados: response, filtros } = useSelector(state => state.usuarios)
  const { showing_results } = useSelector(state => state.listedResults)
  const { busqueda } = filtros
  const dispatch = useDispatch()
  const { user } = useSelector(state => state.auth)


  // FUNCION MANEJADORA DE PAGINACIÓN
  const handleUrl = (url) => {
    dispatch(CONSTANT.reduxSetResults(null))
    axios(url + `&limit=${showing_results}`,
      {
        headers: {
          'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
        },
        method: 'POST',
        data: {
          ...dataFormatter(filtros)
        }
      })
      .then(res => dispatch(CONSTANT.reduxSetResults(res.data)))
      .catch(err => {
        console.error(err)
        dispatch(CONSTANT.reduxSetResults([]))
      })
  }


  // FUNCIÓN MANEJADORA PARA ACTUALIZAR EL ESTATUS
  const handleFetch = async (objData, url, target) => {
    let config = {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
    }

    if (target === 'estatus') {
      let newEstatus = objData[target] ? 0 : 1
      config = {
        data: {
          ...config,
          method: 'POST',
          [target]: newEstatus,
          _method: 'PUT'
        }
      }
    }

    if (target === 'delete') {
      if (!window.confirm('¿Estás seguro que desea borrar el registro?')) return
      config = {
        ...config,
        method: 'DELETE',
      }
    }

    await axios(CONSTANT.queryUrlGetOne(objData.id), config)
      .then(res => {
        let toasterContent = {
          title: 'Operación realizada',
          text: `${CONSTANT.title}: ${target} actualizado con éxito`,
          icon: 'success'
        }

        if (target === 'delete') {
          toasterContent = {
            title: 'Operación realizada',
            text: `Registro borrado con éxito`,
            icon: 'success'
          }

          return dispatch(fireToaster(toasterContent))
        }

        dispatch(fireToaster(toasterContent))
      })
      .catch(err => {
        console.error(err);
        const { errores } = err.response.data
        let detalles = []
        Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))

        const toasterContent = {
          title: `
            Operación fallida
            (${err.response.status} - ${err.response.statusText})
          `,
          html: `
            <b>Detalle: </b>
            ${detalles.map(error => `<br /><i>-${error}</i>`)}
          `,
          icon: 'error'
        }
        dispatch(fireToaster(toasterContent))
      })

    dispatch(CONSTANT.reduxSetResults(null))

    axios(url,
      {
        headers: {
          'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
        },
        method: 'POST',
        data: {
          ...dataFormatter(filtros)
        }
      })
      .then(res => {
        dispatch(CONSTANT.reduxSetResults(res.data))
      })
      .catch(err => {
        console.error(err)
        dispatch(CONSTANT.reduxSetResults([]))
      })
  }


  return (
    <AccordionTable title={`${CONSTANT.title} (${response?.data?.length ?? 0} resultados)`}>
      {response ?
        <Table response={response} handlePagination={handleUrl}>
          <thead className="table-light position-sticky top-0 w-100 z-100 shadow">
            <tr role="row">
              <th>ID</th>
              <th>Datos</th>
              <th>RUT</th>
              <th>País</th>
              <th>Roles</th>
              <th>Creación</th>
              <th>Estatus</th>
              <th className="w-max">Acciones</th>
            </tr>
          </thead>
          <tbody>
            {response?.data?.length
              ? response.data?.map((data) => {
                return (
                  <tr className="odd" key={`user-${data.id}-${data.titulo}`}>
                    <td>{data?.id}</td>

                    <td className='d-flex'>
                      <NameAvatar name={data.nombre} />

                      <div className='d-flex flex-column'>
                        <span className='fw-bold'>{data.nombre ?? 'N/A'}</span>
                        <span className='form-label mb-0'>{data.email ?? 'N/A'}</span>
                        <span className='form-label mb-0'>{data.fono ?? 'N/A'}</span>
                      </div>
                    </td>

                    <td>{data?.rut ?? "N/A"}</td>

                    <td>{data?.pais?.nombre ?? "N/A"}</td>

                    <td>
                      {data?.roles?.length

                        ? data?.roles?.map((el, i) => {
                          return (
                            <span className='px-50 tag' key={`user-roles-${data.id}-${i}`}>{el?.name ?? el ?? '?'}</span>
                          )
                        })

                        : <span className='px-50'>Sin roles</span>
                      }
                    </td>

                    <td>{data?.created_at?.split("T")[0] || 'N/A'}</td>

                    <td>
                      <Switch
                        value={data.estatus ? 1 : 0}
                        labelText={""}
                        handleValue={() => handleFetch(data, response.path + '?page=' + response.current_page + `&limit=${showing_results}`, 'estatus')}
                      />
                    </td>

                    <td>
                      <DropdownWrapper icon={"MoreVertical"} classes="w-fit mx-auto" >
                        {user.permisos[`${CONSTANT.permissionsPath}.detalle`] &&
                          <DropdownMenuLink text='Detalle' href={`${CONSTANT.pathUrl}/detalle/${data.id}`} icon="Table" />
                        }

                        {user.permisos[`${CONSTANT.permissionsPath}.editar`] &&
                          <DropdownMenuLink text='Editar' href={`${CONSTANT.pathUrl}/update/${data.id}`} icon="Settings" />
                        }

                        {user.permisos[`${CONSTANT.permissionsPath}.eliminar`] &&
                          <DropdownMenuLink text='Borrar' icon="X" onClick={() => handleFetch(data, response.path + '?page=' + response.current_page + `&limit=${showing_results}` + queryParamsFormatter({ busqueda }), 'delete')} />
                        }
                      </DropdownWrapper>
                    </td>
                  </tr>
                )
              })

              : <tr><td className="text-center fw-bolder fs-2 text-lgray" colSpan={8}><i>Sin datos para mostrar...</i></td></tr>
            }
          </tbody>
        </Table>
        : <CustomLoader blocking={'partial'} />
      }
    </AccordionTable >
  )
}

export default UsuariosTable