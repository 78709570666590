import React, { useEffect, useState } from 'react'


// Redux
import { useDispatch } from 'react-redux'
import { clearFilterLogisticaReplanificacionMotivos, resultadosLogisticaReplanificacionMotivos } from '../../redux/actions/logisticaReplanificacionMotivos'


// Components
import CustomLoader from '../CustomLoader'


// Layouts
import ListadoHeader from '../../layout/ListadoHeader'


// Helpers


// Services
import { getAllLogisticaReplanificacionMotivos } from '../../services/logistica/replanificacionMotivos/getAll.service'



const CONSTANT = {
  redirectUrl: '/logistica/replanificacion-motivos',
  title: 'Filtros: Motivos - Replanificación',

  reduxStateSelector: 'logisticaReplanificacionMotivos',
  reduxClearFilters: clearFilterLogisticaReplanificacionMotivos,
  reduxSetResults: resultadosLogisticaReplanificacionMotivos,

  selectFilter: {}
}


export const filtrosFormatter = (filtros) => {
  let validFormat = {}

  Object.entries(filtros).forEach(([k, v]) => {
    if (v.includes('*NULL*')) return

    if (['fecha_desde', 'fecha_hasta'].includes(k) && v.length) {
      validFormat = {
        ...validFormat,
        [k]: v
      }
    }
  })

  return validFormat
}


const fetchData = async (params) => {
  return await Promise.all([
    getAllLogisticaReplanificacionMotivos(params)
      .then(({ data }) => data)
      .catch(err => {
        console.error(err);
        return []
      }),
  ])
    .then(x => {
      let data = {}

      x.forEach(d => {
        data = { ...data, ...d }
      })

      return data
    })
}


export const LogisticaReplanificacionMotivosFiltros = () => {
  const dispatch = useDispatch()
  // const { filtros } = useSelector(state => state[CONSTANT.reduxStateSelector])
  // const { } = filtros
  // const [selectFilter, setSelectFilter] = useState(CONSTANT.selectFilter)
  const [isConsulting, setIsConsulting] = useState(false)


  useEffect(() => {
    return () => {
      dispatch(CONSTANT.reduxSetResults([]))
    }
  }, [dispatch])



  // FUNCION QUE CONSULTA EL BACKEND CON LOS FILTROS SELECCIONADOS
  const handleFetchFilter = async (reset = false) => {
    if (reset) {
      dispatch(CONSTANT.reduxClearFilters())
      return
    }

    setIsConsulting(true)

    await fetchData()
      .then(res => {
        dispatch(CONSTANT.reduxSetResults(res))
      })
      .finally(() => {
        setIsConsulting(false)
      })
  }


  // MANEJADORES DE FILTRO
  // const handleFilter = (e, filter) => {
  //   const { name, value } = e.target

  //   switch (filter) {
  //     case FILTER_REPLANIFICACION_INPUT:
  //       dispatch(replanificacionInputFilter(name, value))
  //       break;

  //     default:
  //       break;
  //   }
  // }


  return (
    <div className="card mb-50 shadow-none bg-transparent">
      {isConsulting && <CustomLoader blocking={'partial'} />}

      <ListadoHeader
        classes='border rounded-2'
        title={`${CONSTANT.title}`}
        handleDisable={isConsulting}
        handleClickSearch={() => handleFetchFilter()}
        handleClickClearFilter={() => handleFetchFilter(true)}
      >
        <>
        </>
      </ListadoHeader>
    </div>
  )
}