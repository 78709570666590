import { Navigate, Route, Routes } from "react-router-dom"
import { SucursalesRouter } from "./SucursalesRouter";
import { UsuariosSucursalesRouter } from "./UsuariosSucursalesRouter";
import { Index } from "../../views/Autoservicio/Index";

export const AutoservicioRouter = () => {
   return (
      <Routes>
         <Route path='/*' element={<Index />} />

         <Route path='/sucursales/*' element={<SucursalesRouter />} />

         <Route path='/usuarios-sucursales/*' element={<UsuariosSucursalesRouter />} />

         <Route path='*' element={<Navigate to='/home' replace />} />
      </Routes>
   );
}
