import React, { useEffect, useState } from 'react'


// Redux
import { useDispatch, useSelector } from 'react-redux'
import { FILTER_FECHA_FINAL, FILTER_FECHA_INICIO, FILTER_MOTIVO_ID, FILTER_NOTA_VENTA, FILTER_PEDIDO_ID } from '../../../redux/redux-types'
import { fireToaster } from '../../../redux/actions/toaster'
import {
  clearFilterDevoluciones,
  motivoFilter,
  notasVentaFilter,
  pedidoFilter,
  resultadosDevoluciones
} from '../../../redux/actions/devoluciones'


// Components
import FormInput from '../../FormInput'
import SelectFilter from '../../SelectFilter'


// Layouts
import ListadoHeader from '../../../layout/ListadoHeader'


// Helpers
import { filtrosAplicadosTotal } from '../../../helpers/filtrosAplicadosTotal'
import { idExtractor } from '../../../helpers/idExtractor'
import { responseToasterContent } from 'helpers/toasterContent'


// Services
import { getAllDevoluciones } from '../../../services/devoluciones/getAll.service'
import { getAllMotivos } from '../../../services/devoluciones/motivos/getAll.service'
import { fechaFinalFilter, fechaInicialFilter } from 'redux/actions/devolucionesReportes'


const CONSTANT = {
  redirectUrl: '/devoluciones',
  title: 'Búsqueda de devoluciones',

  reduxStateSelector: 'devoluciones',
  reduxClearFilters: clearFilterDevoluciones,
  reduxSetResults: resultadosDevoluciones,

  selectFilter: {
    motivos: [],
    bancos: [],
  }
}


export const filtrosFormatter = (filtros) => {
  let validFormat = {}

  Object.entries(filtros).forEach(([k, v]) => {
    if ([`motivo_id`].includes(k) && v.length && !v.includes('NULL')) {
      validFormat = {
        ...validFormat,
        [k]: idExtractor(v, true)
      }
    }

    if ([`pedido_id`, `fecha_inicial`, `fecha_final`, `notas_venta`].includes(k) && v.length) {
      validFormat = {
        ...validFormat,
        [k]: v
      }
    }
  })

  return validFormat
}


export const handleSelectsData = async () => {
  return await Promise.all([
    getAllMotivos()
      .then(res => ({ motivos: [...res.data.data] }))
      .catch(err => {
        console.error(err);
        return []
      })
  ])
    .then(x => {
      let res = {}
      x.forEach(d => Object.entries(d).forEach(([k, v]) => res = { ...res, [k]: v }))
      return res
    })
}


export const fetchData = async (params = {}, limit) => {
  return await getAllDevoluciones(params, limit)
    .then((res) => res)
    .catch(err => {
      Promise.reject(err)
    })
}

export const DevolucionesFiltros = () => {
  const dispatch = useDispatch()
  const { filtros } = useSelector(state => state[CONSTANT.reduxStateSelector])
  const { showing_results } = useSelector(state => state.listedResults)
  const { pedido_id, motivo_id, fecha_inicial, fecha_final, notas_venta } = filtros
  const [selectFilter, setSelectFilter] = useState(CONSTANT.selectFilter)

  const [isConsulting, setIsConsulting] = useState(false)


  // EFECTO QUE CARGA LOS DATOS DE LOS SELECTFILTERS
  useEffect(() => {
    setIsConsulting(true)

    handleSelectsData().then((x) => setSelectFilter(x))

    setIsConsulting(false)

    return () => dispatch(CONSTANT.reduxClearFilters())
  }, [showing_results, dispatch])


  // FUNCION QUE CONSULTA EL BACKEND CON LOS FILTROS SELECCIONADOS
  const handleFetchFilter = async (reset = false) => {
    // if (!fecha_inicial) return dispatch(fireToaster({ title: 'La fecha inicial es requerida', icon: 'warning' }))
    // if (!fecha_final) return dispatch(fireToaster({ title: 'La fecha final es requerida', icon: 'warning' }))

    if (reset) {
      dispatch(CONSTANT.reduxClearFilters())
      return
    }

    setIsConsulting(true)

    await fetchData(filtrosFormatter(filtros), showing_results)
      .then((res) => {
        dispatch(CONSTANT.reduxSetResults(res.data))
        dispatch(fireToaster(responseToasterContent(res)))
      })
      .catch((err) => {
        dispatch(fireToaster(responseToasterContent(err)))
        dispatch(CONSTANT.reduxSetResults([]))
        return
      })
      .finally(() => {
        setIsConsulting(false)
      })
  }


  // MANEJADORES DE FILTRO
  const handleFilter = (e, filter) => {
    const { value } = e.target

    switch (filter) {
      case FILTER_PEDIDO_ID:
        dispatch(pedidoFilter(value))
        break;

      case FILTER_MOTIVO_ID:
        dispatch(motivoFilter(value))
        break;

      case FILTER_FECHA_INICIO:
        dispatch(fechaInicialFilter(value))
        break;

      case FILTER_FECHA_FINAL:
        dispatch(fechaFinalFilter(value))
        break;

      case FILTER_NOTA_VENTA:
        dispatch(notasVentaFilter(value))
        break;

      default:
        break;
    }
  }


  return (
    <div className="card mb-50 shadow-none bg-transparent">
      <ListadoHeader
        classes='border rounded-2'
        title={`${CONSTANT.title} (${filtrosAplicadosTotal(filtros)})`}
        handleDisable={isConsulting}
        handleClickSearch={() => handleFetchFilter()}
        handleClickClearFilter={() => handleFetchFilter(true)}
      >
        <>
          <FormInput
            labelText='# Pedido'
            placeholder='Ingrese un número de pedido'
            size='col-12'
            sizeDesk='col-md-3'
            handleValue={(e) => handleFilter(e, FILTER_PEDIDO_ID)}
            value={pedido_id}
          />

          <FormInput
            labelText='NV'
            placeholder='Ingrese las NV'
            size='col-12'
            sizeDesk='col-md-3'
            handleValue={(e) => handleFilter(e, FILTER_NOTA_VENTA)}
            value={notas_venta}
          />

          <FormInput
            type='date'
            labelText='Fecha inicio'
            size='col-12'
            sizeDesk='col-md-2'
            handleValue={(e) => handleFilter(e, FILTER_FECHA_INICIO)}
            value={fecha_inicial}
          />

          <FormInput
            type='date'
            labelText='Fecha final'
            size='col-12'
            sizeDesk='col-md-2'
            handleValue={(e) => handleFilter(e, FILTER_FECHA_FINAL)}
            value={fecha_final}
          />

          <SelectFilter
            labelText="Motivos"
            altSeparator
            size="col-12"
            sizeDesk={"col-md-2"}
            optionObj={selectFilter?.motivos?.map(({ id, nombre }) => ({ id, name: nombre }))}
            handleValue={(e) => handleFilter(e, FILTER_MOTIVO_ID)}
            value={motivo_id.split('%-%')[1] ?? 'Seleccione'}
          />

        </>
      </ListadoHeader>
    </div>
  )
}