import { successResponse } from "./successResponse"

const DICT = {
  create: 'Registro creado',
  delete: 'Registro eliminado',
  update: 'Registro actualizado',
  get: 'Registros encontrados',
  detail: 'Registro encontrado',
}

export const toasterContent = (target = '', icon, title = 'Operación realizada') => {
  if (!target) return new Error('Se necesita un formato para el toaster (missing: "target")')
  if (!Object.keys(DICT).find(k => k === target)) return new Error(`Formato no encontrado para el toaster (target not found: ${target})`)

  return {
    title,
    icon,
    text: DICT[target]
  }
}

export const responseToasterContent = (data) => {
  const toasterContentSuccess = {
    title: 'Operación realizada',
    icon: 'success'
  }


  if (successResponse(data)) {
    return toasterContentSuccess
  }


  const defaultErrorResponse = {
    errores: [], statusText: 'Ha ocurrido un error', message: 'Ha ocurrido un error', status: 500
  }
  // const { errores, statusText: errorText, message } = data?.response?.data ?? defaultErrorResponse
  const { errores } = data?.response?.data ?? defaultErrorResponse
  const { statusText } = data?.response?.data ?? defaultErrorResponse
  const { message } = data?.response?.data ?? defaultErrorResponse
  const { status } = data?.response ?? defaultErrorResponse

  let detalles = []

  typeof errores === 'object' || Object.values(errores)?.length
    ? Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))
    : detalles = [message]

  const toasterContentFailed = {
    title: `
            Operación fallida
            (${status} - ${statusText})
          `,
    html: `
            <b>Detalle: </b>
            ${detalles.map(error => `<br /><i>-${error}</i>`)}
          `,
    icon: 'error'
  }

  return toasterContentFailed
}