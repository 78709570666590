import React, { useEffect, useState } from 'react'

// Redux
import { useDispatch, useSelector } from 'react-redux'
import { clearFilterLogisticaReplanificacionMotivos, resultadosLogisticaReplanificacionMotivos } from '../../../redux/actions/logisticaReplanificacionMotivos';
import { fireToaster } from '../../../redux/actions/toaster';


// Components
import Table from "../../Table";
import Button from '../../Button';


// Layouts
import AccordionTable from "../../../layout/AccordionTable";
import moment from 'moment/moment';
import { deleteMotivoReplanificacion } from '../../../services/logistica/replanificacionMotivos/delete.service';


// Services
import { getAllLogisticaReplanificacionMotivos } from '../../../services/logistica/replanificacionMotivos/getAll.service';


// Helpers/Hooks


const CONSTANT = {
  permissionsPath: 'notas-venta',
  pathUrl: '/logistica/replanificacion-motivos',
  title: 'Replanificación',

  serviceDelete: deleteMotivoReplanificacion,

  reduxSelector: 'logisticaReplanificacionMotivos',
  reduxClearFilters: clearFilterLogisticaReplanificacionMotivos,
  reduxSetResults: resultadosLogisticaReplanificacionMotivos,
}


export const ReplanificacionMotivosTable = () => {
  const { resultados: response } = useSelector(state => state[CONSTANT.reduxSelector])
  const { showing_results } = useSelector(state => state.listedResults)
  const dispatch = useDispatch()


  const handleDelete = async (id, url) => {
    await CONSTANT.serviceDelete(id)
      .then(res => {
        dispatch(fireToaster({ title: 'Operación realizada', text: 'Registro eliminado con éxito', icon: 'success' }))
      })
      .catch(err => {
        console.error(err);
      })
      .finally(async () => {
        await getAllLogisticaReplanificacionMotivos(undefined, undefined, url)
          .then(({ data }) => {
            dispatch(CONSTANT.reduxSetResults(data))
          })
          .catch(err => {
            console.error(err);
            return dispatch(CONSTANT.reduxSetResults([]))
          })
      })
  }


  return (
    <>
      {response?.data
        ?
        <>
          {/* MARK: TABLA */}
          <AccordionTable title={`${CONSTANT.title} (${response?.data?.length ?? 0} resultados)`}>
            <Table response={response} handlePagination={response}>
              <thead className="table-light position-sticky top-0 w-100 z-100 shadow">
                <tr role="row">
                  <th>ID</th>

                  <th>Motivo</th>

                  <th>Creado</th>

                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {response?.data?.length
                  ? response?.data?.map(({ motivo, id, created_at }, i) => {
                    return (
                      <tr className="odd" key={`replanificacion-motivo-${id}-${i}`}>
                        <td className='col-1'>{id}</td>

                        <td>{motivo ?? "N/A"}</td>

                        <td className='col-2'>{moment(created_at).format('DD-MM-YYYY') ?? "N/A"}</td>

                        <td className='col-1'>
                          <div className='d-flex flex-column gap-25'>
                            <Button
                              isLink
                              text='Editar'
                              icon='Settings'
                              className='p-25 w-fit'
                              color='warning'
                              url={`${CONSTANT.pathUrl}/update/${id}`}
                            />

                            <Button
                              text='Eliminar'
                              icon='Trash'
                              className='p-25 w-fit'
                              color='danger'
                              onClick={() => handleDelete(id, response.path + '?page=' + response.current_page + `&limit=${showing_results}`)}
                            />
                          </div>
                        </td>
                      </tr>
                    )
                  })
                  : <tr><td className="text-center fw-bolder fs-2 text-lgray" colSpan={9}><i>Sin datos para mostrar...</i></td></tr>
                }
              </tbody>
            </Table>
          </AccordionTable>
        </>

        : <div className='col-12'><h2 className='card p-2 text-center mb-0 fw-bolder'><i>Por favor realice una búsqueda</i></h2></div>}
    </>
  )
}
